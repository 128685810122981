import React, { useState } from "react";

const DateRangeModal = ({
  setDateRangeModal,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleApply,
}) => {
  const handleClose = () => {
    setDateRangeModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleApply();
    setDateRangeModal(false);
  };

  const currentDate = new Date().toISOString().split("T")[0];

  return (
    <div
      className="modal fade show"
      tabIndex="-1"
      style={{ display: "block", background: "rgba(0, 0, 0, 0.5)" }}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content rounded-5">
          <div className="modal-header border-0 pb-0">
            <h5 className="modal-title" id="dateRangeModalLabel">
              Apply Date Range Filter
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            ></button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={handleSubmit} className="row g-4">
              <div className="col-md-6">
                <div>
                  <label htmlFor="startDate" className="form-label">
                    Start Date
                  </label>
                  <input
                    type="date"
                    id="startDate"
                    className="form-control"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    max={currentDate}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <label htmlFor="endDate" className="form-label">
                    End Date
                  </label>
                  <input
                    type="date"
                    id="endDate"
                    className="form-control"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    min={startDate}
                    max={currentDate}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex align-items-center gap-3 justify-content-end">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={startDate === "" || endDate === ""}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRangeModal;
