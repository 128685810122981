import {
  navigateToAllBoard,
  navigateToApprovalEmployee,
  navigateToGantt,
  navigateToInitialCalendarPage,
  navigateToTaskboard,
  navigateToTasklist,
  navigateToSpaces,
  navigateToApprovalBusiness,
  navigateToWorkspacePage,
  navigateToRoleList,
  navigateToManagePeople,
  navigateToMyTasksPage,
  navigateToAlGrievance,
  navigateToStaff,
  navigateToStaffSalary,
  navigateToStaffAttendance,
  navigateToLeave,
  navigateToLeaveRequest,
  navigateToLeaveHistory,
  navigateToLeaveType,
  navigateToMyGrievance,
  navigateToNotice,
  navigateToPoll,
  navigateToMeeting,
  navigateToShiftTiming,
  navigateToHoliday,
  navigateToGrievanceRate,
  navigateToItems,
  navigateToAllCustomers,
  navigateToAllInvoices,
  navigateToAllVendors,
  navigateToAllQuotes,
  navigateToAllCreditNote,
  navigateToLocation,
  navigateToChartOfAccounts,
  navigateToTaskAnalysis,
  navigateToRawMaterial,
  navigateToItemCategory,
  navigateToManageStock,
  navigateToInventoryCustomer,
  navigateToOrder,
  navigateToOrderHistory,
  navigateToInventoryAnalytics,
  navigateToMyTodo,
  navigateToOrderBill,
  navigateToInventorySettings,
  navigateToAllLeads,
  navigateToAllContacts,
  navigateToAllDeals,
  navigateToCrmMeeting,
  navigateToCrmCalls,
  navigateToCrmTask,
  navigateToCrmEmails,
  navigateToCrmAnalytics,
  navigateToCrmGroups,
  navigateToAllBills,
  navigateToAllExpenses,
  navigateToOtherSettings,
  navigateToVendorsReport,
  navigateToCashInHand,
  navigateToAllTables,
  navigateToOrderApprovals,
  navigateToParticipantsGroups,
  navigateToCrmActivityGroups,
  navigateToCrmSettings,
  navigateToCrEmployeeAnalytics,
  navigateToReport,
} from "./Navigation";

const allModules = [
  {
    title: "Apps",
    menuItems: [
      {
        target: "#dash_approval",
        id: "dash_approval",
        name: "Approval",
        key: "approval",
        isCount: true,
        countType: "allPendingWorkspace",
        isModulePermission: true,
        svg: <i className="ri-user-add-line"></i>,
        subItems: [
          {
            name: "Employee",
            key: `${navigateToApprovalEmployee()?.split("/")?.[2]}`,
            route: `${navigateToApprovalEmployee()}`,
          },
          {
            name: "All Business",
            isCount: true,
            key: `${navigateToApprovalBusiness()?.split("/")?.[2]}`,
            route: `${navigateToApprovalBusiness()}`,
          },
        ],
      },
      {
        target: "#dash_spaces",
        id: "dash_spaces",
        name: "Spaces",
        key: "spaces",
        isRole: true,
        isModulePermission: true,
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path d="M12.6727 1.61162 20.7999 9H17.8267L12 3.70302 6 9.15757V19.0001H11V21.0001H5C4.44772 21.0001 4 20.5524 4 20.0001V11.0001L1 11.0001 11.3273 1.61162C11.7087 1.26488 12.2913 1.26488 12.6727 1.61162ZM14 11H23V18H14V11ZM16 13V16H21V13H16ZM24 21H13V19H24V21Z"></path>
          </svg>
        ),
        // subItems: [
        //   {
        //     name: "All Spaces",
        //     key: `${navigateToSpaces()?.split("/")?.[2]}`,
        //     route: `${navigateToSpaces()}`,
        //   },
        // ],
      },
      {
        target: "#todo",
        id: "mytodo",
        name: "My Todo",
        key: "mytodo",
        isRole: false,
        isCount: true,
        isModulePermission: false,
        countType: "employeePendingInvitations",
        svg: <i className="ri-todo-line"></i>,
        route: `${navigateToMyTodo()}`,
      },
      {
        target: "#dash_business",
        id: "dash_business",
        name: "Workspace",
        key: "workspace",
        isRole: false,
        isCount: true,
        isModulePermission: false,
        countType: "employeePendingInvitations",
        svg: <i className="ri-community-line"></i>,
        route: `${navigateToWorkspacePage()}`,
      },
      {
        target: "#task",
        id: "task",
        name: "Task",
        key: "task",
        isRole: true,
        isModulePermission: true,
        svg: <i className="ri-todo-line"></i>,
        subItems: [
          {
            name: "Performance",
            key: `${navigateToReport()?.split("/")?.[2]}`,
            route: `${navigateToReport()}`,
          },
          {
            name: "Task Overview",
            key: `${navigateToTaskAnalysis()?.split("/")?.[2]}`,
            route: `${navigateToTaskAnalysis()}`,
          },
          // {
          //   name: "My Task",
          //   key: `${navigateToMyTasksPage()?.split("/")?.[2]}`,
          //   route: `${navigateToMyTasksPage()}`,
          // },
        ],
      },

      {
        target: "#role",
        id: "role",
        name: "Role",
        key: "role",
        isRole: true,
        isModulePermission: true,
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12.1597 16C10.1243 16 8.29182 16.8687 7.01276 18.2556C8.38039 19.3474 10.114 20 12 20C13.9695 20 15.7727 19.2883 17.1666 18.1081C15.8956 16.8074 14.1219 16 12.1597 16ZM12 4C7.58172 4 4 7.58172 4 12C4 13.8106 4.6015 15.4807 5.61557 16.8214C7.25639 15.0841 9.58144 14 12.1597 14C14.6441 14 16.8933 15.0066 18.5218 16.6342C19.4526 15.3267 20 13.7273 20 12C20 7.58172 16.4183 4 12 4ZM12 5C14.2091 5 16 6.79086 16 9C16 11.2091 14.2091 13 12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5ZM12 7C10.8954 7 10 7.89543 10 9C10 10.1046 10.8954 11 12 11C13.1046 11 14 10.1046 14 9C14 7.89543 13.1046 7 12 7Z"></path>
          </svg>
        ),
        subItems: [
          {
            name: "Role List",
            key: `${navigateToRoleList()?.split("/")?.[2]}`,
            route: `${navigateToRoleList()}`,
          },
        ],
      },
      {
        target: "#salary_attendance",
        id: "salary_attendance",
        name: "Staff Management",
        key: "staff",
        isRole: true,
        isModulePermission: true,
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path d="M12.0049 22.0029C6.48204 22.0029 2.00488 17.5258 2.00488 12.0029C2.00488 6.48008 6.48204 2.00293 12.0049 2.00293C17.5277 2.00293 22.0049 6.48008 22.0049 12.0029C22.0049 17.5258 17.5277 22.0029 12.0049 22.0029ZM12.0049 20.0029C16.4232 20.0029 20.0049 16.4212 20.0049 12.0029C20.0049 7.58465 16.4232 4.00293 12.0049 4.00293C7.5866 4.00293 4.00488 7.58465 4.00488 12.0029C4.00488 16.4212 7.5866 20.0029 12.0049 20.0029ZM8.50488 14.0029H14.0049C14.281 14.0029 14.5049 13.7791 14.5049 13.5029C14.5049 13.2268 14.281 13.0029 14.0049 13.0029H10.0049C8.62417 13.0029 7.50488 11.8836 7.50488 10.5029C7.50488 9.12222 8.62417 8.00293 10.0049 8.00293H11.0049V6.00293H13.0049V8.00293H15.5049V10.0029H10.0049C9.72874 10.0029 9.50488 10.2268 9.50488 10.5029C9.50488 10.7791 9.72874 11.0029 10.0049 11.0029H14.0049C15.3856 11.0029 16.5049 12.1222 16.5049 13.5029C16.5049 14.8836 15.3856 16.0029 14.0049 16.0029H13.0049V18.0029H11.0049V16.0029H8.50488V14.0029Z"></path>
          </svg>
        ),
        subItems: [
          {
            name: "Onboarding",
            key: `${navigateToStaff()?.split("/")?.[2]}`,
            route: `${navigateToStaff()}`,
          },
          {
            name: "Attendance",
            key: `${navigateToStaffAttendance()?.split("/")?.[2]}`,
            route: `${navigateToStaffAttendance()}`,
          },
          {
            name: "Salary",
            key: `${navigateToStaffSalary()?.split("/")?.[2]}`,
            route: `${navigateToStaffSalary()}`,
          },
          {
            name: "Holiday",
            key: `${navigateToHoliday()?.split("/")?.[2]}`,
            route: `${navigateToHoliday()}`,
          },
          {
            name: "Location",
            key: `${navigateToLocation()?.split("/")?.[2]}`,
            route: `${navigateToLocation()}`,
          },
          {
            name: "Shift",
            key: `${navigateToShiftTiming()?.split("/")?.[2]}`,
            route: `${navigateToShiftTiming()}`,
          },
        ],
      },
      {
        target: "#leave",
        id: "leave",
        name: "Leave",
        key: "leave",
        isRole: true,
        isModulePermission: true,
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M9 3V1H7V3H3C2.44772 3 2 3.44772 2 4V20C2 20.5523 2.44772 21 3 21H21C21.5523 21 22 20.5523 22 20V4C22 3.44772 21.5523 3 21 3H17V1H15V3H9ZM4 10H20V19H4V10ZM4 5H7V6H9V5H15V6H17V5H20V8H4V5ZM9.87862 10.9644L12 13.0858L14.1212 10.9644L15.5355 12.3785L13.4142 14.5001L15.5354 16.6212L14.1213 18.0354L12 15.9143L9.87855 18.0354L8.46442 16.6211L10.5857 14.5001L8.46436 12.3785L9.87862 10.9644Z"></path>
          </svg>
        ),
        subItems: [
          {
            name: "Leave Type",
            key: `${navigateToLeaveType()?.split("/")?.[2]}`,
            route: `${navigateToLeaveType()}`,
          },
          {
            name: "Leave Requests",
            key: `${navigateToLeaveRequest()?.split("/")?.[2]}`,
            route: `${navigateToLeaveRequest()}`,
          },
          {
            name: "Leave History",
            key: `${navigateToLeaveHistory()?.split("/")?.[2]}`,
            route: `${navigateToLeaveHistory()}`,
          },
        ],
      },
      {
        target: "#communication",
        id: "communication",
        name: "Communication",
        key: "communication",
        isRole: true, //check for role add
        isModulePermission: true, //check for module permission
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path d="M9.55 11.5C8.30736 11.5 7.3 10.4926 7.3 9.25C7.3 8.00736 8.30736 7 9.55 7C10.7926 7 11.8 8.00736 11.8 9.25C11.8 10.4926 10.7926 11.5 9.55 11.5ZM10 19.748V16.4C10 15.9116 10.1442 15.4627 10.4041 15.0624C10.1087 15.0213 9.80681 15 9.5 15C7.93201 15 6.49369 15.5552 5.37091 16.4797C6.44909 18.0721 8.08593 19.2553 10 19.748ZM4.45286 14.66C5.86432 13.6168 7.61013 13 9.5 13C10.5435 13 11.5431 13.188 12.4667 13.5321C13.3447 13.1888 14.3924 13 15.5 13C17.1597 13 18.6849 13.4239 19.706 14.1563C19.8976 13.4703 20 12.7471 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 12.9325 4.15956 13.8278 4.45286 14.66ZM18.8794 16.0859C18.4862 15.5526 17.1708 15 15.5 15C13.4939 15 12 15.7967 12 16.4V20C14.9255 20 17.4843 18.4296 18.8794 16.0859ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM15.5 12.5C14.3954 12.5 13.5 11.6046 13.5 10.5C13.5 9.39543 14.3954 8.5 15.5 8.5C16.6046 8.5 17.5 9.39543 17.5 10.5C17.5 11.6046 16.6046 12.5 15.5 12.5Z"></path>
          </svg>
        ),
        subItems: [
          {
            name: "Meeting",
            key: `${navigateToMeeting()?.split("/")?.[2]}`,
            route: `${navigateToMeeting()}`,
          },
          {
            name: "Notice",
            key: `${navigateToNotice()?.split("/")?.[2]}`,
            route: `${navigateToNotice()}`,
          },
          {
            name: "Poll",
            key: `${navigateToPoll()?.split("/")?.[2]}`,
            route: `${navigateToPoll()}`,
          },
        ],
      },
      {
        target: "#settings",
        id: "settings",
        name: "Settings",
        key: "settings",
        isCount: true,
        isRole: true, //check for role add
        isModulePermission: true, //check for module permission
        countType: "pendingWorkspacePeople",
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M8.68735 4.00008L11.294 1.39348C11.6845 1.00295 12.3176 1.00295 12.7082 1.39348L15.3148 4.00008H19.0011C19.5533 4.00008 20.0011 4.4478 20.0011 5.00008V8.68637L22.6077 11.293C22.9982 11.6835 22.9982 12.3167 22.6077 12.7072L20.0011 15.3138V19.0001C20.0011 19.5524 19.5533 20.0001 19.0011 20.0001H15.3148L12.7082 22.6067C12.3176 22.9972 11.6845 22.9972 11.294 22.6067L8.68735 20.0001H5.00106C4.44877 20.0001 4.00106 19.5524 4.00106 19.0001V15.3138L1.39446 12.7072C1.00393 12.3167 1.00393 11.6835 1.39446 11.293L4.00106 8.68637V5.00008C4.00106 4.4478 4.44877 4.00008 5.00106 4.00008H8.68735ZM6.00106 6.00008V9.5148L3.51578 12.0001L6.00106 14.4854V18.0001H9.51578L12.0011 20.4854L14.4863 18.0001H18.0011V14.4854L20.4863 12.0001L18.0011 9.5148V6.00008H14.4863L12.0011 3.5148L9.51578 6.00008H6.00106ZM12.0011 16.0001C9.79192 16.0001 8.00106 14.2092 8.00106 12.0001C8.00106 9.79094 9.79192 8.00008 12.0011 8.00008C14.2102 8.00008 16.0011 9.79094 16.0011 12.0001C16.0011 14.2092 14.2102 16.0001 12.0011 16.0001ZM12.0011 14.0001C13.1056 14.0001 14.0011 13.1047 14.0011 12.0001C14.0011 10.8955 13.1056 10.0001 12.0011 10.0001C10.8965 10.0001 10.0011 10.8955 10.0011 12.0001C10.0011 13.1047 10.8965 14.0001 12.0011 14.0001Z"></path>
          </svg>
        ),
        subItems: [
          {
            name: "Manage People",
            isCount: true,
            key: `${navigateToManagePeople()?.split("/")?.[2]}`,
            route: `${navigateToManagePeople()}`,
          },
          {
            name: "Other Settings",
            key: `${navigateToOtherSettings()?.split("/")?.[2]}`,
            route: `${navigateToOtherSettings()}`,
          },
        ],
      },
      {
        target: "#grievance",
        id: "grievance",
        name: "Grievance",
        key: "grievance",
        isCount: true,
        isRole: true, //check for role add
        isModulePermission: true, //check for module permission
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM8 14H16V16H8V14ZM8 11C7.17157 11 6.5 10.3284 6.5 9.5C6.5 8.67157 7.17157 8 8 8C8.82843 8 9.5 8.67157 9.5 9.5C9.5 10.3284 8.82843 11 8 11ZM16 11C15.1716 11 14.5 10.3284 14.5 9.5C14.5 8.67157 15.1716 8 16 8C16.8284 8 17.5 8.67157 17.5 9.5C17.5 10.3284 16.8284 11 16 11Z"></path>
          </svg>
        ),
        subItems: [
          {
            name: "Overview",
            key: `${navigateToGrievanceRate()?.split("/")?.[2]}`,
            route: `${navigateToGrievanceRate()}`,
          },
          {
            name: "My Grievance",
            key: `${navigateToMyGrievance()?.split("/")?.[2]}`,
            route: `${navigateToMyGrievance()}`,
          },
          {
            name: "All Grievance",
            key: `${navigateToAlGrievance()?.split("/")?.[2]}`,
            route: `${navigateToAlGrievance()}`,
          },
        ],
      },
      {
        target: "#vendor",
        id: "vendor",
        name: "Vendors",
        key: "vendor",
        isRole: true, //check for role add
        isModulePermission: true, //check for module permission
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M2 22C2 17.5817 5.58172 14 10 14C14.4183 14 18 17.5817 18 22H16C16 18.6863 13.3137 16 10 16C6.68629 16 4 18.6863 4 22H2ZM10 13C6.685 13 4 10.315 4 7C4 3.685 6.685 1 10 1C13.315 1 16 3.685 16 7C16 10.315 13.315 13 10 13ZM10 11C12.21 11 14 9.21 14 7C14 4.79 12.21 3 10 3C7.79 3 6 4.79 6 7C6 9.21 7.79 11 10 11ZM18.2837 14.7028C21.0644 15.9561 23 18.752 23 22H21C21 19.564 19.5483 17.4671 17.4628 16.5271L18.2837 14.7028ZM17.5962 3.41321C19.5944 4.23703 21 6.20361 21 8.5C21 11.3702 18.8042 13.7252 16 13.9776V11.9646C17.6967 11.7222 19 10.264 19 8.5C19 7.11935 18.2016 5.92603 17.041 5.35635L17.5962 3.41321Z"></path>
          </svg>
        ),
        subItems: [
          {
            name: "Vendors",
            key: `${navigateToAllVendors()?.split("/")?.[2]}`,
            route: `${navigateToAllVendors()}`,
          },
          {
            name: "Vendors Report",
            key: `${navigateToVendorsReport()?.split("/")?.[2]}`,
            route: `${navigateToVendorsReport()}`,
          },
        ],
      },
      {
        target: "#inventory",
        id: "inventory",
        name: "Restaurant Inventory",
        key: "inventory",
        isCount: false,
        isRole: true, //check for role add
        isModulePermission: true, //check for module permission
        svg: (
          // <svg
          //   xmlns="http://www.w3.org/2000/svg"
          //   width="24"
          //   height="24"
          //   viewBox="0 0 21 17"
          //   fill="none"
          // >
          //   <path
          //     d="M3.87188 0.425C4.11975 0.157456 4.50876 0 4.92188 0H16.0781C16.4912 0 16.8803 0.157456 17.1281 0.425L20.8687 4.4625C20.9539 4.55446 21 4.6663 21 4.78125V15.9375C21 16.5243 20.4124 17 19.6875 17H1.3125C0.587626 17 0 16.5243 0 15.9375V4.78125C0 4.6663 0.0460542 4.55446 0.13125 4.4625L3.87188 0.425ZM9.84375 1.0625H4.92188L1.96875 4.25H9.84375V1.0625ZM11.1562 1.0625V4.25H19.0312L16.0781 1.0625H11.1562ZM19.6875 5.3125H1.3125V15.9375H19.6875V5.3125Z"
          //     fill="black"
          //   />
          // </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            width="24"
            height="24"
          >
            <path d="M21 2V22H19V15H15V8C15 4.68629 17.6863 2 21 2ZM19 4.53C18.17 5 17 6.17 17 8V13H19V4.53ZM9 13.9V22H7V13.9C4.71776 13.4367 3 11.419 3 9V3H5V10H7V3H9V10H11V3H13V9C13 11.419 11.2822 13.4367 9 13.9Z"></path>
          </svg>
        ),
        subItems: [
          {
            name: "Home",
            key: `${navigateToInventoryAnalytics()?.split("/")?.[2]}`,
            route: `${navigateToInventoryAnalytics()}`,
          },
          {
            name: "Raw Materials",
            key: `${navigateToRawMaterial()?.split("/")?.[2]}`,
            route: `${navigateToRawMaterial()}`,
          },
          {
            name: "Menu Items",
            key: `${navigateToItemCategory()?.split("/")?.[2]}`,
            route: `${navigateToItemCategory()}`,
          },
          {
            name: "Customer",
            key: `${navigateToInventoryCustomer()?.split("/")?.[2]}`,
            route: `${navigateToInventoryCustomer()}`,
          },
          {
            name: "Place Order",
            key: `${navigateToOrder()?.split("/")?.[2]}`,
            route: `${navigateToOrder()}`,
          },
          {
            name: "Order Approvals",
            key: `${navigateToOrderApprovals()?.split("/")?.[2]}`,
            route: `${navigateToOrderApprovals()}`,
          },
          {
            name: "Manage Table",
            key: `${navigateToAllTables()?.split("/")?.[2]}`,
            route: `${navigateToAllTables()}`,
          },
          {
            name: "Order History",
            key: `${navigateToOrderHistory()?.split("/")?.[2]}`,
            route: `${navigateToOrderHistory()}`,
          },
          {
            name: "Cash In Hand",
            key: `${navigateToCashInHand()?.split("/")?.[2]}`,
            route: `${navigateToCashInHand()}`,
          },

          {
            name: "Manage Stock",
            key: `${navigateToManageStock()?.split("/")?.[2]}`,
            route: `${navigateToManageStock()}`,
          },

          {
            name: "Inv Settings",
            key: `${navigateToInventorySettings()?.split("/")?.[2]}`,
            route: `${navigateToInventorySettings()}`,
          },
        ],
      },
      {
        target: "#account",
        id: "account",
        name: "Acccount",
        key: "account",
        isRole: true, //check for role add
        isModulePermission: true, //check for module permission
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            class="bi bi-cash-coin"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
            />
            <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
            <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
            <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
          </svg>
        ),
        subItems: [
          {
            name: "Items",
            key: `${navigateToItems()?.split("/")?.[2]}`,
            route: `${navigateToItems()}`,
          },
          {
            name: "Customers",
            key: `${navigateToAllCustomers()?.split("/")?.[2]}`,
            route: `${navigateToAllCustomers()}`,
          },
          {
            name: "Invoices",
            key: `${navigateToAllInvoices()?.split("/")?.[2]}`,
            route: `${navigateToAllInvoices()}`,
          },
          {
            name: "Quotes",
            key: `${navigateToAllQuotes()?.split("/")?.[2]}`,
            route: `${navigateToAllQuotes()}`,
          },
          {
            name: "Credit Notes",
            key: `${navigateToAllCreditNote()?.split("/")?.[2]}`,
            route: `${navigateToAllCreditNote()}`,
          },
          {
            name: "Chart Of Accounts",
            key: `${navigateToChartOfAccounts()?.split("/")?.[2]}`,
            route: `${navigateToChartOfAccounts()}`,
          },
          {
            name: "Bills",
            key: `${navigateToAllBills()?.split("/")?.[2]}`,
            route: `${navigateToAllBills()}`,
          },
          {
            name: "Expenses",
            key: `${navigateToAllExpenses()?.split("/")?.[2]}`,
            route: `${navigateToAllExpenses()}`,
          },
        ],
      },
      {
        target: "#crm",
        id: "crm",
        name: "CRM",
        key: "crm",
        isCount: false,
        isRole: true, //check for role add
        isModulePermission: true, //check for module permission
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            fill="currentColor"
          >
            <path d="M11.8611 2.39057C12.8495 1.73163 14.1336 1.71797 15.1358 2.35573L19.291 4.99994H20.9998C21.5521 4.99994 21.9998 5.44766 21.9998 5.99994V14.9999C21.9998 15.5522 21.5521 15.9999 20.9998 15.9999H19.4801C19.5396 16.9472 19.0933 17.9102 18.1955 18.4489L13.1021 21.505C12.4591 21.8907 11.6609 21.8817 11.0314 21.4974C10.3311 22.1167 9.2531 22.1849 8.47104 21.5704L3.33028 17.5312C2.56387 16.9291 2.37006 15.9003 2.76579 15.0847C2.28248 14.7057 2 14.1254 2 13.5109V6C2 5.44772 2.44772 5 3 5H7.94693L11.8611 2.39057ZM4.17264 13.6452L4.86467 13.0397C6.09488 11.9632 7.96042 12.0698 9.06001 13.2794L11.7622 16.2518C12.6317 17.2083 12.7903 18.6135 12.1579 19.739L17.1665 16.7339C17.4479 16.5651 17.5497 16.2276 17.4448 15.9433L13.0177 9.74551C12.769 9.39736 12.3264 9.24598 11.9166 9.36892L9.43135 10.1145C8.37425 10.4316 7.22838 10.1427 6.44799 9.36235L6.15522 9.06958C5.58721 8.50157 5.44032 7.69318 5.67935 7H4V13.5109L4.17264 13.6452ZM14.0621 4.04306C13.728 3.83047 13.3 3.83502 12.9705 4.05467L7.56943 7.65537L7.8622 7.94814C8.12233 8.20827 8.50429 8.30456 8.85666 8.19885L11.3419 7.45327C12.5713 7.08445 13.8992 7.53859 14.6452 8.58303L18.5144 13.9999H19.9998V6.99994H19.291C18.9106 6.99994 18.5381 6.89148 18.2172 6.68727L14.0621 4.04306ZM6.18168 14.5448L4.56593 15.9586L9.70669 19.9978L10.4106 18.7659C10.6256 18.3897 10.5738 17.9178 10.2823 17.5971L7.58013 14.6247C7.2136 14.2215 6.59175 14.186 6.18168 14.5448Z"></path>
          </svg>
        ),
        subItems: [
       
          {
            name: "Business Analytics",
            group:"1.Analytics",
            key: `${navigateToCrmAnalytics()?.split("/")?.[2]}`,
            route: `${navigateToCrmAnalytics()}`,
          },
          {
            name: "Employee Analytics",
            // group:"Analytics",
            key: `${navigateToCrEmployeeAnalytics()?.split("/")?.[2]}`,
            route: `${navigateToCrEmployeeAnalytics()}`,
          },
    
        
          {
            name: "Calls",
            group:"2.Activities",
            key: `${navigateToCrmCalls()?.split("/")?.[2]}`,
            route: `${navigateToCrmCalls()}`,
          },
          {
            name: "Meetings",
            // group:"Activities",
            key: `${navigateToCrmMeeting()?.split("/")?.[2]}`,
            route: `${navigateToCrmMeeting()}`,
          },
          {
            name: "Tasks",
            // group:"Activities",
            key: `${navigateToCrmTask()?.split("/")?.[2]}`,
            route: `${navigateToCrmTask()}`,
          },
          {
            name: "Emails",
            // group:"Activities",
            key: `${navigateToCrmEmails()?.split("/")?.[2]}`,
            route: `${navigateToCrmEmails()}`,
          },
      
          {
            name: "Contacts",
            group:"3.Main",
            key: `${navigateToAllContacts()?.split("/")?.[2]}`,
            route: `${navigateToAllContacts()}`,
          },
          {
            name: "Deals",
            // group:"Main",
            key: `${navigateToAllDeals()?.split("/")?.[2]}`,
            route: `${navigateToAllDeals()}`,
          },
          {
            name: "Leads",
            // group:"Main",
            key: `${navigateToAllLeads()?.split("/")?.[2]}`,
            route: `${navigateToAllLeads()}`,
          },
          {
            name: "Activity Group",
            group:"4.Configurations",
            key: `${navigateToCrmActivityGroups()?.split("/")?.[2]}`,
            route: `${navigateToCrmActivityGroups()}`,
          },
          {
            name: "Status Group",
         //   group:"Group Configuration",
            key: `${navigateToCrmGroups()?.split("/")?.[2]}`,
            route: `${navigateToCrmGroups()}`,
          },
          {
            name: "Participants Group",
            // group:"Group Configuration",
            key: `${navigateToParticipantsGroups()?.split("/")?.[2]}`,
            route: `${navigateToParticipantsGroups()}`,
          },
          {
            name: "Crm Settings",
            key: `${navigateToCrmSettings()?.split("/")?.[2]}`,
            route: `${navigateToCrmSettings()}`,
          },
        ],
      },
    ],
  },
];

export default allModules;
