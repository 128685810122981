import React, { useEffect, useState } from "react";
import CrmEmailsHeader from "./CrmEmailsHeader";
import CrmEmailsBody from "./CrmEmailsBody";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { DynamicEmailRelatedEndPoints } from "../../../api/Endpoints";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import MailTemplateDetailsModal from "./MailTemplateDetailsModal";
import DateRangeModal from "../../../components/DateRangeModal/DateRangeModal";

const CrmEmails = () => {
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const [allEmails, setAllEmails] = useState([]);
  const [selectedMailTemplate, setSelectedMailTemplate] = useState("");
  const [emailDetailsModal, setEmailDetailsModal] = useState(false);
  const [dateRangeModal, setDateRangeModal] = useState(false);

  // Date Filter
  const today = new Date().toISOString().split("T")[0];
  const yesterday = new Date(Date.now() - 864e5).toISOString().split("T")[0];
  const tomorrow = new Date(Date.now() + 864e5).toISOString().split("T")[0];
  const [isDateFilterValue, setIsDateFilterValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Pagination
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);
  const [count, setCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [search]);

  const handleDateFilterChange = (value) => {
    console.log(value);
    if (value !== "Date Range") {
      setIsDateFilterValue(value);
      const newDate =
        value === "Today"
          ? today
          : value === "Yesterday"
          ? yesterday
          : tomorrow;
      setStartDate(newDate);
      setEndDate(newDate);
      setPage(1);
    } else {
      setDateRangeModal(true);
      setIsDateFilterValue("Date Range");
      setStartDate("");
      setEndDate("");
      setPage(1);
    }
  };

  const handleGetAllEmails = async () => {
    startApiCall(null, setLoader);
    const data = {
      page,
      limit: pageLimit,
      filters: {
        searchKey: debouncedSearch,
        startDate: startDate || "",
        endDate: endDate || "",
        businessId: getCurrentWorkspaceId(),
      },
    };

    try {
      const res = await ApiCall(
        "post",
        DynamicEmailRelatedEndPoints.getEmailActivity,
        data
      );

      if (res?.success) {
        setAllEmails(res.result.docs);
        setCount(res.result.totalDocs);
        setTotalPages(res.result.totalPages);
      } else {
        setAllEmails([]);
      }
    } catch (error) {
      console.error("Error fetching emails:", error);
      setAllEmails([]);
    }
    setLoader(false);
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllEmails();
    }
  }, [page, pageLimit, debouncedSearch, isDateFilterValue]);

  const handleRefresh = () => {
    setSearch("");
    setStartDate("");
    setEndDate("");
    setIsDateFilterValue("");
    setPage(1);
    handleGetAllEmails();
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <CrmEmailsHeader
            isDateFilterValue={isDateFilterValue}
            setPage={setPage}
            handleRefresh={handleRefresh}
            search={search}
            setSearch={setSearch}
            handleDateFilterChange={handleDateFilterChange}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar pt-2">
              {!loader && (
                <>
                  <CrmEmailsBody
                    allEmails={allEmails}
                    page={page}
                    pageLimit={pageLimit}
                    setSelectedMailTemplate={setSelectedMailTemplate}
                    setEmailDetailsModal={setEmailDetailsModal}
                  />
                  <FooterPagination
                    setPageLimit={setPageLimit}
                    pageLimit={pageLimit}
                    setPage={setPage}
                    page={page}
                    totalPages={totalPages}
                    count={count}
                    dataLength={allEmails.length}
                    align={"end"}
                  />
                </>
              )}

              {loader && <DataLoader title="Data is loading..." />}
              {!loader && allEmails.length === 0 && (
                <NoData title="No emails added" />
              )}
            </div>
          </div>
        </div>

        {emailDetailsModal && (
          <MailTemplateDetailsModal
            setEmailDetailsModal={setEmailDetailsModal}
            selectedMailTemplate={selectedMailTemplate}
          />
        )}

        {dateRangeModal && (
          <DateRangeModal
            setDateRangeModal={setDateRangeModal}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            handleApply={handleGetAllEmails}
          />
        )}
      </div>
    </div>
  );
};

export default CrmEmails;
