import React from "react";
import { useNavigate } from "react-router-dom";
import { navigateToAllDeals } from "../../../../../Navigation/Navigation";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";

const DealOverviewHeader = ({
  componentRef,
  setEmailDetailsModal,
  handleGetDealDetails,
  id,
  locationData,
  dealDetails,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <header className="todo-header">
        <div className="d-flex align-items-center">
          <span className="todoapp-title link-dark">
            <button
              className="btn btn-outline text-primary btn-md d-flex align-items-center gap-1"
              onClick={() => navigate(navigateToAllDeals())}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="currentColor"
                class="bi bi-chevron-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
              </svg>
              <p className="mb-0">Back</p>
            </button>
            <div className="v-separator d-lg-inline-block"></div>
            <h1 className="header-title-font ">Overview</h1>
          </span>
        </div>
        <div className="todo-options-wrap">
          {/* <div className="ms-3">
            <button className="btn btn-sm btn-outline-light flex-shrink-0 d-lg-inline-block header-button-shadow">
              Create Invoice
            </button>
          </div> */}
          <div className="ms-3">
            <ReactToPrint
              trigger={() => (
                <button className="btn btn-sm btn-outline-light flex-shrink-0 d-lg-inline-block header-button-shadow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-printer me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                    <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1" />
                  </svg>
                  Print
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
          <div className="ms-3">
            <button
              className="btn btn-sm btn-outline-light flex-shrink-0 d-lg-inline-block header-button-shadow"
              data-bs-toggle="modal"
              data-bs-target="#choose_option"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="currentColor"
                className="bi bi-envelope me-2"
                viewBox="0 0 16 16"
              >
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
              </svg>
              Send Email
            </button>
          </div>

          <span
            className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="dsdsd"
            data-bs-original-title="Refresh"
          >
            <span className="btn-icon-wrap" onClick={handleGetDealDetails}>
              <span className="feather-icon">
                <i
                  className="ri-refresh-line"
                  title="Refresh business list"
                ></i>
              </span>
            </span>
          </span>
        </div>
      </header>
    </>
  );
};

export default DealOverviewHeader;
