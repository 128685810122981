import React from "react";
import { dateAndTimeFormatter } from "../../../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";

const MailTemplateDetailsModal = ({
  setTemplateDetailsModal,
  editMailTemplate,
}) => {
  const user = useSelector((state) => state.user);

  return (
    <div className="details-modal w-100 mw-500p">
      <div className="nicescroll-bar simple-scrollbar">
        <div className="d-flex gap-3 justify-content-between">
          <span className="file-name">{editMailTemplate?.title}</span>
          <button
            onClick={() => setTemplateDetailsModal(false)}
            type="button"
            className="info-close btn-close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="tab-content">
          <div className="tab-pane fade show active" id="tab_info">
            <div className="collapse-simple">
              <div className="card py-0">
                <div className="card-header">
                  <a
                    role="button"
                    data-bs-toggle="collapse"
                    href="#fl_info"
                    aria-expanded="true"
                  >
                    Mail Template Details
                  </a>
                </div>
                <div id="fl_info" className="collapse show">
                  <div
                    className="card-body overflow-auto nicescroll-bar simple-scrollbar"
                    style={{ height: "calc(100vh - 255px)" }}
                  >
                    <ul className="fm-info">
                      <li>
                        <span>Created By : </span>
                        <span>{user?.name}</span>
                      </li>
                      <li>
                        <span>Updated Time : </span>
                        <span>
                          {dateAndTimeFormatter(editMailTemplate?.updatedAt)}
                        </span>
                      </li>
                      <li>
                        <span>Subject : </span>
                        <span>{editMailTemplate?.subject}</span>
                      </li>
                      <li>
                        <span>Message : </span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: editMailTemplate?.message,
                          }}
                        ></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailTemplateDetailsModal;
