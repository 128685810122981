import React from "react";

const CrmEmailsHeader = ({
  isDateFilterValue,
  setPage,
  handleRefresh,
  search,
  setSearch,
  handleDateFilterChange,
}) => {
  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title link-dark">
          <h1 className="header-title-font">Emails</h1>
        </span>
      </div>
      <div className="todo-options-wrap">
        <div className="todo-toolbar me-3">
          <div className="dropdown">
            <button
              className="btn border dropdown-toggle"
              type="button"
              id="dateFilterDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {isDateFilterValue ? isDateFilterValue : "Date Filter"}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dateFilterDropdown">
              <li>
                <span
                  className="dropdown-item"
                  onClick={() => handleDateFilterChange("Today")}
                >
                  Today
                </span>
              </li>
              <li>
                <span
                  className="dropdown-item"
                  onClick={() => handleDateFilterChange("Yesterday")}
                >
                  Yesterday
                </span>
              </li>
              <li>
                <span
                  className="dropdown-item"
                  onClick={() => handleDateFilterChange("Tomorrow")}
                >
                  Tomorrow
                </span>
              </li>
              <li>
                <span
                  className="dropdown-item"
                  onClick={() => handleDateFilterChange("Date Range")}
                >
                  Date Range
                </span>
              </li>
            </ul>
          </div>
        </div>

        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          onClick={() => handleRefresh()}
        >
          <span className="btn-icon-wrap">
            <i className="ri-refresh-line" />
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-sm-block d-none" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={search}
            onChange={(e) => {
              setPage(1);
              setSearch(e.target.value);
            }}
          />
        </form>
      </div>
    </header>
  );
};

export default CrmEmailsHeader;
