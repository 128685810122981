import React, { useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { useState } from "react";
import NavbarLeft from "./NavigationLeft/NavbarLeft";
import NavbarRight from "./NavigationRight/NavbarRight";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import StartAddWorkspace from "../../pages/GetStarted/StartAddWorkspace";
import PendingWorkspaceModal from "../../pages/GetStarted/PendingWorkspaceModal";
import {
  getCurrentWorkspaceId,
  getDashboardRelatedCounts,
} from "../../global-functions/globalFunctions";
import Clock from "../Clock/Clock";
import CallReminder from "../../pages/CRM/CrmCalls/CallReminder";

const Navbar = () => {
  const user = useSelector((state) => state.user);
  const myApproveWorkspaces = useSelector((state) => state.approvedWorkspace);
  const myPendingWorkspaces = useSelector((state) => state.pendingWorkspaces);
  const callReminder = useSelector((state) => state.navbar);
  const [isActive, setisActive] = useState(false);
  const [dashboardCounts, setdashboardCounts] = useState();
  const currentTime = new Date();

  /********* Reminder Modal Start *********/
  const [reminderModal, setReminderModal] = useState({
    showModal: false,
    reminderDetails: null,
  });

  const closeTimeObject =
    typeof callReminder === "string" ? JSON.parse(callReminder) : callReminder;
  const closetTime = new Date(closeTimeObject?.closestTime);

  useEffect(() => {
    if (!closetTime || !currentTime) return;
    const timeDifference = closetTime.getTime() - currentTime.getTime();

    if (timeDifference > 0) {
      if (!reminderModal.showModal) {
        const timeoutId = setTimeout(() => {
          setReminderModal({
            showModal: true,
            reminderDetails: closeTimeObject?.closestTask,
          });
        }, timeDifference);

        return () => clearTimeout(timeoutId);
      }
    }
  }, [currentTime, closetTime, closeTimeObject, reminderModal.showModal]);

  /********* Reminder Modal End *********/

  const handleSidebarActive = () => {
    setisActive(!isActive);
  };

  const getAllDashboardCounts = async () => {
    let counts = await getDashboardRelatedCounts(
      getCurrentWorkspaceId(),
      user?._id
    );
    setdashboardCounts(counts);
  };

  useEffect(() => {
    getAllDashboardCounts();
  }, []);

  return (
    <div
      className="hk-wrapper"
      data-layout="vertical"
      data-layout-style={isActive ? "collapsed" : "default"}
      data-menu="light"
      data-footer="simple"
      data-hover={isActive ? "active" : ""}
      data-navbar-style=""
    >
      <nav className="hk-navbar navbar navbar-expand-xl navbar-light fixed-top">
        <div className="container-fluid">
          <NavbarLeft
            handleSidebarActive={handleSidebarActive}
            myApproveWorkspaces={myApproveWorkspaces}
            user={user}
          />
          <Clock />
          <NavbarRight user={user} />
        </div>
      </nav>

      <Sidebar
        user={user}
        handleSidebarActive={handleSidebarActive}
        dashboardCounts={dashboardCounts}
        isActive={isActive}
      />

      <div className="hk-pg-wrapper pb-0">
        <Outlet />
      </div>

      {myApproveWorkspaces?.length === 0 &&
        myPendingWorkspaces?.length === 0 && (
          <StartAddWorkspace
            myApproveWorkspaces={myApproveWorkspaces}
            user={user}
            myPendingWorkspaces={myPendingWorkspaces}
          />
        )}

      {myApproveWorkspaces?.length === 0 &&
        myPendingWorkspaces?.length === 1 && (
          <PendingWorkspaceModal
            myApproveWorkspaces={myApproveWorkspaces}
            user={user}
            myPendingWorkspaces={myPendingWorkspaces}
          />
        )}
      {reminderModal?.showModal && (
        <CallReminder
          onHide={() =>
            setReminderModal({ showModal: false, reminderDetails: null })
          }
          reminderDetails={reminderModal.reminderDetails}
        />
      )}
    </div>
  );
};

export default Navbar;
