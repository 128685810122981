import React from "react";

const CrmSettingsHeader = ({
  handleRefresh,
  status,
  handleGetBusinessDetails,
  setdynamicFieldsModal,
  handleGetDynamicFields,
  isModuleReadWriteAccessForSetting,
  setAddMailTemplateModal,
  handleGetAllMailTemplates,
}) => {
  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title  link-dark">
          <h1 className="header-title-font">CRM Settings</h1>
          {isModuleReadWriteAccessForSetting && (
            <>
              {status === "leadSource" && (
                <div className="ms-3">
                  <button
                    className="btn btn-sm btn-outline-light flex-shrink-0 d-lg-inline-block header-button-shadow"
                    data-bs-toggle="modal"
                    data-bs-target="#create_new_lead_source"
                  >
                    Create Lead Source
                  </button>
                </div>
              )}
              {status === "mailTemplates" && (
                <div className="ms-3">
                  <button
                    className="btn btn-sm btn-outline-light flex-shrink-0 d-lg-inline-block header-button-shadow"
                    onClick={() => setAddMailTemplateModal(true)}
                  >
                    Create Mail Template
                  </button>
                </div>
              )}
              {status === "dynamicFields" && (
                <div className="ms-3">
                  <button
                    className="btn btn-sm btn-outline-light flex-shrink-0 d-lg-inline-block header-button-shadow"
                    onClick={() => {
                      setdynamicFieldsModal(true);
                    }}
                  >
                    Create Dynamic Field
                  </button>
                </div>
              )}
            </>
          )}
        </span>
      </div>
      <div className="todo-options-wrap">
        <div className="v-separator d-lg-inline-block d-none"></div>
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
        >
          <span
            className="btn-icon-wrap"
            onClick={() => {
              if (
                status === "generationTarget" ||
                status === "leadVisibility"
              ) {
                handleGetBusinessDetails();
              } else if (status === "dynamicFields") {
                handleGetDynamicFields();
              } else if (status === "mailTemplates") {
                handleGetAllMailTemplates();
              } else {
                handleRefresh();
              }
            }}
          >
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
      </div>
    </header>
  );
};

export default CrmSettingsHeader;
