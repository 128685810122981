import { useRoutes } from "react-router-dom";
import BusinessApprovalsRoutes from "./Approvals/BusinessApprovals";
import EmployeeApprovalsRoutes from "./Approvals/EmployeeApprovals";
import WorkspaceRoutes from "./Workspace/Workspace";
import CalendarRoutes from "./Calendar/Calendar";
import HomeRoutes from "./Home/HomeRoutes";
import SpacesRoutes from "./Spaces/Spaces";
// import AllBoardsRoutes from "./Scrumboard/AllBoards";
import RoleListRoutes from "./Role/RoleList";
import ManagePeopleRoutes from "./Settings/ManagePeople";
import MyTasksRoutes from "./MyTasks/MyTasks";
import LogsRoutes from "./Logs/LogsRoutes";
import AllComplaintRoutes from "./Complaints/AllComplaints";
import MyComplaintRoutes from "./Complaints/MyComplaints";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../components/Constant/Route-Constants";
import NoAccessRoute from "./Other/NoAccessRoute";
import { isUserSuperAdmin } from "../global-functions/globalFunctions";
import StaffOnboardingRoutes from "./StaffManagement/Onboarding";
import StaffAttendanceRoutes from "./StaffManagement/Attendance";
import StaffSalaryRoutes from "./StaffManagement/Salary";
import NoticeRoutes from "./Communication/Notice";
import PollRoutes from "./Communication/Poll";
import MeetingRoutes from "./Communication/Meeting";
import StaffShiftTimingRoutes from "./StaffManagement/ShiftTiming";
import LeaveTypeRoutes from "./Leave/LeaveType";
import LeaveRequestRoutes from "./Leave/LeaveRequests";
import LeaveHistoryRoutes from "./Leave/LeaveHistory";
import HolidayRoutes from "./StaffManagement/Holiday";
import AllTasksRoutes from "./AllTask/AllTask";
import ComplaintsRateRoutes from "./Complaints/ComplaintsRate";
import ItemRoutes from "./Account/Items";
import CustomerRoutes from "./Account/Customer";
import InvoiceRoutes from "./Account/invoice";
import VendorRoutes from "./Vendors/Vendor";
import QuotesRoutes from "./Account/Quotes";
import CreditNotesRoutes from "./Account/CreditNote";
import StaffLocationRoutes from "./StaffManagement/Location";
import ChartOfAccountRoutes from "./Account/ChartOfAccount";
import TaskAnalysisRoutes from "./TaskAnalysis/TaskAnalysis";
import RawMaterialRoutes from "./Inventory/RawMaterialRoutes";
import ItemCategoryRoutes from "./Inventory/ItemCategoryRoutes";
import ManageStockRoutes from "./Inventory/ManageStockRoutes";
import InventoryCustomerRoutes from "./Inventory/CustomerRoutes";
import OrderRoutes from "./Inventory/OrderRoutes";
import OrderHistoryRoutes from "./Inventory/OrderHistory";
import InventoryAnalyticsRoutes from "./Inventory/InventoryAnalyticsRoutes";
import MyTodoRoutes from "./MyTasks/MyTodo";
import InventorySettingsRoutes from "./Inventory/InventorySettingsRoutes";
import LeadsRoutes from "./CRM/Leads";
import ContactsRoutes from "./CRM/Contacts";
import DealsRoutes from "./CRM/Deals";
import CrmMeetingRoutes from "./CRM/Meetings";
import CrmCallsRoutes from "./CRM/Calls";
import CrmTasksRoutes from "./CRM/Tasks";
import CrmAnalyticsRoutes from "./CRM/CrmAnalytics";
import CrmGroupRoutes from "./CRM/Group";
import CrmActivityGroupRoutes from "./CRM/ActivityGroup";
import BillsRoutes from "./Account/Bills";
import AllExpensesRoutes from "./Account/AllExpenses";
import OtherSettingRoutes from "./Settings/OtherSetting";
import CashInHandRoutes from "./Inventory/CashInHand";
import AllTablesRoutes from "./Inventory/AllTables";
import OrderApprovalsRoutes from "./Inventory/OrderApprovals";
import ParticipantsGroupRoutes from "./CRM/ParticipantsGroups";
import CrmSettingsRoutes from "./CRM/CrmSettings";
import EmployeeCrmCountRoutes from "./CRM/EmployeeCrmCount";
import PerformanceRoute from "./TaskAnalysis/Performance";
import CrmEmailsRoutes from "./CRM/CrmEmails";
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { allModulePermission, user } = useSelector((state) => state);

  const handleCheckModulePermission = (moduleName) => {
    let isPublic = false;
    isPublic =
      allModulePermission?.includes(moduleName) && user?.business?.length > 0;
    return user?.isSuperAdmin ? true : isPublic;
  };

  return useRoutes([
    HomeRoutes,
    NoAccessRoute,
    // ******** WORKSPACE MODULE ROUTES *******

    WorkspaceRoutes,

    // ******** SPACE MODULE ROUTES *******

    SpacesRoutes,

    // ******** APPROVAL S2ADMIN MODULE ROUTES *******

    isUserSuperAdmin(user) && BusinessApprovalsRoutes,
    isUserSuperAdmin(user) && EmployeeApprovalsRoutes,

    // ******** ROLE MODULE ROUTES *******
    handleCheckModulePermission(PRIVATEROUTES.ROLE_LIST) && RoleListRoutes,

    // ******** SETTINGS MODULE ROUTES *******
    handleCheckModulePermission(PRIVATEROUTES.MANAGE_PEOPLE) &&
      ManagePeopleRoutes,
    OtherSettingRoutes,

    // ******** TASK MODULE ROUTES *******
    CalendarRoutes,
    MyTasksRoutes,
    LogsRoutes,
    AllTasksRoutes,
    handleCheckModulePermission(PRIVATEROUTES.Task_Overview) &&
      TaskAnalysisRoutes,
    handleCheckModulePermission(PRIVATEROUTES.Performance) && PerformanceRoute,
    // AllBoardsRoutes,
    MyTodoRoutes,

    // ******** STAFF MANAGEMENT  ROUTES *******
    handleCheckModulePermission(PRIVATEROUTES.ONBOARDING) &&
      StaffOnboardingRoutes,
    handleCheckModulePermission(PRIVATEROUTES.ATTENDANCE) &&
      StaffAttendanceRoutes,
    handleCheckModulePermission(PRIVATEROUTES.SALARY) && StaffSalaryRoutes,
    handleCheckModulePermission(PRIVATEROUTES.LEAVE_HISTORY) &&
      LeaveHistoryRoutes,
    handleCheckModulePermission(PRIVATEROUTES.LEAVE_REQUEST) &&
      LeaveRequestRoutes,
    handleCheckModulePermission(PRIVATEROUTES.LEAVE_TYPE) && LeaveTypeRoutes,

    handleCheckModulePermission(PRIVATEROUTES.SHIFT) && StaffShiftTimingRoutes,

    handleCheckModulePermission(PRIVATEROUTES.LOCATION) && StaffLocationRoutes,

    // ******** COMPLAINT MODULE ROUTES *******

    handleCheckModulePermission(PRIVATEROUTES.ALL_GRIEVANCE) &&
      AllComplaintRoutes,
    handleCheckModulePermission(PRIVATEROUTES.MY_GRIEVANCE) &&
      MyComplaintRoutes,
    handleCheckModulePermission(PRIVATEROUTES.GRIVEANCE_HOME) &&
      ComplaintsRateRoutes,

    // ******** COMMUNICATION  ROUTES *******
    handleCheckModulePermission(PRIVATEROUTES.NOTICE) && NoticeRoutes,
    handleCheckModulePermission(PRIVATEROUTES.POLL) && PollRoutes,
    handleCheckModulePermission(PRIVATEROUTES.MEETING) && MeetingRoutes,

    // ******** HOLIDAY  ROUTES *******
    handleCheckModulePermission(PRIVATEROUTES.HOLIDAY) && HolidayRoutes,

    handleCheckModulePermission(PRIVATEROUTES.VENDORS) && VendorRoutes,

    // ******** INVENTORY ROUTES *******

    handleCheckModulePermission(PRIVATEROUTES.MENU_ITEMS) && ItemCategoryRoutes,
    handleCheckModulePermission(PRIVATEROUTES.CUSTOMER) &&
      InventoryCustomerRoutes,
    handleCheckModulePermission(PRIVATEROUTES.RAW_MATERIALS) &&
      RawMaterialRoutes,
    handleCheckModulePermission(PRIVATEROUTES.MANAGE_STOCK) &&
      ManageStockRoutes,
    handleCheckModulePermission(PRIVATEROUTES.ORDER_HISTORY) &&
      OrderHistoryRoutes,
    handleCheckModulePermission(PRIVATEROUTES.ORDER) && OrderRoutes,
    handleCheckModulePermission(PRIVATEROUTES.ORDERAPPROVALS) &&
      OrderApprovalsRoutes,
    handleCheckModulePermission(PRIVATEROUTES.INVENTORY_ANALYTICS) &&
      InventoryAnalyticsRoutes,
    handleCheckModulePermission(PRIVATEROUTES.INVENTORY_SETTINGS) &&
      InventorySettingsRoutes,
    handleCheckModulePermission(PRIVATEROUTES.CASHINHAND) && CashInHandRoutes,
    handleCheckModulePermission(PRIVATEROUTES.TABLES) && AllTablesRoutes,

    // ******** ACCOUNT ROUTES *******
    handleCheckModulePermission(PRIVATEROUTES.ITEMS) && ItemRoutes,
    handleCheckModulePermission(PRIVATEROUTES.CUSTOMERS) && CustomerRoutes,
    handleCheckModulePermission(PRIVATEROUTES.INVOICES) && InvoiceRoutes,
    handleCheckModulePermission(PRIVATEROUTES.QUOTES) && QuotesRoutes,
    handleCheckModulePermission(PRIVATEROUTES.CREDIT_NOTES) &&
      CreditNotesRoutes,
    handleCheckModulePermission(PRIVATEROUTES.CHART_OF_ACCOUNTS) &&
      ChartOfAccountRoutes,

    handleCheckModulePermission(PRIVATEROUTES.BILLS) && BillsRoutes,
    handleCheckModulePermission(PRIVATEROUTES.EXPENSES) && AllExpensesRoutes,

    // ******** CRM ROUTES *******
    handleCheckModulePermission(PRIVATEROUTES.LEADS) && LeadsRoutes,
    handleCheckModulePermission(PRIVATEROUTES.CONTACTS) && ContactsRoutes,
    handleCheckModulePermission(PRIVATEROUTES.DEALS) && DealsRoutes,
    handleCheckModulePermission(PRIVATEROUTES.CRM_MEETING) && CrmMeetingRoutes,
    handleCheckModulePermission(PRIVATEROUTES.CRM_CALLS) && CrmCallsRoutes,
    handleCheckModulePermission(PRIVATEROUTES.CRM_TASK) && CrmTasksRoutes,
    handleCheckModulePermission(PRIVATEROUTES.CRM_TASK) && CrmEmailsRoutes,
    handleCheckModulePermission(PRIVATEROUTES.CRM_ANALYTICS) &&
      CrmAnalyticsRoutes,
    handleCheckModulePermission(PRIVATEROUTES.STATUS_GROUP) && CrmGroupRoutes,
    handleCheckModulePermission(PRIVATEROUTES.PARTICIPANT_GROUP) &&
      ParticipantsGroupRoutes,
    handleCheckModulePermission(PRIVATEROUTES.ACTIVITY_GROUP) &&
      CrmActivityGroupRoutes,
    handleCheckModulePermission(PRIVATEROUTES.CRM_SETTINGS) &&
      CrmSettingsRoutes,
    handleCheckModulePermission(PRIVATEROUTES.Employee_Analytics) &&
      EmployeeCrmCountRoutes,
  ]);
}
