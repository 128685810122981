import React, { useEffect } from "react";
import { truncatedText } from "../../../../../../global-functions/globalFunctions";
import DataLoader from "../../../../../../components/Loaders/DataLoader/DataLoader";
import FooterPagination from "../../../../../../components/Pagination/FooterPagination";
import NoData from "../../../../../../components/NoData/NoData";

const MailTemplatesListModal = ({
  setIsMailTemplatesListModal,
  allMailTemplates,
  mailTemplateLoader,
  setSelectedMailTemplate,
  setEmailDetailsModal,
  mailTemplatePage,
  setMailTemplatePage,
  mailTemplatePageLimit,
  setMailTemplatePageLimit,
  mailTemplateTotalPages,
  mailTemplateCount,
  handleGetAllMailTemplates,
}) => {
  useEffect(() => {
    handleGetAllMailTemplates();
  }, [mailTemplatePage, mailTemplatePageLimit]);

  return (
    <div
      className="modal fade show d-block"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: "800px" }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-3"
              aria-label="Close"
              onClick={() => {
                setIsMailTemplatesListModal(false);
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">Select a template for your email.</h5>
            <div className="d-flex flex-column gap-3">
              <div className="card rounded-4">
                <div className="card-body">
                  <div className="table-responsive">
                    {!mailTemplateLoader && allMailTemplates?.length > 0 && (
                      <table className="table" style={{ marginBottom: "37px" }}>
                        <thead>
                          <tr className="text-nowrap">
                            <th className="text-center">Sl. No.</th>
                            <th>Template Title</th>
                            <th className="text-center">Category</th>
                            <th>Email Subject</th>
                            <th className="text-center">Select</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allMailTemplates?.map((item, index) => {
                            return (
                              <tr>
                                <td className="text-center">
                                  {(mailTemplatePage - 1) *
                                    mailTemplatePageLimit +
                                    (index + 1)}
                                  &nbsp;.
                                </td>
                                <td>
                                  {truncatedText(item?.title || "N/A", 20)}
                                </td>
                                <td className="text-center">
                                  {item?.category || "N/A"}
                                </td>
                                <td>
                                  {truncatedText(item?.subject || "N/A", 40)}
                                </td>
                                <td className="text-center">
                                  <span
                                    className="btn btn-icon btn-primary rounded-circle border"
                                    onClick={() => {
                                      setSelectedMailTemplate(item);
                                      setIsMailTemplatesListModal(false);
                                      setEmailDetailsModal(true);
                                    }}
                                  >
                                    <span className="btn-icon-wrap">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-arrow-right"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                                        />
                                      </svg>
                                    </span>
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}

                    {mailTemplateLoader && (
                      <DataLoader title="data is loading..." />
                    )}

                    {!mailTemplateLoader && allMailTemplates?.length > 0 && (
                      <FooterPagination
                        setpageLimit={setMailTemplatePageLimit}
                        pageLimit={mailTemplatePageLimit}
                        setPage={setMailTemplatePage}
                        page={mailTemplatePage}
                        totalPages={mailTemplateTotalPages}
                        count={mailTemplateCount}
                        dataLength={allMailTemplates?.length}
                        align={"end"}
                      />
                    )}

                    {!mailTemplateLoader && allMailTemplates?.length === 0 && (
                      <NoData title="No Mail Template Added" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailTemplatesListModal;
