import React, { useState } from "react";
import {
  copyToClipboard,
  dateFormatter,
  getCurrentWorkspaceId,
  getName,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { taskEndpoint } from "../../../api/Endpoints";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Avatar } from "../../../components/Avatar/Avatar";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import SmallLoader from "../../../components/Loaders/SmallLoader";
import AssigneEmployeeDetails from "../../../components/AssigneEmployeeDetails/AssigneEmployeeDetails";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";

const TaskListTable = (props) => {
  const {
    taskData,
    handleGetTask,
    subTask,
    handleSubTask,
    loader3,
    setselectedTask,
    settaskDetailsModal,
    handleGetAllTaskStatus,
    taskStatus,
    taskStatusLoader,
    setisAssigneeEmployeeModal,
    page,
    pageLimit,
    setuserInfoData,
    editDueDate,
    seteditDuedDate,
    settaskId,
    isAssigned,
    setListOrSpaceId,
    listOrSpaceId,
    setListName,
    setSpaceName,
    AllTask,
    locationData,
    location,
    handleGetAllTaskList,
  } = props;
  const [isAddEmployeeSubtask, setIsAddEmployeeInSubtask] = useState("");
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const [taskListInput, setTaskListInput] = useState(false);
  const [showSubTask, setShowSubTask] = useState(false);
  const [pleaseWait, setpleaseWait] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [subtaskName, setsubtaskName] = useState("");
  const [loaderForEdit, setloaderForEdit] = useState(false);
  const [copy, setcopy] = useState({
    isTrue: false,
    item: "",
  });
  const [editableTaskId, seteditableTaskId] = useState({
    taskId: "",
    type: "",
  });

  const handleCopy = (text) => {
    setcopy({ isTrue: true, item: text });
    copyToClipboard(text);
    setTimeout(() => {
      setcopy({ isTrue: false, item: text });
    }, 800);
  };

  const taskPriority = [
    {
      name: "No Priority",
      value: "NO_PRIORITY",
      color: "#585656",
    },
    {
      name: "Low",
      value: "LOW",
      color: "#00C172",
    },
    {
      name: "Medium",
      value: "MEDIUM",
      color: "#FFB156",
    },
    {
      name: "High",
      value: "HIGH",
      color: "#FB1010",
    },
  ];

  const handleEditTask = async (
    taskId,
    statusId,
    priority,
    tasktype,
    type,
    id
  ) => {
    startApiCall(null, setloaderForEdit);
    seteditableTaskId({ taskId, type });
    const sendData = {
      taskId,
      status: statusId ? statusId : "",
      userId: user?._id,
      priority: priority ? priority : "",
    };
    const res = await ApiCall("post", taskEndpoint.editTask, sendData);
    if (res?.success) {
      toast.success(`Change ${priority ? "Priority" : "Status"} successfully!`);
      if (tasktype === "maintask") {
        seteditableTaskId({ taskId: "", type: "" });
        handleGetTask();
        handleGetAllTaskList();
      } else {
        seteditableTaskId({ taskId: "", type: "" });
        handleSubTask(id);
      }
      setloaderForEdit(false);
    } else {
      seteditableTaskId({ taskId: "", type: "" });
      setloaderForEdit(false);
      seterrorMessage(res.error);
    }
  };

  const handleCreateSubtask = async (e, taskId) => {
    e.preventDefault();
    startApiCall(null, setpleaseWait);
    const sendData = {
      name: subtaskName,
      taskType: "subtask",
      taskAbilities: "business",
      createdBy: user?._id,
      assignedBy: [user?._id],
      businessId: getCurrentWorkspaceId(),
      list: isAssigned || AllTask ? listOrSpaceId : id,
      maintaskId: taskId,
      assignedTo: isAssigned ? [user?._id] : [],
    };
    const res = await ApiCall("post", taskEndpoint.createTask, sendData);
    if (res?.success) {
      setsubtaskName("");
      if (isAssigned) {
        handleAssignee();
      }
      toast.success("Create Subtask successfully!");
      handleGetTask();
      setpleaseWait(false);
    } else {
      setpleaseWait(false);
      seterrorMessage(res.error);
    }
  };

  const handleAssignee = async () => {
    const sendData = {
      taskId: isAddEmployeeSubtask,
      userId: user?._id,
      employees: [{ employeeId: user?._id, isRemoved: false }],
    };
    const res = await ApiCall("post", taskEndpoint.assignTask, sendData);
    if (res?.success) {
      setpleaseWait(false);
    }
  };

  const toggleSubTask = (taskId) => {
    setShowSubTask((prevState) => ({
      ...prevState,
      [taskId]: !prevState[taskId],
    }));
  };

  return (
    <>
      <div className="table-wrap pb-5">
        <div
          id="datable_1t_wrapper"
          className="dataTables_wrapper dt-bootstrap5 no-footer"
        >
          <div className="row">
            <div className="col-sm-12">
              <table className="table table-bordered nowrap gt-todo-table dataTable no-footer">
                <thead>
                  <tr role="row">
                    <th className="text-center font-2">No .</th>
                    <th className="w-60 font-2" colSpan="5">
                      Task Name
                    </th>
                    {/* <th className="text-center font-2">Task ID</th> */}
                    <th className="text-center font-2">Created By</th>
                    <th className="text-center font-2">Assignee</th>
                    {/* <th className="text-center font-2">Date created</th> */}
                    <th className="text-center font-2">Due Date</th>
                    <th className="text-center font-2">Status</th>
                    <th className="text-center font-2">Priority</th>
                  </tr>
                </thead>
                <tbody>
                  {taskData?.map((task, taskindex) => {
                    const isUserAssigned = task?.subtasks?.filter((subt) =>
                      subt?.assignedTo?.includes(user?._id)
                    );
                    return (
                      <>
                        <tr role="row">
                          <td className="text-center pointer font-2">
                            {(page - 1) * pageLimit + (taskindex + 1)} .
                          </td>
                          <td
                            className="w-20 text-start pointer font-3 align-items-center text-truncate"
                            colSpan="5"
                          >
                            <div
                              className="d-flex justify-content-between "
                              style={{ width: "100%", margin: 0, padding: 0 }}
                            >
                              <div className="col-md-10 d-flex align-items-center w-90">
                                {task?.subtasks?.length > 0 &&
                                  showSubTask[task?._id] && (
                                    <div className="d-flex">
                                      <span className="badge badge-sm badge-pill badge-soft-info d-flex justify-content-center align-items-center font-3 me-2">
                                        {isAssigned
                                          ? isUserAssigned?.length
                                          : task?.subtasks?.length}
                                      </span>

                                      <span
                                        className="text-primary pointer me-2"
                                        onClick={() => {
                                          toggleSubTask(task?._id);
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12"
                                          height="12"
                                          fill="#000"
                                          class="bi bi-chevron-down text-primary"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                  )}
                                {isAssigned ? (
                                  <>
                                    {task?.subtasks?.length > 0 &&
                                      !showSubTask[task?._id] &&
                                      isUserAssigned?.length > 0 && (
                                        <div className="d-flex">
                                          <span className="badge badge-sm badge-pill badge-soft-info d-flex justify-content-center align-items-center font-3 me-2">
                                            {isAssigned
                                              ? isUserAssigned?.length
                                              : task?.subtasks?.length}
                                          </span>

                                          <span
                                            className="text-primary pointer me-2"
                                            onClick={() => {
                                              handleSubTask(task?._id);
                                              toggleSubTask(task?._id);
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="12"
                                              height="12"
                                              fill="#000"
                                              class="bi bi-chevron-right"
                                              viewBox="0 0 16 16"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      )}
                                  </>
                                ) : (
                                  <>
                                    {task?.subtasks?.length > 0 &&
                                      !showSubTask[task?._id] && (
                                        <div className="d-flex">
                                          <span className="badge badge-sm badge-pill badge-soft-info d-flex justify-content-center align-items-center font-3 me-2">
                                            {isAssigned
                                              ? isUserAssigned?.length
                                              : task?.subtasks?.length}
                                          </span>

                                          <span
                                            className="text-primary pointer me-2"
                                            onClick={() => {
                                              handleSubTask(task?._id);
                                              toggleSubTask(task?._id);
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="12"
                                              height="12"
                                              fill="#000"
                                              class="bi bi-chevron-right"
                                              viewBox="0 0 16 16"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      )}
                                  </>
                                )}

                                <span
                                  className="todo-text text-dark f text-capitalize"
                                  onClick={() => {
                                    setselectedTask(task);
                                    settaskDetailsModal(true);
                                    if (isAssigned) {
                                      setListName(task?.list?.name);
                                      setSpaceName(task?.space?.name);
                                    }
                                  }}
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  <p className="text-dark mb-2 text-truncate">
                                    {task?.name}
                                  </p>
                                  {isAssigned && (
                                    <p className="d-flex align-items-center gap-1 mb-1 bg-light p-1 rounded-2">
                                      {task?.folder && (
                                        <>
                                          <div className="avatar avatar-icon avatar-xxs avatar-soft-warning">
                                            <span className="initial-wrap">
                                              <i
                                                className="ri-folder-2-fill"
                                                style={{ fontSize: "8px" }}
                                              ></i>
                                            </span>
                                          </div>
                                          <span className="fs-7 ms-1">
                                            {task?.folder?.name}
                                          </span>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="13"
                                            height="13"
                                            fill="currentColor"
                                            className="bi bi-chevron-right"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              fill-rule="evenodd"
                                              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                                            />
                                          </svg>
                                        </>
                                      )}

                                      <div className="avatar avatar-icon avatar-soft-blue avatar-xxs">
                                        <span className="initial-wrap">
                                          <i
                                            className="ri-file-text-fill"
                                            style={{ fontSize: "8px" }}
                                          ></i>
                                        </span>
                                      </div>
                                      <span className="fs-7 ms-1">
                                        {task?.list?.name}
                                      </span>
                                    </p>
                                  )}
                                </span>
                              </div>
                              <div className="col-md-2 d-flex justify-content-end pt-1 w-10">
                                <span
                                  onClick={() => {
                                    if (isAssigned || AllTask) {
                                      setListOrSpaceId(task?.list?._id);
                                      setIsAddEmployeeInSubtask(task?._id);
                                    }
                                    setTaskListInput((prevOpenInputTasks) => ({
                                      ...prevOpenInputTasks,
                                      [task._id]: !prevOpenInputTasks[task._id],
                                    }));
                                  }}
                                >
                                  {taskListInput[task._id] ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      fill="currentColor"
                                      className="bi bi-dash fw-semibold "
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-plus-lg fw-semibold"
                                      viewBox="0 0 16 16"
                                      strokeWidth="7"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                                      />
                                    </svg>
                                  )}
                                </span>
                              </div>
                            </div>
                          </td>
                          {/* 
                          <td className="text-center pointer text-dark font-2">
                            <CustomTooltip
                              text={
                                copy?.isTrue &&
                                copy?.item === task?.taskReferenceId ? (
                                  <>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="#6cd16b"
                                      class="bi bi-clipboard-check-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3Zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Z" />
                                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1Zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708Z" />
                                    </svg>{" "}
                                    Copied
                                  </>
                                ) : (
                                  <>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      class="bi bi-clipboard2"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M3.5 2a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5H12a.5.5 0 0 1 0-1h.5A1.5 1.5 0 0 1 14 2.5v12a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-12A1.5 1.5 0 0 1 3.5 1H4a.5.5 0 0 1 0 1h-.5Z" />
                                      <path d="M10 .5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5V2a.5.5 0 0 0 .5.5h5A.5.5 0 0 0 11 2v-.5a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5Z" />
                                    </svg>{" "}
                                    Copy
                                  </>
                                )
                              }
                              placement="bottom"
                            >
                              <div
                                className="text-center"
                                onClick={() => {
                                  handleCopy(task?.taskReferenceId);
                                }}
                              >
                        
                                <>
                                  {task?.taskReferenceId}
                                </>
                              </div>
                            </CustomTooltip>
                          </td>*/}
                          <td className="text-center pointer font-2">
                            <div className="text-center">
                              <CustomTooltip
                                text={getName(task?.createdBy, user?._id)}
                                placement="bottom"
                              >
                                <Avatar
                                  name={task?.createdBy?.name}
                                  count={1}
                                  image={task?.createdBy?.image}
                                  size="xss"
                                  color="primary"
                                />
                              </CustomTooltip>
                            </div>
                          </td>
                          <td className="text-center pointer font-2">
                            <div className="text-center">
                              <div className="avatar-group avatar-group-sm avatar-group-overlapped pointer font-2">
                                {task?.assignedTo
                                  ?.slice(0, 3)
                                  ?.map((assine) => {
                                    return (
                                      <CustomTooltip
                                        text={getName(assine, user?._id)}
                                        placement="bottom"
                                      >
                                        <span
                                          data-bs-toggle="modal"
                                          data-bs-target="#user_information_modal"
                                          onClick={() => {
                                            setuserInfoData(assine);
                                          }}
                                        >
                                          <Avatar
                                            name={assine?.name}
                                            count={1}
                                            image={assine?.image}
                                            size="xss"
                                            color="primary"
                                          />
                                        </span>
                                      </CustomTooltip>
                                    );
                                  })}
                                {task?.assignedTo?.length > 3 && (
                                  <CustomTooltip
                                    text="view all"
                                    placement="bottom"
                                  >
                                    <div className="selectable-dropdown">
                                      <div className="dropdown selectable-dropdown">
                                        <span
                                          className="avatar avatar-xs avatar-soft-indigo avatar-rounded"
                                          data-bs-toggle="dropdown"
                                        >
                                          <span className="initial-wrap">
                                            +{task?.assignedTo?.length - 3}
                                          </span>
                                        </span>
                                        <AssigneEmployeeDetails
                                          taskDetails={task?.assignedTo}
                                        />
                                      </div>
                                    </div>
                                  </CustomTooltip>
                                )}
                                <CustomTooltip
                                  text="add assignee"
                                  placement="bottom"
                                >
                                  <div
                                    className="avatar avatar-xxs avatar-soft-indigo avatar-rounded font-1"
                                    onClick={() => {
                                      if (isAssigned || AllTask) {
                                        setListOrSpaceId(task?.list?._id);
                                      }
                                      setselectedTask(task);
                                      setisAssigneeEmployeeModal(true);
                                    }}
                                  >
                                    <span className="initial-wrap">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="12"
                                        fill="currentColor"
                                        className="bi bi-plus-lg"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                                        />
                                      </svg>
                                    </span>
                                  </div>
                                </CustomTooltip>
                              </div>
                            </div>
                          </td>
                          {/* <td className="text-center pointer font-2">
                            <div className="text-center">
                              {dateFormatter(task?.createdAt)}
                            </div>
                          </td> */}
                          <td className="text-center pointer font-2">
                            <div className="d-flex flex-column gap-1">
                              <span className="d-flex align-items-center justify-content-center gap-1">
                                <span>
                                  {task?.dueDate
                                    ? dateFormatter(task?.dueDate)
                                    : "No Due Date"}
                                </span>
                                <span
                                  className="d-flex"
                                  style={{
                                    display: "grid",
                                    placeItems: "center",
                                    marginTop: -8,
                                  }}
                                  onClick={() => {
                                    seteditDuedDate(!editDueDate);
                                    settaskId(task);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="10"
                                    fill="#3862DD"
                                    className="bi bi-pencil-square"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                    <path
                                      fill-rule="evenodd"
                                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                    />
                                  </svg>
                                </span>
                              </span>
                            </div>
                          </td>
                          <td className="text-center pointer font-3">
                            <div className="selectable-dropdown">
                              <div className="dropdown selectable-dropdown">
                                {(editableTaskId?.type === "priority" ||
                                  editableTaskId?.taskId !== task?._id) && (
                                  <span
                                    className={` d-flex align-items-center justify-content-center gap-1 fw-medium dropdown-toggle text-capitalize`}
                                    aria-expanded="false"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    onClick={() => {
                                      handleGetAllTaskStatus(task?.space?._id);
                                    }}
                                  >
                                    <svg
                                      className="circle"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                      fill={task?.status?.color}
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        width="15"
                                        height="15"
                                        fill={task?.status?.color}
                                      />
                                    </svg>{" "}
                                    {task?.status?.statusName}
                                  </span>
                                )}
                                {editableTaskId?.taskId === task?._id &&
                                  loaderForEdit &&
                                  editableTaskId?.type === "status" && (
                                    <SmallLoader />
                                  )}
                                <div
                                  role="menu"
                                  className="dropdown-menu"
                                  style={{
                                    maxHeight: "40vh",
                                    overflow: "auto",
                                  }}
                                >
                                  {taskStatusLoader ? (
                                    <span className="text-center">
                                      Loading...
                                    </span>
                                  ) : (
                                    <>
                                      {taskStatus?.map((status) => (
                                        <button
                                          disabled={
                                            task?.status?._id === status?._id
                                          }
                                          className="dropdown-item text-capitalize d-flex align-items-center gap-1"
                                          key={status._id}
                                          value={status._id}
                                          onClick={() => {
                                            handleEditTask(
                                              task._id,
                                              status._id,
                                              null,
                                              "maintask",
                                              "status"
                                            );
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="12"
                                            height="12"
                                            fill={status?.color}
                                            className="bi bi-square-fill"
                                            viewBox="0 0 16 16"
                                            style={{
                                              borderRadius: "3px",
                                            }}
                                          >
                                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z" />
                                          </svg>
                                          {status?.statusName}
                                        </button>
                                      ))}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </td>

                          <td className="text-center pointer">
                            <div className="selectable-dropdown">
                              <div className="dropdown selectable-dropdown">
                                {(editableTaskId?.type === "status" ||
                                  editableTaskId?.taskId !== task?._id) && (
                                  <span
                                    className={` d-flex align-items-center justify-content-center gap-1 fw-medium dropdown-toggle text-capitalize`}
                                    aria-expanded="false"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                  >
                                    <CustomTooltip
                                      text={task?.priority}
                                      placement="bottom"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        className="bi bi-flag-fill me-2"
                                        fill={
                                          task?.priority === "LOW"
                                            ? "#0D6EFD"
                                            : task?.priority === "MEDIUM"
                                            ? "#FFC400"
                                            : task?.priority === "NO_PRIORITY"
                                            ? "#505050"
                                            : "#FF0101"
                                        }
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                                      </svg>
                                    </CustomTooltip>
                                  </span>
                                )}

                                {editableTaskId?.taskId === task?._id &&
                                  loaderForEdit &&
                                  editableTaskId?.type === "priority" && (
                                    <SmallLoader />
                                  )}
                                <div role="menu" className="dropdown-menu">
                                  {taskPriority?.map((priority, index) => (
                                    <button
                                      disabled={
                                        task?.priority === priority.value
                                      }
                                      key={index}
                                      className="dropdown-item"
                                      onClick={() => {
                                        handleEditTask(
                                          task._id,
                                          null,
                                          priority?.value,
                                          "maintask",
                                          "priority"
                                        );
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        className="bi bi-flag-fill me-2"
                                        fill={priority?.color}
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                                      </svg>
                                      {priority?.name}
                                    </button>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        {/* ****************** ADD SUBTASK ****************** */}

                        {taskListInput[task?._id] && (
                          <tr className="group">
                            <td></td>
                            <td className="row-sep" colspan="12" font-2>
                              <div className="row ms-2">
                                <small>Add Sub task</small>
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                <input
                                  className="border form-control"
                                  type="text"
                                  style={{ outline: "none" }}
                                  onChange={(e) => {
                                    setsubtaskName(e.target.value);
                                  }}
                                />
                                <span
                                  className={`btn ${
                                    pleaseWait ? "btn-secondary" : "btn-primary"
                                  } btn-sm`}
                                  onClick={(e) => {
                                    if (!pleaseWait) {
                                      handleCreateSubtask(e, task?._id);
                                    } else {
                                      //nothing
                                    }
                                  }}
                                >
                                  {pleaseWait ? <ApiLoader /> : "Add"}
                                </span>
                                <span
                                  className="btn btn-outline-light btn-sm"
                                  onClick={() => {
                                    setTaskListInput((prevOpenInputTasks) => ({
                                      ...prevOpenInputTasks,
                                      [task._id]: false,
                                    }));
                                  }}
                                >
                                  <i className="ri-close-line"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                        )}

                        {/* ****************** SUBTASK ****************** */}
                        {showSubTask[task?._id] &&
                          task?.subtasks?.length > 0 &&
                          !loader3[task?._id] && (
                            <>
                              {subTask[task?._id]?.map((subtask, index) => (
                                <tr
                                  key={subtask._id}
                                  className="odd bg-grey-light-5 mh-100p"
                                  id="task_lists"
                                >
                                  <td className="text-center  pointer font-2 "></td>
                                  <td
                                    className="w-50 text-start pointer font-2 text-truncate"
                                    colSpan="5"
                                  >
                                    <>
                                      &nbsp;&nbsp;
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="22"
                                        fill="currentColor"
                                        class="bi bi-arrow-return-right text-primary ms-1"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"
                                        />
                                      </svg>
                                      &nbsp; &nbsp;
                                      <>
                                        {(page - 1) * pageLimit +
                                          (taskindex + 1)}
                                        .{index + 1}. &nbsp;
                                        <span
                                          className="text-dark text-capitalize"
                                          style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            fontSize: "13px",
                                          }}
                                          onClick={() => {
                                            setselectedTask(subtask);
                                            settaskDetailsModal(true);
                                          }}
                                        >
                                          {subtask?.name}
                                        </span>
                                      </>
                                    </>
                                  </td>
                                  {/* <td className="text-center pointer font-2 text-dark">
                                    <CustomTooltip
                                      text={
                                        copy?.isTrue &&
                                        copy?.item ===
                                          subtask?.taskReferenceId ? (
                                          <>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="#6cd16b"
                                              class="bi bi-clipboard-check-fill"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3Zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Z" />
                                              <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1Zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708Z" />
                                            </svg>{" "}
                                            Copied
                                          </>
                                        ) : (
                                          <>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="currentColor"
                                              class="bi bi-clipboard2"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M3.5 2a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5H12a.5.5 0 0 1 0-1h.5A1.5 1.5 0 0 1 14 2.5v12a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-12A1.5 1.5 0 0 1 3.5 1H4a.5.5 0 0 1 0 1h-.5Z" />
                                              <path d="M10 .5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5V2a.5.5 0 0 0 .5.5h5A.5.5 0 0 0 11 2v-.5a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5Z" />
                                            </svg>{" "}
                                            Copy
                                          </>
                                        )
                                      }
                                      placement="bottom"
                                    >
                                      <div
                                        className="text-center"
                                        onClick={() => {
                                          handleCopy(subtask?.taskReferenceId);
                                        }}
                                      >
                                     
                                        <>
                                         
                                          {subtask?.taskReferenceId}
                                        </>
                                      </div>
                                    </CustomTooltip>
                                  </td> */}

                                  <td className="text-center pointer font-2">
                                    <div className="text-center">
                                      <CustomTooltip
                                        text={getName(
                                          subtask?.createdBy,
                                          user?._id
                                        )}
                                        placement="bottom"
                                      >
                                        <Avatar
                                          name={subtask?.createdBy?.name}
                                          count={1}
                                          image={subtask?.createdBy?.image}
                                          size="xxs"
                                          color="primary"
                                        />
                                      </CustomTooltip>
                                    </div>
                                  </td>

                                  <td className="text-center pointer font-2">
                                    <div className="text-center">
                                      <div className="avatar-group avatar-group-sm avatar-group-overlapped pointer font-2">
                                        {subtask?.assignedTo
                                          ?.slice(0, 3)
                                          ?.map((assine) => {
                                            return (
                                              <CustomTooltip
                                                text={getName(
                                                  assine,
                                                  user?._id
                                                )}
                                                placement="bottom"
                                              >
                                                <span
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#user_information_modal"
                                                  onClick={() => {
                                                    setuserInfoData(assine);
                                                  }}
                                                >
                                                  <Avatar
                                                    name={assine?.name}
                                                    count={1}
                                                    image={assine?.image}
                                                    size="xxs"
                                                    color="primary"
                                                  />
                                                </span>
                                              </CustomTooltip>
                                            );
                                          })}
                                        {subtask?.assignedTo?.length > 3 && (
                                          <CustomTooltip
                                            text="view all"
                                            placement="bottom"
                                          >
                                            <div className="selectable-dropdown">
                                              <div className="dropdown selectable-dropdown">
                                                <span
                                                  className="avatar avatar-xs avatar-soft-indigo avatar-rounded"
                                                  data-bs-toggle="dropdown"
                                                >
                                                  <span className="initial-wrap">
                                                    +
                                                    {subtask?.assignedTo
                                                      ?.length - 3}
                                                  </span>
                                                </span>
                                                <AssigneEmployeeDetails
                                                  taskDetails={
                                                    subtask?.assignedTo
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </CustomTooltip>
                                        )}
                                        <CustomTooltip
                                          text="add assignee"
                                          placement="bottom"
                                        >
                                          <div
                                            className="avatar avatar-xxs avatar-soft-indigo avatar-rounded font-1"
                                            onClick={() => {
                                              if (isAssigned) {
                                                setListOrSpaceId(
                                                  subtask?.list?._id
                                                );
                                              }
                                              setselectedTask(subtask);
                                              setisAssigneeEmployeeModal(true);
                                            }}
                                          >
                                            <span className="initial-wrap">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="12"
                                                height="12"
                                                fill="currentColor"
                                                className="bi bi-plus-lg"
                                                viewBox="0 0 16 16"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                                                />
                                              </svg>
                                            </span>
                                          </div>
                                        </CustomTooltip>
                                      </div>
                                    </div>
                                  </td>
                                  {/* <td className="text-center pointer font-2">
                                    {" "}
                                    <div className="text-center">
                                      {dateFormatter(subtask?.createdAt)}
                                    </div>
                                  </td> */}
                                  <td className="text-center pointer font-2">
                                    <div className="d-flex flex-column gap-1">
                                      <span className="d-flex align-items-center justify-content-center gap-1">
                                        <span>
                                          {subtask?.dueDate
                                            ? dateFormatter(subtask?.dueDate)
                                            : "No Due Date"}
                                        </span>
                                        <span
                                          className="d-flex"
                                          style={{
                                            display: "grid",
                                            placeItems: "center",
                                            marginTop: -8,
                                          }}
                                          onClick={() => {
                                            seteditDuedDate(!editDueDate);
                                            settaskId(subtask);
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill="#3862DD"
                                            className="bi bi-pencil-square"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path
                                              fill-rule="evenodd"
                                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                            />
                                          </svg>
                                        </span>
                                      </span>
                                    </div>
                                  </td>
                                  <td className="text-center pointer font-2">
                                    <div className="selectable-dropdown">
                                      <div className="dropdown selectable-dropdown">
                                        {(editableTaskId?.type === "priority" ||
                                          editableTaskId?.taskId !==
                                            subtask?._id) && (
                                          <span
                                            className={` d-flex align-items-center justify-content-center gap-1 fw-medium dropdown-toggle text-capitalize`}
                                            aria-expanded="false"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            onClick={() => {
                                              handleGetAllTaskStatus(
                                                subtask?.space?._id
                                              );
                                            }}
                                          >
                                            <svg
                                              className="circle"
                                              width="14"
                                              height="14"
                                              viewBox="0 0 14 14"
                                              fill={subtask?.status?.color}
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <rect
                                                width="15"
                                                height="15"
                                                fill={subtask?.status?.color}
                                              />
                                            </svg>{" "}
                                            {subtask?.status?.statusName}
                                          </span>
                                        )}
                                        {editableTaskId?.taskId ===
                                          subtask?._id &&
                                          loaderForEdit &&
                                          editableTaskId?.type === "status" && (
                                            <SmallLoader />
                                          )}
                                        <div
                                          role="menu"
                                          className="dropdown-menu"
                                          style={{
                                            maxHeight: "40vh",
                                            overflow: "auto",
                                          }}
                                        >
                                          {taskStatusLoader ? (
                                            <span className="text-center">
                                              Loading...
                                            </span>
                                          ) : (
                                            <>
                                              {taskStatus?.map((status) => (
                                                <button
                                                  disabled={
                                                    subtask?.status?._id ===
                                                    status?._id
                                                  }
                                                  className="dropdown-item text-capitalize d-flex align-items-center gap-1"
                                                  key={status._id}
                                                  value={status._id}
                                                  onClick={() => {
                                                    handleEditTask(
                                                      subtask._id,
                                                      status._id,
                                                      null,
                                                      "subtask",
                                                      "status",
                                                      task?._id
                                                    );
                                                  }}
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="12"
                                                    height="12"
                                                    fill={status?.color}
                                                    className="bi bi-square-fill"
                                                    viewBox="0 0 16 16"
                                                    style={{
                                                      borderRadius: "3px",
                                                    }}
                                                  >
                                                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z" />
                                                  </svg>
                                                  {status?.statusName}
                                                </button>
                                              ))}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="text-center pointer font-2">
                                    <div className="selectable-dropdown">
                                      <div className="dropdown selectable-dropdown">
                                        {(editableTaskId?.type === "status" ||
                                          editableTaskId?.taskId !==
                                            subtask?._id) && (
                                          <span
                                            className={` d-flex align-items-center justify-content-center gap-1 fw-medium dropdown-toggle text-capitalize`}
                                            aria-expanded="false"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                          >
                                            <CustomTooltip
                                              text={subtask?.priority}
                                              placement="bottom"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                className="bi bi-flag-fill me-2"
                                                fill={
                                                  subtask?.priority === "LOW"
                                                    ? "#0D6EFD"
                                                    : subtask?.priority ===
                                                      "MEDIUM"
                                                    ? "#FFC400"
                                                    : subtask?.priority ===
                                                      "NO_PRIORITY"
                                                    ? "#505050"
                                                    : "#FF0101"
                                                }
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                                              </svg>
                                            </CustomTooltip>
                                          </span>
                                        )}

                                        {editableTaskId?.taskId ===
                                          subtask?._id &&
                                          loaderForEdit &&
                                          editableTaskId?.type ===
                                            "priority" && <SmallLoader />}
                                        <div
                                          role="menu"
                                          className="dropdown-menu"
                                        >
                                          {taskPriority?.map(
                                            (priority, index) => (
                                              <button
                                                disabled={
                                                  subtask?.priority ===
                                                  priority.value
                                                }
                                                key={index}
                                                className="dropdown-item"
                                                onClick={() => {
                                                  handleEditTask(
                                                    subtask._id,
                                                    null,
                                                    priority?.value,
                                                    "subtask",
                                                    "priority",
                                                    task?._id
                                                  );
                                                }}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  className="bi bi-flag-fill me-2"
                                                  fill={priority?.color}
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                                                </svg>
                                                {priority?.name}
                                              </button>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        {loader3[task?._id] &&
                          showSubTask[task?._id] &&
                          task?.subtasks?.length > 0 && (
                            <>
                              {/* {subTask[task?._id]?.map((subtask, index) => ( */}
                              <tr>
                                <td className="text-center">
                                  <span class="placeholder "></span>
                                </td>

                                <td colSpan="3" className="text-center">
                                  <span class="placeholder-glow d-inline-block w-100 bg-light task-table-skeleton"></span>
                                </td>
                                <td className="text-center">
                                  <span class="placeholder-glow d-inline-block w-100 bg-light task-table-skeleton"></span>
                                </td>
                                <td className="text-center">
                                  <span class="placeholder-glow d-inline-block w-30 h-30 circle bg-light task-table-skeleton"></span>
                                </td>
                                <td className="text-center">
                                  <span class="placeholder-glow d-inline-block w-100 bg-light task-table-skeleton"></span>
                                </td>
                                <td className="text-center">
                                  <span class="placeholder-glow d-inline-block w-100 bg-light task-table-skeleton"></span>
                                </td>
                                <td className="text-center">
                                  <span class="placeholder-glow d-inline-block w-100 bg-light task-table-skeleton"></span>
                                </td>
                                <td className="text-center">
                                  <span class="placeholder-glow d-inline-block w-100 bg-light task-table-skeleton"></span>
                                </td>
                                <td className="text-center">
                                  <span class="placeholder-glow d-inline-block w-100 bg-light task-table-skeleton"></span>
                                </td>
                              </tr>
                              {/* ))} */}
                            </>
                          )}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskListTable;
