import React, { useEffect, useRef, useState } from "react";
import DealOverviewHeader from "./DealOverviewHeader";
import DealDetailsLeft from "./DealDetailsLeft";
import AddMeetingCrm from "../../../Leads/LeadsDetails/LeadOverview/MeetingCard/AddMeetingCrm";
import AddTaskCrm from "../../../Leads/LeadsDetails/LeadOverview/TaskCard/AddTaskCrm";
import CreateDeal from "../../CreateDeal";
import PrintDeals from "../PrintDeals";
import DealEmailModal from "../DealEmailModal";
import { useLocation, useParams } from "react-router-dom";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../../global-functions/globalFunctions";
import ApiCall from "../../../../../api/ApiCall";
import {
  CrmTaskRelatedEndPoints,
  DealRelatedEndPoints,
  DynamicEmailRelatedEndPoints,
  LeadRelatedEndPoints,
  StatusRelatedEndPoints,
} from "../../../../../api/Endpoints";
import CreateCalls from "../../../Leads/LeadsDetails/LeadOverview/CallsCard/CreateCalls";
import TimelineMoreInfoModal from "../../../Leads/LeadsDetails/LeadOverview/TimelineMoreInfoModal";
import AssigneGroupModal from "../../../Assignee/AssigneGroupModal";
import CallDetailsModal from "../../../Leads/LeadsDetails/LeadOverview/CallsCard/CallDetailsModal";
import TaskActionWarningModal from "../../../Leads/LeadsDetails/LeadOverview/TaskActionWarning/TaskActionWarningModal";
import AssigneeEmployeeInLead from "../../../Assignee/AssigneeEmployeeInLead";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import LeadStatusChangeModal from "../../../Leads/LeadsDetails/LeadOverview/LeadStatusChangeModal";
import UpdatedForModal from "../../../Leads/LeadsDetails/LeadOverview/UpdatedForModal";
import ImageViewModal from "../../../Leads/LeadsDetails/LeadOverview/ActivityMandatoryTask/ImageViewModal";
import ChooseOption from "./MailTemplates/ChooseOption";
import MailTemplatesListModal from "./MailTemplates/MailTemplatesListModal";

const DealOverview = () => {
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const componentRef = useRef();
  const [emailDetailsModal, setEmailDetailsModal] = useState(false);
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [dealDetails, setDealDetails] = useState([]);
  const [editDealData, setEditDealData] = useState("");
  const [subStatus, setSubStatus] = useState([]);
  const [locationData, setlocationData] = useState([]);
  const [taskLoader, settaskLoader] = useState(false);
  const [allActivity, setallActivity] = useState([]);
  const [moreInfoModal, setmoreInfoModal] = useState(false);
  const [timelineDetails, settimelineDetails] = useState(null);
  const [activityDetails, setactivityDetails] = useState("");
  const [statusWarningDetails, setstatusWarningDetails] = useState([]);
  const [taskWarningModal, settaskWarningModal] = useState(false);
  const [editDealParticipant, seteditDealParticipant] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [changeStatusloader, setChangeStatusLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [statusId, setStatusId] = useState("");
  const [selectedParticipantId, setselectedParticipantId] = useState([]);
  const [deadline, setDeadline] = useState(0);
  const [closingDate, setclosingDate] = useState("");
  const [expectedRevenue, setexpectedRevenue] = useState("");
  const [allEmployees, setallEmployees] = useState([]);
  const [updatedForModal, setupdatedForModal] = useState(false);
  const [mandatoryLoader, setmandatoryLoader] = useState(false);
  const [imageData, setimageData] = useState([]);
  const [imageModal, setimageModal] = useState(false);
  const [editTask, seteditTask] = useState("");
  const [totalCounts, setTotalCounts] = useState([]);

  const [mailTemplateLoader, setMailTemplateLoader] = useState(false);
  const [allMailTemplates, setAllMailTemplates] = useState([]);

  const [isMailTemplatesListModal, setIsMailTemplatesListModal] =
    useState(false);
  const [selectedMailTemplate, setSelectedMailTemplate] = useState("");
  const [mailTemplatePage, setMailTemplatePage] = useState(1);
  const [mailTemplatePageLimit, setMailTemplatePageLimit] = useState(10);
  const [mailTemplateCount, setMailTemplateCount] = useState(0);
  const [mailTemplateTotalPages, setMailTemplateTotalPages] = useState(0);

  // Assign Group and Assign Employee
  const [assigneeModal, setassigneeModal] = useState(false);
  const [selectedEmployees, setselectedEmployees] = useState([]);
  const [selectedEmpId, setselectedEmpId] = useState([]);
  const [groupAssigneeModal, setgroupAssigneeModal] = useState(false);
  const [selectedGroups, setselectedGroups] = useState([]);
  const [selectedGroupId, setselectedGroupId] = useState([]);
  const [editGroup, seteditGroup] = useState("");

  const handleReset = () => {
    setselectedEmpId([]);
    setselectedEmployees([]);
    setassigneeModal(false);
  };

  useEffect(() => {
    setlocationData(location?.state);
  }, [location?.state]);

  // GET DEAL DETAILS FUNCTION API CALL
  const handleGetDealDetails = async () => {
    startApiCall(null, setloader);
    const data = {
      dealId: id,
    };
    const res = await ApiCall(
      "post",
      DealRelatedEndPoints.getDealDetails,
      data
    );
    if (res?.success) {
      setDealDetails(res?.result);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetDealDetails();
  }, []);

  const handleEditDeal = async (e) => {
    e.preventDefault();
    startApiCall(null, setloader2);
    const data = {
      dealId: id,
      closingDate: closingDate,
      closingAmount: expectedRevenue,
      actionBy: user?._id,
    };
    const res = await ApiCall("post", LeadRelatedEndPoints.editDeal, data);
    if (res?.success) {
      handleChangeStatus(e);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  //Add Participant to Deal
  const handleAddParticipantToDeal = async (e) => {
    e.preventDefault();
    startApiCall(null, setloader3);
    const data = {
      leadOrDealId: id,
      employeeIds: selectedEmpId,
      participantsGroupActionBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      LeadRelatedEndPoints.addParticipantInLead,
      data
    );
    if (res?.success) {
      handleReset();
      handleGetDealDetails();
      toast.success("Participant added successfully!");
      setloader3(false);
    } else {
      setloader3(false);
    }
  };

  // GET STATUS BY INITIALSTATUS ID
  const handleGetStatusSteps = async () => {
    startApiCall(null, setStatusLoader);
    const data = {
      page: 1,
      limit: 1000,
      filters: {
        statusGroupId: dealDetails?.groupId?._id,
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall("post", StatusRelatedEndPoints.getStatus, data);
    if (res?.success) {
      setSubStatus(res?.result?.data);
      setStatusLoader(false);
    } else {
      setStatusLoader(false);
    }
  };

  useEffect(() => {
    if (dealDetails?.groupId) {
      handleGetStatusSteps();
    }
  }, [dealDetails]);

  // GET MEETING,CALL,TASK FUNCTION API CALL
  const handleGetAllTask = async () => {
    startApiCall(null, settaskLoader);
    const data = {
      page: 1,
      limit: 5000,
      sortBy: "createdAt",
      filters: {
        actionOn: id,
        isDeleted: false,
        relatedTo: "Deal",
      },
    };
    const res = await ApiCall("post", CrmTaskRelatedEndPoints.getTask, data);
    if (res?.success) {
      const activity = res?.result?.data;
      setallActivity(activity);
      setTotalCounts(res?.result);
      settaskLoader(false);
    } else {
      settaskLoader(false);
    }
  };

  useEffect(() => {
    handleGetAllTask();
  }, []);

  const handleRefresh = () => {
    handleGetDealDetails();
    handleGetAllTask();
  };
  const handleClose = () => {
    setIsOpen(false);
    seterrorMessage("");
    setallEmployees([]);
    setselectedParticipantId([]);
    setChangeStatusLoader(false);
    setclosingDate("");
    setexpectedRevenue("");
  };

  const handleChangeStatus = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setChangeStatusLoader);
    const temp = allEmployees?.map((emp) => emp._id);
    const data = {
      actionBy: user?._id,
      leadIdOrDealId: id,
      statusId: statusId?._id,
      task: [],
      deadline: parseInt(deadline) || 0,

      assignees:
        statusId?.activities?.length > 0
          ? selectedParticipantId?.length > 0
            ? selectedParticipantId
            : temp
          : [],
    };
    const res = await ApiCall(
      "post",
      CrmTaskRelatedEndPoints.changeLeadStatus,
      data
    );
    if (res?.success) {
      toast.success("Change status successfully!");
      handleClose();
      handleGetDealDetails();
    } else {
      seterrorMessage(res.error);
      setChangeStatusLoader(false);
    }
  };

  const handleGetMandatoryTask = async (id, statusID, activity, statusName) => {
    startApiCall(null, setmandatoryLoader);
    const data = {
      activityId: id,
      activityStatusId: statusID,
    };
    const res = await ApiCall(
      "post",
      CrmTaskRelatedEndPoints.checkMandatoryTask,
      data
    );
    if (res?.success) {
      const taskToBeDone = findMandatoryItems(res?.result);
      if (taskToBeDone?.some((task) => Object.values(task)[0])) {
        seteditTask({
          task: taskToBeDone,
          statusID,
          activityId: id,
          activity: activity,
          statusName,
        });
        const closeModalDom = document.getElementById("call_details_modal");
        if (closeModalDom) closeModalDom.click();
        setstatusWarningDetails(activity);
        setactivityDetails(activity);
        settaskWarningModal(true);
      } else {
        handleEditActivity(activity, statusID, activity?.taskType);
      }
      setmandatoryLoader(false);
    } else {
      setmandatoryLoader(false);
    }
  };

  const handleEditActivity = async (task, status, taskType) => {
    startApiCall(seterrorMessage, setmandatoryLoader);
    const sendData = {
      leadTaskId: task?._id,
      taskType: taskType,
      title: task?.title,
      dueDate: task?.dueDate,
      priority: task?.priority,
      relatedTo: task?.relatedTo,
      activityStatus: status,
      actionOn: task?.actionOn?.id,
      actionBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      CrmTaskRelatedEndPoints.editTask,
      sendData
    );
    if (res?.success) {
      setmandatoryLoader(false);
      handleGetAllTask();
      toast.success("Status changed successfully!");
    } else {
      setmandatoryLoader(false);
      seterrorMessage(res.error);
    }
  };

  function findMandatoryItems(data) {
    let tasks = [];

    data?.map((item) => {
      const key = Object.keys(item)[0];
      const mandatory = item[key]?.mandatory;

      if (mandatory) {
        tasks.push({
          [key]: item[key]?.mandatory,
          description: item[key]?.description,
        });
      }
    });

    return tasks;
  }

  const handleGetAllMailTemplates = async () => {
    startApiCall(null, setMailTemplateLoader);
    const data = {
      page: mailTemplatePage,
      limit: mailTemplatePageLimit,
      filters: { isDeleted: false },
    };
    const res = await ApiCall(
      "post",
      DynamicEmailRelatedEndPoints?.getDynamicEmailTemplate,
      data
    );
    if (res?.success) {
      setAllMailTemplates(res?.result?.docs);
      setMailTemplateCount(res?.result?.totalDocs);
      setMailTemplateTotalPages(res?.result?.totalPages);
      setMailTemplateLoader(false);
    } else {
      setMailTemplateLoader(false);
    }
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <DealOverviewHeader
            componentRef={componentRef}
            setEmailDetailsModal={setEmailDetailsModal}
            handleGetDealDetails={handleRefresh}
            id={id}
            locationData={locationData}
            dealDetails={dealDetails}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar py-2 px-3">
              <div className="contact-list-view">
                <div className="d-flex gap-2">
                  <DealDetailsLeft
                    subStatus={subStatus}
                    dealDetails={dealDetails}
                    statusLoader={statusLoader}
                    id={id}
                    handleGetDealDetails={handleGetDealDetails}
                    loader={loader}
                    taskLoader={taskLoader}
                    allActivity={allActivity}
                    setEditDealData={setEditDealData}
                    setmoreInfoModal={setmoreInfoModal}
                    settimelineDetails={settimelineDetails}
                    seteditGroup={seteditGroup}
                    setgroupAssigneeModal={setgroupAssigneeModal}
                    setactivityDetails={setactivityDetails}
                    seteditDealParticipant={seteditDealParticipant}
                    setassigneeModal={setassigneeModal}
                    setIsOpen={setIsOpen}
                    setStatusId={setStatusId}
                    setupdatedForModal={setupdatedForModal}
                    handleGetMandatoryTask={handleGetMandatoryTask}
                    totalCounts={totalCounts}
                  />
                </div>
              </div>
              {emailDetailsModal && (
                <DealEmailModal
                  setEmailDetailsModal={setEmailDetailsModal}
                  dealDetails={dealDetails}
                  id={id}
                  locationData={locationData}
                  handleGetDealDetails={handleGetDealDetails}
                  selectedMailTemplate={selectedMailTemplate}
                  setSelectedMailTemplate={setSelectedMailTemplate}
                />
              )}
            </div>
          </div>
        </div>
        <AddMeetingCrm
          handleGetMeeting={handleGetAllTask}
          isLeadDeal={true}
          leadDetails={dealDetails}
          id={id}
        />
        <CreateCalls
          handleGetAllTask={handleGetAllTask}
          leadDetails={dealDetails}
          handleRefresh={handleRefresh}
          isLeadDeal={true}
        />
        <AddTaskCrm
          handleGetTask={handleGetAllTask}
          seteditTask={seteditTask}
          editTask={editTask}
          isLeadDeal={true}
          leadDetails={dealDetails}
          id={id}
        />
        <CreateDeal
          editDealData={editDealData}
          setEditDealData={setEditDealData}
          handleGetDealDetails={handleGetDealDetails}
        />

        <CallDetailsModal
          leadDetails={dealDetails}
          loader={loader}
          activityDetails={activityDetails}
          setactivityDetails={setactivityDetails}
          seteditTask={seteditTask}
          settaskWarningModal={settaskWarningModal}
          setstatusWarningDetails={setstatusWarningDetails}
          handleAllTask={handleGetAllTask}
          handleGetMandatoryTask={handleGetMandatoryTask}
          setimageData={setimageData}
          setimageModal={setimageModal}
          relatedTo="Deal"
        />
        {taskWarningModal && (
          <TaskActionWarningModal
            settaskWarningModal={settaskWarningModal}
            editTask={editTask}
            statusWarningDetails={statusWarningDetails}
            handleGetAllTask={handleGetAllTask}
            handleEditActivity={handleEditActivity}
            setimageData={setimageData}
            setimageModal={setimageModal}
          />
        )}
        {moreInfoModal && (
          <TimelineMoreInfoModal
            setmoreInfoModal={setmoreInfoModal}
            timelineDetails={timelineDetails}
            setimageData={setimageData}
            setimageModal={setimageModal}
          />
        )}
        {updatedForModal && (
          <UpdatedForModal
            setupdatedForModal={setupdatedForModal}
            timelineDetails={timelineDetails}
          />
        )}
        <AssigneGroupModal
          selectedGroups={selectedGroups}
          setselectedGroups={setselectedGroups}
          groupAssigneeModal={groupAssigneeModal}
          setgroupAssigneeModal={setgroupAssigneeModal}
          selectedGroupId={selectedGroupId}
          setselectedGroupId={setselectedGroupId}
          leadDetails={dealDetails}
          editGroup={editGroup}
          handleGetLeadDetails={handleGetDealDetails}
        />
        {assigneeModal && (
          <AssigneeEmployeeInLead
            setassigneeModal={setassigneeModal}
            assigneeModal={assigneeModal}
            selectedEmployees={selectedEmployees}
            setselectedEmployees={setselectedEmployees}
            selectedEmpId={selectedEmpId}
            setselectedEmpId={setselectedEmpId}
            handleAddParticipant={handleAddParticipantToDeal}
            editData={editDealParticipant}
            loader3={loader3}
            leadDetails={dealDetails}
          />
        )}
        {isOpen && (
          <LeadStatusChangeModal
            onClose={handleClose}
            text={
              <span>
                Are you sure you want change status to{" "}
                <span className="fw-bold text-capitalize">
                  {statusId?.name}
                </span>{" "}
                ?{" "}
              </span>
            }
            loader={changeStatusloader}
            errorMessage={errorMessage}
            handleConfirm={
              closingDate === "" ? handleChangeStatus : handleEditDeal
            }
            statusId={statusId}
            leadDetails={dealDetails}
            selectedEmpId={selectedParticipantId}
            setselectedEmpId={setselectedParticipantId}
            setDeadline={setDeadline}
            deadline={deadline}
            closingDate={closingDate}
            setclosingDate={setclosingDate}
            expectedRevenue={expectedRevenue}
            setexpectedRevenue={setexpectedRevenue}
            type="deal"
            setallEmployees={setallEmployees}
            allEmployees={allEmployees}
          />
        )}
        {imageModal && (
          <ImageViewModal
            imageData={imageData}
            setimageData={setimageData}
            setimageModal={setimageModal}
          />
        )}
        <div style={{ display: "none" }}>
          <PrintDeals
            ref={componentRef}
            dealDetails={dealDetails}
            locationData={locationData}
            allActivity={allActivity}
          />
        </div>

        <ChooseOption
          dealDetails={dealDetails}
          setEmailDetailsModal={setEmailDetailsModal}
          setIsMailTemplatesListModal={setIsMailTemplatesListModal}
          locationData={locationData}
        />

        {isMailTemplatesListModal && (
          <MailTemplatesListModal
            setIsMailTemplatesListModal={setIsMailTemplatesListModal}
            allMailTemplates={allMailTemplates}
            mailTemplateLoader={mailTemplateLoader}
            setSelectedMailTemplate={setSelectedMailTemplate}
            setEmailDetailsModal={setEmailDetailsModal}
            mailTemplatePage={mailTemplatePage}
            setMailTemplatePage={setMailTemplatePage}
            mailTemplatePageLimit={mailTemplatePageLimit}
            setMailTemplatePageLimit={setMailTemplatePageLimit}
            mailTemplateTotalPages={mailTemplateTotalPages}
            mailTemplateCount={mailTemplateCount}
            handleGetAllMailTemplates={handleGetAllMailTemplates}
          />
        )}
      </div>
    </div>
  );
};

export default DealOverview;
