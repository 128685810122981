import React, { useState } from "react";
import TaskListTable from "../../Task/Views/TaskListTable";
import {
  getAllWorkspaceStatusBySpace,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import ApiCall from "../../../api/ApiCall";
import { taskEndpoint } from "../../../api/Endpoints";
import FooterPagination from "../../../components/Pagination/FooterPagination";

function TaskAnalysisTableView({
  loader2,
  loader,
  taskData,
  selectedTab,
  setSelectedTab,
  settaskData,
  page,
  pageLimit,
  handleGetTask,
  setpageLimit,
  count,
  setPage,
  totalPages,
  settaskDetailsModal,
  setselectedTask,
  setisAssigneeEmployeeModal,
  listOrSpaceId,
  setListOrSpaceId,
  settaskId,
  seteditDuedDate,
  handleGetAllTaskList,
}) {
  const user = useSelector((state) => state.user);
  const [subTask, setsubTask] = useState([]);
  const [loader3, setloader3] = useState(false);
  const [taskStatus, settaskStatus] = useState([]);
  const [taskStatusLoader, settaskStatusLoader] = useState(false);
  const [userInfoData, setuserInfoData] = useState([]);

  const [listName, setListName] = useState("");
  const [spaceName, setSpaceName] = useState("");

  const handleGetAllTaskStatus = async (spaceId) => {
    startApiCall(null, settaskStatusLoader);
    const res = await getAllWorkspaceStatusBySpace(spaceId);
    settaskStatus([...res]);
    settaskStatusLoader(false);
  };

  const handleSubTask = async (taskId) => {
    setloader3((prevLoading) => ({
      ...prevLoading,
      [taskId]: true,
    }));
    setTimeout(() => {
      setloader3((prevLoading) => ({
        ...prevLoading,
        [taskId]: false,
      }));
    }, 50000);
    const sendData = {
      page: 1,
      limit: 500,
      employeeId: user?._id,
      filters: {
        businessId: getCurrentWorkspaceId(),
        maintaskId: taskId,
        taskType: "subtask",
        assignedTo: user?._id,
      },
    };
    const res = await ApiCall("post", taskEndpoint.getTask, sendData);
    if (res?.success) {
      setsubTask((prevSubtaskData) => ({
        ...prevSubtaskData,
        [taskId]: res?.task?.data,
      }));
      setloader3((prevLoading) => ({
        ...prevLoading,
        [taskId]: false,
      }));
    } else {
      setloader3((prevLoading) => ({
        ...prevLoading,
        [taskId]: false,
      }));
    }
  };

  return (
    <>
      <div className="mt-1">
        <ul className="nav nav-tabs border-bottom-transparent mb-0">
          <li className="nav-item pointer">
            <span
              className={`nav-link text-secondary py-0 d-flex align-items-center gap-1 ${
                selectedTab === "All" ? "task-tab-active" : ""
              }`}
              onClick={() => {
                setPage(1);
                setSelectedTab("All");
              }}
            >
              All
            </span>
          </li>
          <li className="nav-item pointer">
            <span
              className={`nav-link text-secondary py-0 d-flex align-items-center gap-1 ${
                selectedTab === "active" ? "task-tab-active" : ""
              }`}
           
              onClick={() => {
                if(selectedTab!=="active"){
                  setPage(1);
                  settaskData([]);
                  setSelectedTab("active");
                }
            
              }}
            >
              Active
            </span>
          </li>
          <li className="nav-item pointer">
            <span
              className={`nav-link text-secondary py-0 d-flex align-items-center gap-1 ${
                selectedTab === "done" ? "task-tab-active" : ""
              }`}
              onClick={() => {
                if(selectedTab!=="done"){
                setPage(1);
                settaskData([]);
                setSelectedTab("done");
                }
              }}
            >
              Done
            </span>
          </li>
          <li className="nav-item pointer">
            <span
              className={`nav-link text-secondary py-0 d-flex align-items-center gap-1 ${
                selectedTab === "completed" ? "task-tab-active" : ""
              }`}
              onClick={() => {
                if(selectedTab!=="completed"){
                setPage(1);
                settaskData([]);
                setSelectedTab("completed");
                }
              }}
            >
              Completed
            </span>
          </li>
        </ul>

        {!loader && !loader2 && taskData?.length > 0 && (
          <>
            <TaskListTable
              taskData={taskData}
              handleGetTask={handleGetTask}
              subTask={subTask}
              handleSubTask={handleSubTask}
              loader3={loader3}
              setloader3={setloader3}
              setselectedTask={setselectedTask}
              settaskDetailsModal={settaskDetailsModal}
              handleGetAllTaskStatus={handleGetAllTaskStatus}
              taskStatus={taskStatus}
              taskStatusLoader={taskStatusLoader}
              setisAssigneeEmployeeModal={setisAssigneeEmployeeModal}
              page={page}
              pageLimit={pageLimit}
              setuserInfoData={setuserInfoData}
              seteditDuedDate={seteditDuedDate}
              settaskId={settaskId}
              isAssigned={true}
              setListOrSpaceId={setListOrSpaceId}
              listOrSpaceId={listOrSpaceId}
              setListName={setListName}
              setSpaceName={setSpaceName}
              handleGetAllTaskList={handleGetAllTaskList}
            />
            <FooterPagination
              setpageLimit={setpageLimit}
              pageLimit={pageLimit}
              setPage={setPage}
              page={page}
              totalPages={totalPages}
              count={count}
              dataLength={taskData?.length}
              align={"start"}
            />
          </>
        )}
      </div>

      <div
        className="position-fixed bottom-0 end-0 p-2"
        style={{
          position: "fixed",
          zIndex: "9999",
        }}
      >
        <button
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#add_task_mytask"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-plus-circle"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
          </svg>{" "}
          task
        </button>
      </div>
    </>
  );
}

export default TaskAnalysisTableView;
