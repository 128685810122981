import Navbar from "../../components/Navbar/Navbar";
import CrmEmails from "../../pages/CRM/CrmEmails/CrmEmails";

const CrmEmailsRoutes = {
  path: "/crm",
  element: <Navbar />,
  children: [
    {
      path: "allEmails",
      element: <CrmEmails />,
    },
  ],
};

export default CrmEmailsRoutes;
