import React from "react";
import { toast } from "react-toastify";

const ChooseOption = ({
  leadDetails,
  setEmailDetailsModal,
  setIsMailTemplatesListModal,
}) => {
  return (
    <div
      id="choose_option"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-3"
              data-bs-dismiss="modal"
              aria-label="Close"
              //   onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">Select a template for your email.</h5>
            <div className="d-flex align-items-center gap-3 justify-content-center py-5">
              <span
                className="btn btn-primary"
                onClick={() => setIsMailTemplatesListModal(true)}
                data-bs-dismiss="modal"
              >
                Choose template
              </span>
              <p className="mb-0 text-secondary">or</p>
              <span
                className="btn btn-light"
                onClick={() => {
                  if (leadDetails?.email) {
                    setEmailDetailsModal(true);
                  } else {
                    toast.warning(
                      "Email does not exist! Please add an email first by editing the existing lead."
                    );
                  }
                }}
                data-bs-dismiss="modal"
              >
                Custom
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseOption;
