import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { DynamicEmailRelatedEndPoints } from "../../../../api/Endpoints";
import { toast } from "react-toastify";
import ErrorMessage from "../../../../components/Error/ErrorMessage";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import { useSelector } from "react-redux";
import moment from "moment";
import ReactQuill from "react-quill";

const AddMailTemplate = ({
  handleGetAllMailTemplates,
  editMailTemplate,
  addMailTemplateModal,
  setAddMailTemplateModal,
  setEditMailTemplate,
}) => {
  const user = useSelector((state) => state.user);

  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [displayName, setDisplayName] = useState("");
  const [mailTitle, setMailTitle] = useState("");
  const [mailCategory, setMailCategory] = useState("");
  const [mailSubject, setMailSubject] = useState("");
  const [mailMessage, setMailMessage] = useState("");

  const handleReset = () => {
    setDisplayName("");
    setMailTitle("");
    setMailCategory("");
    setMailSubject("");
    setMailMessage("");
    setLoader(false);
    setErrorMessage("");
    setEditMailTemplate("");
  };

  useEffect(() => {
    if (editMailTemplate) {
      setDisplayName(editMailTemplate?.displayName || "");
      setMailCategory(editMailTemplate?.category || "");
      setMailTitle(editMailTemplate?.title || "");
      setMailSubject(editMailTemplate?.subject || "");
      setMailMessage(editMailTemplate?.message || "");
    }
  }, [editMailTemplate]);

  const handleAddMailTemplate = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setLoader);
    const data = {
      createdBy: user?._id,
      businessId: getCurrentWorkspaceId(),
      displayName,
      category: mailCategory,
      title: mailTitle,
      subject: mailSubject,
      message: mailMessage,
      approvalStatus: "approved",
    };
    const res = await ApiCall(
      "post",
      DynamicEmailRelatedEndPoints.addDynamicEmailTemplate,
      data
    );
    if (res?.success) {
      toast.success("Mail Template Added successfully");
      handleGetAllMailTemplates();
      handleReset();
      setAddMailTemplateModal(false);
    } else {
      setLoader(false);
      setErrorMessage(res.error);
    }
  };

  const handleEditMailTemplate = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setLoader);
    const data = {
      createdBy: user?._id,
      businessId: getCurrentWorkspaceId(),
      dynamicemailId: editMailTemplate?._id,
      displayName,
      category: mailCategory,
      title: mailTitle,
      subject: mailSubject,
      message: mailMessage,
      approvalStatus: "approved",
      actionBy: user?._id,
      actionTime: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    const res = await ApiCall(
      "post",
      DynamicEmailRelatedEndPoints?.editDynamicEmailTemplate,
      data
    );
    if (res?.success) {
      toast.success("Mail Template Edited successfully");
      handleGetAllMailTemplates();
      handleReset();
      setAddMailTemplateModal(false);
    } else {
      setLoader(false);
      setErrorMessage(res.error);
    }
  };

  return (
    <div
      className={`modal ${addMailTemplateModal ? "show d-block" : "fade"}`}
      tabIndex="-1"
      role="dialog"
      aria-hidden={!addMailTemplateModal}
    >
      <div
        className="modal-dialog modal-dialog-centered mw-700p"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header border-0 pb-0">
            <h5 className="modal-title">
              {editMailTemplate ? "Edit" : "Add"} Mail Template
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                handleReset();
                setAddMailTemplateModal(false);
              }}
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <form
              className="d-flex flex-column gap-3"
              onSubmit={(e) => {
                if (!editMailTemplate) {
                  handleAddMailTemplate(e);
                } else {
                  handleEditMailTemplate(e);
                }
              }}
            >
              <div className="row gy-1 gx-4 overflow-auto mh-400p simple-scrollbar">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Display Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={displayName}
                      onChange={(e) => setDisplayName(e.target.value)}
                      required
                      placeholder="Minimum 6 characters"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Category</label>
                    <input
                      type="text"
                      className="form-control"
                      value={mailCategory}
                      onChange={(e) => setMailCategory(e.target.value)}
                      required
                      placeholder="Type category"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Template Title</label>
                    <input
                      type="text"
                      className="form-control"
                      value={mailTitle}
                      onChange={(e) => setMailTitle(e.target.value)}
                      required
                      placeholder="Minimum 6 characters"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Email Subject</label>
                    <input
                      type="text"
                      className="form-control"
                      value={mailSubject}
                      onChange={(e) => setMailSubject(e.target.value)}
                      required
                      placeholder="Minimum 8 characters"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group mb-0">
                    <label>Email Body</label>
                    <ReactQuill
                      className="h-100"
                      theme="snow"
                      value={mailMessage}
                      onChange={setMailMessage}
                      placeholder="Compose your email body (minimum 20 characters)..."
                      modules={{
                        toolbar: [
                          [{ font: [] }],
                          [{ size: ["small", false, "large", "huge"] }],
                          ["bold", "italic", "underline"],
                          [{ color: [] }, { background: [] }],
                          [{ align: [] }],
                          [{ list: "ordered" }, { list: "bullet" }],
                          [{ indent: "-1" }, { indent: "+1" }],
                        ],
                      }}
                      formats={[
                        "font",
                        "size",
                        "bold",
                        "italic",
                        "underline",
                        "color",
                        "background",
                        "align",
                        "list",
                        "bullet",
                        "indent",
                      ]}
                    />
                  </div>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="d-flex align-items-center gap-3 justify-content-end w-100 ">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    handleReset();
                    setAddMailTemplateModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={
                    !displayName ||
                    displayName?.length <= 5 ||
                    !mailTitle ||
                    mailTitle?.length <= 5 ||
                    !mailMessage ||
                    mailMessage.length <= 19 ||
                    !mailSubject ||
                    mailSubject.length <= 7
                  }
                >
                  {loader ? (
                    <ApiLoader />
                  ) : editMailTemplate ? (
                    "Edit"
                  ) : (
                    "Create"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMailTemplate;
