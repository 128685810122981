const authPrefix = "auth";
const employeePrefix = "employee";
const businessPrefix = "business";
const spacePrefix = "space";
const managementPrefix = "management";
const taskPrefix = "task";
const listPrefix = "list";
const folderPrefix = "folder";
const graphPrefix = "graph";
const complaintPrefix = "complaint";
const lineupPrefix = "lineup";
const versionPrefix = "version";
const staffPrefix = "staff";
const attendancePrefix = "attendance";
const salaryPrefix = "salary";
const leavePrefix = "leave";
const leavetypePrefix = "leaveType";
const transactionPrefix = "transaction";
const noticePrefix = "notice";
const meetingPrefix = "meeting";
const pollPrefix = "poll";
const salaryInsightPrefix = "salary-insight";
const holidayPrefix = "holiday";
const ratingPrefix = "rating";
const itemPrefix = "item";
const customerPrefix = "customer";
const invoicePrefix = "invoice";
const quotesPrefix = "quote";
const accountPrefix = "account";
const productCategoryPrefix = "product-category";
const productPrefix = "product";
const inventoryPrefix = "inventory";
const stockPrefix = "stock";
const buyerPrefix = "buyer";
const orderPrefix = "order";
const leadPrefix = "lead";
const statusPrefix = "status";
const crmContact = "contact";
const leadtaskPrefix = "leadtask";
const vendorPrefix = "vendor";
const billPrefix = "bill";
const expensePrefix = "expense";
const tablePrefix = "table";
const participantGroupPrefix = "participant-group";
const activityGroupPrefix = "activity-group";
const leadgroupPrefix = "leadgroup";
const dynamicFieldPrefix = "dynamic-custom-field";
const analyticsPrefix = "analytics";
const dynamicEmailPrefix = "dynamicemail";

// ** AUTH RELATED ENDPOINTS **
export const loginEndpoints = {
  userLogin: authPrefix + "/user-login",
  deleteAccount: employeePrefix + "/delete-account-request",
};

export const signupEndpoints = {
  sendOtpForRegistration: authPrefix + "/send-otp-register", //first step
  validateOtpForRegistration: authPrefix + "/validate-otp-register", //second step
  addDetailsForRegistration: employeePrefix + "/add-details-register", //third step
};

export const forgotPasswordEndpoints = {
  sendOtpForResetPassword: authPrefix + "/send-otp-forgot-password", //first step
  validateOtpForResetPassword: authPrefix + "/validate-otp-forgot-password", //second step
  resetPassword: authPrefix + "/reset-password", //third step
};

export const logoutEndpoints = {
  userLogout: authPrefix + "/user-logout",
};

export const authenticateEndpoints = {
  verifyUser: employeePrefix + "/get-user",
  sendForApproval: employeePrefix + "/resend-business-for-approval",
  allBusinessRequest: employeePrefix + "/get-employee-pending-invite",
};

// ** HOME RELATED ENDPOINTS **
export const homeEndpoints = {
  getDashboardCounts: managementPrefix + "/get-dashboard-counts",
};

// ** BUSINESS RELATED ENDPOINTS **
export const businessEndpoints = {
  addBusiness: businessPrefix + "/add-business",
  getBusiness: businessPrefix + "/get-all-business",
  workspaceCount: managementPrefix + "/data-count",
  inviteBusiness: businessPrefix + "/invite-to-business",
  joinBusiness: businessPrefix + "/join-business",
  editManagePeople: businessPrefix + "/edit-manage-employee",
  searchBusiness: businessPrefix + "/get-business-filters",
  getBusinessById: businessPrefix + "/get-business-by-id",
  editBusiness: businessPrefix + "/edit-business",
};

// ** SPACES RELATED ENDPOINTS **

export const spaceEndpoint = {
  getSpacebyId: spacePrefix + "/get-space-by-id",
  addRemoveEmployeesInSpcae: spacePrefix + "/add-remove-employee-from-space",
  getFolderAndList: spacePrefix + "/get-folder-&-list-by-spaceId",
  getSpace: spacePrefix + "/get-spaces",
  createSpace: spacePrefix + "/create-space",
  privateAccessEmployeeDetails: spacePrefix + "/private-access-employees",
};

// ** TASK RELATED ENDPOINTS **

export const taskEndpoint = {
  createTask: taskPrefix + "/create-task",
  getTask: taskPrefix + "/get-all-tasks",
  editTask: taskPrefix + "/editOrAssignedTask",
  taskDetails: taskPrefix + "/getTaskById",
  addComment: taskPrefix + "/comment",
  getComments: taskPrefix + "/comments",
  getStatus: taskPrefix + "/get-task-status",
  assignTask: taskPrefix + "/assign-employee",
  getUniversalTasks: taskPrefix + "/get-universal-tasks-for-employee",
  deleteTask: taskPrefix + "/delete-task",
  allTaskStatusWise: taskPrefix + "/status-wise-list-tasks",
  createStatus: taskPrefix + "/create-task-status",
  editStatus: taskPrefix + "/edit-task-status",
  deleteStatus: taskPrefix + "/delete-task-status",
  removeDuedate: taskPrefix + "/remove-due-date",
  taskCounts: taskPrefix + "/tasks-counts",
  taskWeeklyMonthlyPercentage: managementPrefix + "/get-week-month-task-graph",
  taskWeeklyLineGraph: managementPrefix + "/week-wise-tasks",
  taskMonthlyLineGraph: taskPrefix + "/month-wise-tasks-counts",
  employeeTaskOverview: analyticsPrefix + "/get-employees-task-analytics",
};

// ** LIST RELATED ENDPOINTS **

export const listEndpoint = {
  createList: listPrefix + "/create-list",
  addRemoveEmployee: listPrefix + "/add-remove-employee-from-list",
  getAllList: listPrefix + "/get-lists",
};
// ** FOLDER RELATED ENDPOINTS **

export const folderEndpoint = {
  getFolderbyId: folderPrefix + "/get-folder-by-id",
  getTask: folderPrefix + "/",
  createFolder: folderPrefix + "/create-folder-in-space",
  editFolder: folderPrefix + "/edit-folder",
  addEmployee: folderPrefix + "/add-remove-employee-from-folder",
};

// ** MANAGEMENT RELATED ENDPOINTS **

export const managementEndpoints = {
  approveOrRejectBusiness:
    managementPrefix + "/approve-or-reject-business-request",
  getAllEmployees: managementPrefix + "/get-all-employees",
  getAllManagePeople: managementPrefix + "/get-manage-people",
  acceptOrRejectBusinessRequest:
    managementPrefix + "/accept-or-reject-join-business-request",
  changeRole: managementPrefix + "/change-employee-role",
  removeEmployee: managementPrefix + "/remove-employee",
};

// ** WORKSPACE ROLE RELATED ENDPOINTS **

export const workspaceRoleEndPoints = {
  createRole: managementPrefix + "/create-role",
  getAllRole: managementPrefix + "/get-all-role",
  roleDetails: managementPrefix + "/get-role-by-id",
  editRolePermissions: managementPrefix + "/edit-module-role-permissions",
  deleteRole: managementPrefix + "/delete-role",
};

// ** INVITATION OF WORKSPACE Endpoints **

export const invitationWorkspaceEndPoints = {
  verifyInvite: businessPrefix + "/verify-invite",
  approveorRejectWorkspaceRequest:
    employeePrefix + "/employee-approve-reject-business-invite",
  approveorRejectWorkspaceRequestFromInApp:
    employeePrefix + "/employee-approve-reject-business-invite-from-app",
};

// ** GRAPH Endpoints **

export const graphEndPoints = {
  taskStatusGraph: graphPrefix + "/task-status-range-graph-data",
  employeeTaskStatusGraph: graphPrefix + "/employee-task-status-graph-data",
  listTaskStatusGraph: graphPrefix + "/listwise-task-status-graph",
  employeeDailyTaskGraph: graphPrefix + "/employee-daily-task-status-graph",
  employeeTotalTasks: graphPrefix + "/total-counts-of-tasks",
};

export const employeeEndPoints = {
  getEmployeeById: employeePrefix + "/get-employee-by-employee-and-business-id",
};

// ** LOGS Endpoints **
export const logsEndPoints = {
  getLogs: managementPrefix + "/get-logs",
};

export const editProfileEndPoints = {
  editProfile: managementPrefix + "/employee-profile",
  addPhoneNo: employeePrefix + "/add-email-or-phone-after-login",
  validatePhoneOrEmail: employeePrefix + "/verify-email-or-phone-after-login",
};

// ** COMPLAINT ENDPOINTS **
export const complaintEndPoints = {
  addComplaint: complaintPrefix + "/add-complaint",
  getComplaints: complaintPrefix + "/get-complaints",
  changeComplaintStatus: complaintPrefix + "/update-status",
  editComplaint: complaintPrefix + "/edit-complaint",
  editAnonymous: complaintPrefix + "/edit-configuration",
  getAnonymous: complaintPrefix + "/get-configuration",
  complaintCounts: complaintPrefix + "/complaint-counts",
  monthWiseComplaintCounts: complaintPrefix + "/month-wise-complaint-counts",
};

// ** LINEUP TASK ENDPOINTS **
export const linupTaskEndpoints = {
  addTaskInLineUp: lineupPrefix + "/add-or-remove-task",
  getTaskInLineUp: lineupPrefix + "/lineup",
};

// ** VERSION RELATED ENDPOINTS **
export const versionRelatedEndPoints = {
  getDashboardVersion: versionPrefix + "/get-version-dashboard",
};

// ** ATTENDANCE RELATED ENDPOINTS **
export const attendanceRelatedEndPoints = {
  addStaff: staffPrefix + "/add-staff",
  getAttendance: attendancePrefix + "/get-attendance",
  getAttendancePerDay: attendancePrefix + "/get-attendance-per-day",
  attendanceCounts: attendancePrefix + "/attendance-counts",
  attendanceDetails: leavePrefix + "/get-attendance-and-leave",
  verifyAttendance: attendancePrefix + "/verify-attendance",
  attendanceOverview: salaryPrefix + "/get-overview",
  editAttendance: attendancePrefix + "/edit-attendance",
  markAbsent: attendancePrefix + "/mark-absentee",
  markAsPresent: attendancePrefix + "/mark-as-present",
  staffOnboarding: managementPrefix + "/get-staff-employee-data",
  editStaff: staffPrefix + "/edit-staff",
  removeStaff: staffPrefix + "/remove-staff",
};

// ** SALARY RELATED ENDPOINTS **
export const salaryRelatedEndPoints = {
  getSalaryDetails: salaryPrefix + "/get-salary-by-id",
  getAllStaffSalary: salaryPrefix + "/get-salaries",
  getTotalSalaryCount: salaryPrefix + "/salary-paid-unpaid-count",
  editSalaryData: salaryPrefix,
  getSalaryInsight: salaryInsightPrefix + "/get-salary-insight",
  editSalaryInsight: salaryInsightPrefix,
  salaryTotalCount: salaryPrefix + "/get-total-salaries-count",
  editSalary: salaryPrefix + "/override-salary",
};

// ** TRANSACTION  RELATED ENDPOINTS **
export const transactionRelatedEndPoints = {
  settleSalary: transactionPrefix + "/create-transaction",
};

// ** LEAVE RELATED ENDPOINTS **
export const leaveRelatedEndPoints = {
  getLeaveRequest: leavePrefix + "/get-leave-requests",
  approveOrRejectLeaveRequest: leavePrefix + "/approve-reject-leave",
  createLeaveTypes: leavetypePrefix + "/create-leave-type",
  addAnotherLeave: leavetypePrefix + "/add-new-leave-type",
  getLeaveTypes: leavetypePrefix + "/get-leave-type",
  getLeaveHistory: leavePrefix + "/get-all-staff-leave",
  getLeavesForStaff: leavePrefix + "/leave-for-staff",
  editLeaveTypes: leavetypePrefix + "/update-leave-type",
  deleteLeaveType: leavetypePrefix + "/delete-leave-type",
  applyLeave: leavePrefix + "/leave-data",
  getExcludedDatesForStaff: leavePrefix + "/get-excluded-dates",
  getTotalLeaveCountTypeWise: leavePrefix + "/get-leavetypewise-leave-count",
};

// ** NOTICE RELATED ENDPOINTS **
export const noticeRelatedEndPoints = {
  addNotice: noticePrefix + "/add-notice",
  editNotice: noticePrefix + "/edit-notice",
  getAllNotices: noticePrefix + "/get-notices",
  getNoticeById: noticePrefix + "/get-notice-by-id",
  deleteNotice: noticePrefix + "/delete-notice",
  changeNoticeStatus: noticePrefix + "/change-publish-status",
  getNoticeCount: noticePrefix + "/notice-status-count",
};

// ** MEETING RELATED ENDPOINTS **
export const MeetingRelatedEndPoints = {
  getMeeting: meetingPrefix + "/get-meetings",
  addMeeting: meetingPrefix + "/add-meeting",
  changeMeetingStatus: meetingPrefix + "/change-status",
  deleteMeeting: "/" + meetingPrefix,
  getParticipants: meetingPrefix + "/get-participants",
  editMeeting: meetingPrefix + "/meeting",
};

// ** POLL RELATED ENDPOINTS **
export const PollRelatedEndPoints = {
  getPoll: pollPrefix + "/get-polls",
  addPoll: pollPrefix + "/create-poll",
  addVote: pollPrefix + "/add-vote",
  editPoll: pollPrefix + "/edit-poll",
};

// ** SHIFT RELATED ENDPOINTS **
export const ShiftRelatedEndPoints = {
  addShiftTiming: staffPrefix + "/add-shift-timing",
  editShiftTiming: staffPrefix + "/edit-shift-timing",
  getStaffShift: staffPrefix + "/get-shifts",
  getShiftsById: staffPrefix + "/get-shifts-by-id",
  addShiftToStaff: staffPrefix + "/add-shift-to-staff",
  getStaffById: staffPrefix + "/get-staff-by-id",
  shiftsofMonth: staffPrefix + "/get-shifts-for-month",
  updateToDefalut: staffPrefix + "update-default-shift",
  getStaff: staffPrefix + "/get-staffs",
};

// ** HOLIDAY RELATED ENDPOINTS **
export const HolidayRelatedEndPoints = {
  createHolidays: holidayPrefix + "/create-holidays",
  getAllHolidays: holidayPrefix + "/get-holidays",
};

// ** HOLIDAY RELATED ENDPOINTS **
export const RatingRelatedEndPoints = {
  addRating: ratingPrefix,
  getAverageRating: ratingPrefix + "/get-average",
};
// ** ITEM RELATED ENDPOINTS **
export const ItemRelatedEndPoints = {
  addItem: itemPrefix + "/add-item",
  getItem: itemPrefix + "/get-item",
  editItem: itemPrefix + "/edit-item",
  deleteItem: itemPrefix + "/delete-item",
  addItemUnit: itemPrefix + "/add-item-unit",
  getItemUnit: itemPrefix + "/get-item-unit",
};

// ** CUSTOMER RELATED ENDPOINTS **
export const CustomerRelatedEndPoints = {
  addCustomer: customerPrefix + "/add-customer",
  getCustomer: customerPrefix + "/get-customer",
  editCustomer: customerPrefix + "/edit-customer",
  deleteCustomer: customerPrefix + "/delete-customer",
};

// ** INVOICES RELATED ENDPOINTS **
export const InvoicesRelatedEndPoints = {
  addInvoice: invoicePrefix + "/create-invoice",
  getInvoice: invoicePrefix + "/get-invoices",
  editInvoice: invoicePrefix + "/edit-invoice",
  deleteInvoice: invoicePrefix + "/mark-as-void",
  getInvoiceById: invoicePrefix + "/get-invoice-by-id",
  getTotalAmount: invoicePrefix + "/get-Amount-counts",
  addTransaction: invoicePrefix + "/add-inv-transaction",
  getTransactions: invoicePrefix + "/get-inv-transaction",
};

// ** QUOTES RELATED ENDPOINTS **
export const QuotesRelatedEndPoints = {
  addQuote: quotesPrefix,
  getQuote: quotesPrefix + "/get-quotes",
  editQuote: quotesPrefix,
  deleteQuote: "/quote",
  getQuoteById: quotesPrefix + "/get-quote-by-id",
};

// ** CREDIT NOTE RELATED ENDPOINTS **
export const CreditNoteRelatedEndPoints = {
  getCreditNotes: invoicePrefix + "/get-credit-note",
  getCreditNoteById: invoicePrefix + "/get-credit-note-by-id",
  editCreditNotes: invoicePrefix + "/edit-credit-note",
  addCredit: invoicePrefix + "/create-credit-note",
  deleteCreditNote: invoicePrefix + "/delete-credit-note",
  changeCreditNoteStatus: invoicePrefix + "/change-status-credit-note",
};

// ** CONFIGURATION RELATED ENDPOINTS **
export const ConfigurationRelatedEndPoints = {
  getConfifguration: complaintPrefix + "/get-configuration",
};
// ** LOCATION RELATED ENDPOINTS **
export const LocationRelatedEndPoints = {
  getLocation: businessPrefix + "/locations",
  editLocation: businessPrefix + "/edit-location",
  deleteLocation: businessPrefix + "/add-remove-location",
};

// ** ACCOUNT RELATED ENDPOINTS **
export const AccountRelatedEndPoints = {
  addSubAccountType: accountPrefix + "/add-account-type",
  getSubAccountType: accountPrefix + "/get-account-type",
  addAccount: accountPrefix + "/add-account",
  getAccount: accountPrefix + "/get-account",
  editAccount: accountPrefix + "/edit-account",
  deleteAccount: accountPrefix + "/delete-account",
};

// ** PRODUCT CATEGORY RELATED ENDPOINTS **
export const ProductCategoryRelatedEndPoints = {
  addProductCategory: productCategoryPrefix + "/add-product-category",
  ediProductCategory: productCategoryPrefix + "/edit-product-category",
  getProductCategory: productCategoryPrefix + "/get-product-category",
  getCategoryItems: productPrefix + "/get-product",
  addMenuItem: productPrefix + "/add-product",
  deleteMenuItem: productPrefix + "/delete-product",
  editMenuItem: productPrefix + "/edit-product",
};

// ** INVENTORY RELATED ENDPOINTS **
export const InventoryRelatedEndPoints = {
  addInventory: inventoryPrefix + "/add-inventory",
  getInventory: inventoryPrefix + "/get-inventory",
  addInventoryUnit: inventoryPrefix + "/add-inventory-unit",
  getInventoryUnit: inventoryPrefix + "/get-inventory-unit",
  editInventory: inventoryPrefix + "/edit-inventory",
  inventorySettings: inventoryPrefix + "/get-invoice-setting",
  getAllCharges: inventoryPrefix + "/get-charge",
  getLowStock: inventoryPrefix + "/get-low-stocks",
};

// ** INVENTORY CUSTOMER RELATED ENDPOINTS **

export const InventoryCustomerRelatedEndPoints = {
  addCustomer: buyerPrefix + "/add-buyer",
  getCustomer: buyerPrefix + "/get-buyers",
  deleteCustomer: buyerPrefix + "/delete-buyer",
  editCustomer: buyerPrefix + "/edit-buyer",
};

// ** STOCK RELATED ENDPOINTS **
export const StockRelatedEndPoints = {
  addStock: stockPrefix + "/add-stock",
  getStock: stockPrefix + "/get-stock",
  editStock: stockPrefix + "/edit-stock",
  getStockByInventoryId: stockPrefix + "/get-stock-by-inventory-id",
  getStockCount: stockPrefix + "/get-stock-count",
  removeStock: stockPrefix + "/out-stock",
  getAllStockCount: stockPrefix + "/get-stocks-of-all-inventory",
};
// ** ORDER RELATED ENDPOINTS **
export const OrderRelatedEndPoints = {
  getOrderInvoice: orderPrefix + "/get-inventory-invoice",
  getOrders: orderPrefix + "/get-orders",
  getInventoryLogs: orderPrefix + "/get-inventory-logs",
  createOrder: orderPrefix + "/create-order",
  getTotalSell: stockPrefix + "/get-daily-expenses-details",
  collectPayment: orderPrefix + "/collect-payment",
  cancelOrder: orderPrefix + "/cancel-order",
  editOrder: orderPrefix + "/edit-order",
  returnOrder: orderPrefix + "/return-order",
  updateOrderApproval: orderPrefix + "/update-customer-and-order",
  editPaymentMode: orderPrefix + "/change-payment-mode",
};

// * INVENTORY ANALYTICS ENDPOINTS *
export const InventoryAnalyticsRelatedEndPoints = {
  getSalesOverview: graphPrefix + "/sales-overview",
  getStockOverview: graphPrefix + "/stock-overview",
  getCustomerOverview: graphPrefix + "/customer-overview",
  getTopSellingItem: graphPrefix + "/highest-selling-overview",
  getSalesStatistics: graphPrefix + "/sakes-stats",
};

// * INVENTORY SETTINGS ENDPOINTS *
export const InventorySettingsRelatedEndPoints = {
  addInvoiceSetting: inventoryPrefix + "/add-invoice-setting",
  editInvoiceSetting: inventoryPrefix + "/edit-invoice-setting",
  getInvoiceSetting: inventoryPrefix + "/get-invoice-setting",
  addCharge: inventoryPrefix + "/add-charge",
  editCharge: inventoryPrefix + "/edit-charge",
  getCharge: inventoryPrefix + "/get-charge",
};

// * CRM TASK ENDPOINTS *
export const CrmTaskRelatedEndPoints = {
  addTask: leadtaskPrefix + "/add-lead-task",
  getTask: leadtaskPrefix + "/get-lead-task",
  deleteTask: leadtaskPrefix + "/delete-lead-task",
  editTask: leadtaskPrefix + "/edit-lead-task",
  taskWithInHour: leadtaskPrefix + "/get-task-within-hour",
  changeLeadStatus: leadtaskPrefix + "/add-task-wise-status",
  upcomingEvent: leadtaskPrefix + "/task-for-day",
  taskisExpired: leadtaskPrefix + "/update-isExpired-in-LeadTask",
  snoozeLeadTask: leadtaskPrefix + "/snooze-task",
  uploadAttachment: leadtaskPrefix + "/upload-attachments",
  checkMandatoryTask: leadtaskPrefix + "/check-mandatory-tasks",
  reminderMarkAsClose: leadtaskPrefix + "/mark-close",
};

// * LEAD RELATED ENDPOINTS *
export const LeadRelatedEndPoints = {
  createLead: leadPrefix + "/create-lead",
  getLead: leadPrefix + "/get-leads",
  editLead: leadPrefix + "/edit-lead",
  getLeadDetails: leadPrefix + "/get-lead-by-id",
  convertToDeal: leadPrefix + "/convert-lead-to-deal",
  addPaymentToDeal: leadPrefix + "/add-payment-to-deal",
  sendEmailToLead: leadPrefix + "/send-email",
  getLeadLogs: leadPrefix + "/get-lead-task-log",
  getLeadCount: leadPrefix + "/get-lead-data",
  getDealsCount: leadPrefix + "/open-and-closed-deal",
  getLeadAndContactCount: leadPrefix + "/lead-contact-conversion-create-count",
  addParticipantGroupInLead: leadPrefix + "/assign-lead-to-participants-group",
  addParticipantInLead: leadPrefix + "/assign-participant-to-lead",
  allLeadCount: leadPrefix + "/lead-data-count",
  leadContactConversionCount:
    leadPrefix + "/total-lead-deal-contact-status-count",
  recentLead: graphPrefix + "/recent-leads",
  recentContact: graphPrefix + "/get-contact-created",
  recentDeal: graphPrefix + "/get-recent-deal",
  getUpcomingActivity: graphPrefix + "/get-activities-analytics",
  leadGeneratedCount: graphPrefix + "/lead-generated-and-deal-close-won-count",
  editDeal: leadPrefix + "/edit-deal",
  uploadCsvForLead: leadPrefix + "/csv-upload-lead",
  addLeadSource: leadPrefix + "/create-lead-source",
  editLeadSource: leadPrefix + "/edit-lead-source",
  getLeadSource: leadPrefix + "/get-lead-source",
  getLeadSourceDetails: leadPrefix + "/get-lead-source-by-id",
  removeLeadSource: leadPrefix + "/remove-lead-source",
  generationTarget: leadPrefix + "/add-edit-crm-generation-target",
  bulkCsvUpload: leadPrefix + "/csv-upload-lead",
  exportLead: leadPrefix + "/export-lead",
  allEmployeeCrmCounts: leadPrefix + "/lead-deal-contact-count",
  assignEmployeeLead: leadPrefix + "/assigned-multiple-lead",
  allCounts: leadPrefix + "/total-count",
  crmEmpAnalytics: leadPrefix + "/employee-analytics",
};

// * DEAL RELATED ENDPOINTS *

export const DealRelatedEndPoints = {
  createDeal: leadPrefix + "/create-deal",
  getDeal: leadPrefix + "/get-deals",
  allDealCount: leadPrefix + "/get-deal-data-count",
  getDealDetails: leadPrefix + "/get-deal-by-id",
  editDeal: leadPrefix + "/edit-deal",
};

export const StatusRelatedEndPoints = {
  getGropus: statusPrefix + "/get-group",
  getGropusDashboard: statusPrefix + "/get-group-dashboard",
  getInitialGroups: statusPrefix + "/get-initial-status",
  getStatus: statusPrefix + "/get-status",
  addGroup: statusPrefix + "/add-group",
  editGroup: statusPrefix + "/edit-group",
  getGroupById: statusPrefix + "/get-group-by-id",
  addStatus: statusPrefix + "/add-status",
  editStatus: statusPrefix + "/edit-status",
  getInitialStatus: statusPrefix + "/get-initial-status",
  getStatusById: statusPrefix + "/get-status-by-id",
};

export const CrmContactRelatedEndPoints = {
  addContact: crmContact + "/create-contact",
  getContact: crmContact + "/get-contact",
  deleteContact: crmContact + "/remove-contact",
  editContact: crmContact + "/edit-contact",
  contactById: crmContact + "/get-contact-by-id",
  convertToContact: crmContact + "/convert-lead-to-contact",
};

// ** VENDOR RELATED ENDPOINTS **
export const VendorRelatedEndPoints = {
  addVendor: vendorPrefix + "/add-vendor",
  getVendor: vendorPrefix + "/get-vendor",
  editVendor: vendorPrefix + "/edit-vendor",
  deleteVendor: vendorPrefix + "/delete-vendor",
  getVendorById: vendorPrefix + "/get-vendor-by-id",
  getVendorTransaction: transactionPrefix + "/get-main-transactions",
  getVendorTransactionAmount: transactionPrefix + "/get-amounts-sum",
  advancePayment: transactionPrefix + "/add-advance",
  addInstallment: transactionPrefix + "/add-transaction-installment",
  getVendorReport: vendorPrefix + "/get-vendor-transactions",
};

// ** BILLS RELATED ENDPOINTS **
export const BillRelatedEndPoints = {
  createBill: billPrefix + "/create-bill",
  getBill: billPrefix + "/get-bills",
  editBill: billPrefix + "/edit-bill",
  getBillById: billPrefix + "/get-bill-by-id",
  voidOrDeleteBill: billPrefix + "/mark-as-void",
};

// ** EXPENSE RELATED ENDPOINTS **
export const ExpensRelatedEndPoints = {
  addExpense: expensePrefix + "/add-expense",
  getExpense: expensePrefix + "/get-expenses",
  editExpense: expensePrefix + "/edit-expense",
  deleteExpense: expensePrefix + "/delete-expense",
};

// ** CASH IN OUT RELATED ENDPOINTS **
export const CashInOutRelatedEndPoints = {
  adjustCash: transactionPrefix + "/add-or-withdraw-cash",
  getCashCounterAmount: transactionPrefix + "/get-cash-counter",
  editTransaction: transactionPrefix + "/edit-main-transactions",
  deleteTransaction: transactionPrefix + "/delete-main-transactions",
};

// ** RESTAURANT TABLE RELATED ENDPOINTS **
export const restaurantTableRelatedEndPoints = {
  addTable: tablePrefix + "/add-table",
  getTable: tablePrefix + "/get-table",
  editTable: tablePrefix + "/edit-table",
  deleteTable: tablePrefix + "/delete-table",
};

// * PARTICIPANTS GROUP RELATED ENDPOINTS *
export const CrmParticipantsGroup = {
  AddGroup: participantGroupPrefix + "/create-lead-group",
  getGroup: participantGroupPrefix + "/get-participants-group",
  addParticipantGroup: participantGroupPrefix + "/create-participants-group",
  deleteContact: participantGroupPrefix + "/remove-contact",
  editGroup: participantGroupPrefix + "/edit-participants-group",
  getGroupById: participantGroupPrefix + "/get-participants-group-by-id",
  addParticipant: participantGroupPrefix + "/add-or-remove-participants",
  addMultipleParticipant:
    participantGroupPrefix + "/add-multiple-participants-to-group",
  getGrouplogs: participantGroupPrefix + "/get-group-lead-logs",
  getLeadByGroupId: participantGroupPrefix + "/get-lead-by-group-id",
  addDefaultAssignee: participantGroupPrefix + "/add-default-assignee-to-group",
  changeRoleParticipant: participantGroupPrefix + "/change-participants-role",
  getLeadGroupParticipant:
    participantGroupPrefix + "/get-all-participants-of-groups",
};

//
export const activityGroupRelatedEndpoints = {
  addActivityGroup: activityGroupPrefix + "/add-activity-group",
  editActivityGroup: activityGroupPrefix + "/edit-activity-group",
  getActivityGroup: activityGroupPrefix + "/get-activity-groups",
  addActivityStatus: activityGroupPrefix + "/add-activity-status",
  getActivitySubStatus: activityGroupPrefix + "/get-activity-status",
  editActivityStatus: activityGroupPrefix + "/edit-activity-status",
};
// ** BUSINESS INVOICE RELATED ENDPOINTS **
export const businessInvoiceEndpoints = {
  getBusinessInvoice: businessPrefix + "/get-business-setting",
  editBusinessInvoice: businessPrefix + "/edit-business-setting",
};

export const CrmSettingsRelatedEndPoints = {
  createDynamicField: dynamicFieldPrefix + "/create-dynamic-custom-field",
  getAllFields: dynamicFieldPrefix + "/get-dynamic-custom-fields",
  editDynamicField: dynamicFieldPrefix + "/edit-dynamic-custom-field",
};

// ** DYNAMIC EMAIL RELATED ENDPOINTS **
export const DynamicEmailRelatedEndPoints = {
  addDynamicEmailTemplate: dynamicEmailPrefix + "/add-dynamic-email",
  editDynamicEmailTemplate: dynamicEmailPrefix + "/edit-dynamic-email",
  getDynamicEmailTemplate: dynamicEmailPrefix + "/get-dynamic-emails",
  getDynamicSingleEmailTemplate: dynamicEmailPrefix + "/get-dynamic-email",
  deleteDynamicEmailTemplate: dynamicEmailPrefix + "/delete-dynamic-email",
  sendDynamicEmail: dynamicEmailPrefix + "/send-dynamic-email",
  getEmailActivity: dynamicEmailPrefix + "/get-email-activity",
};
