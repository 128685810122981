import React, { useEffect, useState } from "react";
import ErrorMessage from "../../../../components/Error/ErrorMessage";
import {
  getCurrentWorkspaceId,
  reuiredField,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { businessInvoiceEndpoints } from "../../../../api/Endpoints";
import { toast } from "react-toastify";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import { useSelector } from "react-redux";

const EditInvoiceModal = ({
  setInvoiceModal,
  editInvoiceDetails,
  handleGetInvoiceDetails,
}) => {
  const { user } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [invoiceTitle, setinvoiceTitle] = useState("");

  const handleReset = () => {
    setInvoiceModal(false);
    setinvoiceTitle("");
  };

  useEffect(() => {
    if (editInvoiceDetails) {
      setinvoiceTitle(editInvoiceDetails?.invoiceTitle);
    }
  }, []);

  const handleEditInvoice = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      businessId: getCurrentWorkspaceId(),
      invoiceTitle,
      actionBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      businessInvoiceEndpoints.editBusinessInvoice,
      data
    );
    if (res?.success) {
      handleGetInvoiceDetails();
      handleReset();
      toast.success("Invoice Details edited successfully!");
      setloader(false);
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-md modal-dialog-centered"
        role="document"
      >
        <div className="modal-content p-2">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                handleReset();
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>Edit Invoice</h5>
            <div className="border-bottom mb-2"></div>
            <form
              onSubmit={(e) => {
                handleEditInvoice(e);
              }}
            >
              <div className="row gx-3">
                <div className="col-12">
                  <label className="text-dark fw-300 fs-6">
                    Invoice Name {reuiredField}
                  </label>
                  <input
                    className="form-control w-100"
                    type="text"
                    required
                    maxLength={10}
                    value={invoiceTitle}
                    // onChange={(e) => setinvoiceTitle(e.target.value)}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const filteredValue = inputValue.replace(/\s/g, "");
                      setinvoiceTitle(filteredValue);
                    }}
                  />
                </div>

                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              </div>
              <div className="modal-footer align-items-center mt-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    handleReset();
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!invoiceTitle || invoiceTitle?.length <1}
                >
                  {loader ? <ApiLoader /> : "Edit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditInvoiceModal;
