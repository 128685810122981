import ApiCall from "../api/ApiCall";
import {
  CrmTaskRelatedEndPoints,
  authenticateEndpoints,
  businessEndpoints,
  employeeEndPoints,
  homeEndpoints,
  managementEndpoints,
  spaceEndpoint,
  taskEndpoint,
  workspaceRoleEndPoints,
} from "../api/Endpoints";
import { AWS_ACCESS_KEY, AWS_SECRET_ACCESS_KEY } from "../aws-s3";
import AWS from "aws-sdk";
import allModules from "../Navigation/AllModules";
import { AWS_BUCKET_NAME } from "../api/base";
import CustomTooltip from "../components/CustomTooltip/CustomTooltip";
import moment from "moment";

//******* START API CALL ********/
export const startApiCall = (seterrorMessage, setLoader) => {
  {
    seterrorMessage !== null && seterrorMessage("");
  }
  setLoader(true);
  setTimeout(() => {
    setLoader(false);
  }, 50000);
};

/********* CHECK VALID BUSINESS AND SWITCH FOR MAIN SWITCH ***********/
export const checkValidWorkspaceForAdmin = (
  otherWorkspaceIds,
  currentWorkspaceId,
  isSuperAdmin
) => {
  if (otherWorkspaceIds?.length > 0) {
    if (!otherWorkspaceIds?.includes(currentWorkspaceId) && !isSuperAdmin) {
      localStorage.setItem("current-workspace", otherWorkspaceIds?.[0]);
      return otherWorkspaceIds?.[0];
    } else {
      localStorage.setItem("current-workspace", currentWorkspaceId);
      return currentWorkspaceId;
    }
  }
};

/********* CHECK VALID BUSINESS AND SWITCH ***********/
export const checkValidWorkspace = (otherWorkspaceIds, currentWorkspaceId) => {
  if (otherWorkspaceIds?.length > 0) {
    if (!otherWorkspaceIds?.includes(currentWorkspaceId)) {
      localStorage.setItem("current-workspace", otherWorkspaceIds?.[0]);
      return otherWorkspaceIds?.[0];
    } else {
      localStorage.setItem("current-workspace", currentWorkspaceId);
      return currentWorkspaceId;
    }
  }
};

/********* CHECK VALID WORKSPACEID ***********/
export const checkWorkspaceIdValidation = async (
  allWorkspaces,
  currentWorkspaceId
) => {
  let workSpaceIds = allWorkspaces?.map((ws) => ws?._id);
  checkValidWorkspace(workSpaceIds, currentWorkspaceId);
};

//********* GET ALL MY APPROVED BUSINESSES ***********/
export const getAllMyApprovedBusinesses = async (userId) => {
  let data = {
    pageLimit: 4000,
    pageNumber: 1,
    employeeId: userId,
    status: "approved",
  };
  let res = await ApiCall("post", businessEndpoints.getBusiness, data);
  return res.success ? res.business ?? [] : [];
};

//********* GET CALL REMINDER WITHIN HOUR ***********/
export const getCallReminderWithInHour = async (assignees) => {
  let data = {
    taskType: "call",
    businessId: getCurrentWorkspaceId(),
    assignedTo: assignees ? assignees?.[0] : "",
  };
  let res = await ApiCall("post", CrmTaskRelatedEndPoints.taskWithInHour, data);
  return res.success ? res?.result ?? [] : [];
};

// export const findClosestTime = (currentTime, taskArray) => {
//   let closestTime = taskArray?.[0]?.snooze?.snoozedTime;
//   let closestDifference = Math.abs(
//     currentTime?.getTime() - new Date(taskArray?.[0]?.startTime)?.getTime()
//   );
//   let closestTask = taskArray?.[0];

//   for (let i = 1; i < taskArray?.length; i++) {
//     const taskTime =
//       taskArray?.[i]?.startTime instanceof Date
//         ? taskArray?.[i]?.startTime
//         : new Date(taskArray?.[i]?.startTime);

//     const difference = Math?.abs(currentTime?.getTime() - taskTime?.getTime());

//     if (difference < closestDifference) {
//       closestTime = taskArray?.[i]?.startTime;
//       closestDifference = difference;
//       closestTask = taskArray?.[i];
//     }
//   }

//   let task = {
//     closestTime: new Date(closestTime),
//     closestTask: closestTask,
//   };

//   return JSON.stringify(task);
// };

export const findClosestTime = (currentTime, taskArray) => {
  // Directly set the snoozedTime of the first task as the closest time
  let closestTask = taskArray?.[0];
  let closestTime = closestTask?.snooze?.snoozedTime;

  // Return the closest time and task in a JSON string format
  let task = {
    closestTime: new Date(closestTime),
    closestTask: closestTask,
  };

  return JSON.stringify(task);
};

//********* SWITCH INTO ANOTHER WORKSPACE ***********/
export const switchIntoAnotherWorkspace = async (workspaceId) => {
  localStorage.setItem("current-workspace", workspaceId);
  window.location.href = "/home";
};

//********* GET ALL MY BUSINESSES ***********/
export const getAllStatusBusinesses = async (userId) => {
  let data = {
    pageLimit: 4000,
    pageNumber: 1,
    employeeId: userId,
    status: "",
  };
  let res = await ApiCall("post", businessEndpoints.getBusiness, data);
  return res.success ? res.business ?? [] : [];
};

//********* GET ALL MY APPROVED BUSINESSES USING FILTER ***********/
export const getAllMyBusinessesUsingFilter = async (allWorkspaces, status) => {
  let temp = allWorkspaces?.filter(
    (ws) => ws?.approvalRequest?.status === status
  );
  return temp;
};

/*********  GET MONTHS INDEX WISE ***********/

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getTimeFormatter = (isoString) => {
  const date = new Date(isoString);

  // Create a custom function to format the time with AM/PM
  function formatTimeWithAmPm(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amOrPm = hours < 12 ? "AM" : "PM";

    const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
  }

  // Call the function to format the time
  const formattedTime = formatTimeWithAmPm(date);
  return formattedTime;
};
/********* TIME FORMATTER *************/
export const timeFormatter = (time24Hours) => {
  const options = {
    hour: "2-digit",
    minute: "2-digit",
  };
  const [hours, minutes] = time24Hours?.split(":");
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);

  return date.toLocaleTimeString("en-IN", options);
};

//********* DATE & TIME FORMATER ***********/
export const dateAndTimeFormatter = (isDate) => {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  return new Date(isDate).toLocaleDateString("en-IN", options);
};

//********* TIME IN HOURS ***********/
export const getTimeAgo = (timestamp) => {
  const now = new Date();
  const createdTime = new Date(timestamp);
  const timeDifference = now - createdTime;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days === 0) {
    if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
    }
  } else {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return createdTime.toLocaleDateString("en-IN", options);
  }
};

//********* DATE FORMATER ***********/
export const dateFormatter = (isDate) => {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  return new Date(isDate).toLocaleDateString("en-IN", options);
};
//********* REVERSE DATE FORMATER ***********/
export const reversesDateFormatter = (isDate) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const formattedDate = new Date(isDate).toLocaleDateString("en-IN", options);
  const [day, month, year] = formattedDate.split("/");

  return `${year}-${month}-${day}`;
};

//********* TIME FORMATER  HH:mm ***********/

export const customTimeFormater = (inputDateString) => {
  const inputDate = new Date(inputDateString);
  const istOptions = {
    timeZone: "Asia/Kolkata",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  };
  // Format the date in IST
  const istFormattedTime = new Intl.DateTimeFormat("en-IN", istOptions).format(
    inputDate
  );
  return istFormattedTime;
};

//********* AVATAR COLOR ***********/

export const getAvatarClass = (nameInitial) => {
  let tempAvatarClass = "";
  if (nameInitial >= "A" && nameInitial <= "E") {
    tempAvatarClass = "avatar-primary";
  } else if (nameInitial >= "F" && nameInitial <= "J") {
    tempAvatarClass = "avatar-sun";
  } else if (nameInitial >= "K" && nameInitial <= "O") {
    tempAvatarClass = "avatar-orange";
  } else if (nameInitial >= "P" && nameInitial <= "T") {
    tempAvatarClass = "avatar-pumpkin";
  } else if (nameInitial >= "U" && nameInitial <= "Y") {
    tempAvatarClass = "avatar-brown";
  } else if (nameInitial === "Z") {
    tempAvatarClass = "avatar-soft-neon";
  }
  return tempAvatarClass;
};

//********* GET CURRENT WORKSPACE ID ***********/
export const getCurrentWorkspaceId = () => {
  try {
    const stateStr = localStorage.getItem("current-workspace");
    return stateStr ? stateStr : undefined;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

//********* GET CURRENT WORKSPACE NAME ***********/
export const getCurrentWorkspaceName = (workspaces, currentWorkspaceId) => {
  checkWorkspaceIdValidation(workspaces, currentWorkspaceId);
  let work = workspaces?.filter((ws) => {
    return ws?._id === getCurrentWorkspaceId();
  });
  return work?.length > 0 ? work?.[0]?.name : workspaces?.[0]?.name;
};

//********* CHECK IF CURRENT WORKSPACE IS DEFAULT ***********/
export const isCurrentWorkspaceDefault = (workspaces, currentWorkspaceId) => {
  checkWorkspaceIdValidation(workspaces, currentWorkspaceId);
  const currentWorkspace = workspaces?.find(
    (ws) => ws?._id === getCurrentWorkspaceId()
  );
  return currentWorkspace ? currentWorkspace.isDefault : false;
};

//********* GET All EMPLOYEES ***********/
export const getAllEmployees = async (data) => {
  let res = await ApiCall("post", managementEndpoints.getAllEmployees, data);
  return res.success ? res?.employee ?? [] : [];
};

//********* GET All ROLES OF WORKSPACE ***********/
export const getAllRolesForWorkspace = async (businessId) => {
  let res = await ApiCall("post", workspaceRoleEndPoints.getAllRole, {
    businessId,
  });
  let temp = [];
  if (res.success) {
    temp = res?.role?.filter((emp) => {
      return emp.roleName !== "owner";
    });
  }
  return temp;
};

/********* USER LOGOUT ***********/
export const userLogoutHard = async (data) => {
  localStorage.clear();
  window.location.href = "/login";
};

/********* GET WORKSPACE ROLE ***********/
export const getWorkSpaceRole = (workspaces, key) => {
  let role = workspaces?.filter(
    (ws) => ws?.businessId?._id === getCurrentWorkspaceId()
  );
  return role?.length !== 0 ? role?.[0][key] : "-";
};

/********* IS EMPLOYEE OWNER ***********/
export const isEmployeeOwner = (permissions) => {
  let isOwner = false;
  let role = permissions?.filter(
    (ws) => ws?.businessId?._id === getCurrentWorkspaceId()
  );
  if (role?.length > 0) {
    role?.[0]["roleName"] === "owner" ? (isOwner = true) : (isOwner = false);
  }
  return isOwner;
};

/********* GET DASHBOARD RELATED COUNTS ***********/
export const getDashboardRelatedCounts = async (businessId, userId) => {
  let res = await ApiCall("post", homeEndpoints.getDashboardCounts, {
    businessId,
    userId,
  });
  return res?.logs;
};

/********* GET WORKSPACE REQUESTES ***********/
export const getAllWorkspaceRequests = async (userId, businessId) => {
  let res = await ApiCall("post", authenticateEndpoints.allBusinessRequest, {
    employeeId: userId,
    sortType: "updatedAt",
    businessId,
  });
  return res.success ? res?.employee ?? [] : [];
};

//********* GET All EMPLOYEES BY PRIVATE ACCESS***********/
export const getAllEmployeesPrivateAccess = async (data) => {
  let res = await ApiCall(
    "post",
    spaceEndpoint.privateAccessEmployeeDetails,
    data
  );
  return res.success ? res?.space ?? [] : [];
};

//********* GET All WORKSPACE STATUS BY SPACE ***********/
export const getAllWorkspaceStatusBySpace = async (spaceId) => {
  const data = {
    businessId: getCurrentWorkspaceId(),
    spaceId,
  };
  let res = await ApiCall("post", taskEndpoint.getStatus, data);
  return res.success ? res?.taskStatus ?? [] : [];
};

//********* GET EMPLOYEE BY ID ***********/
export const handleGetEmployeeById = async (employeeId) => {
  const data = {
    businessId: getCurrentWorkspaceId(),
    employeeId: employeeId,
  };
  let res = await ApiCall("post", employeeEndPoints.getEmployeeById, data);
  return res.success ? res?.employee ?? [] : [];
};

// Function to copy text to clipboard using the Clipboard API
export const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.log("Text copied to clipboard successfully");
    })
    .catch((error) => {
      console.error("Failed to copy text to clipboard:", error);
    });
};

// GET OWNER FOR INDIVIDUAL MODULE
export const getCreatorForIndividualModule = (infoData, empId, moduleName) => {
  let role = false;
  if (infoData?.createdBy) {
    role = infoData?.createdBy?._id === empId ? true : false;
  }
  return role;
};

// LOCAL URL FOR IMAGE
export const createLocalUrlForImage = (event) => {
  let img = URL.createObjectURL(event.target.files[0]);
  return img ? img : null;
};

//UPLOAD IMAGE INTO AWS
export const handleUploadInDirectAws = async (file) => {
  AWS.config.update({
    region: "ap-south-1",
    credentials: new AWS.Credentials(AWS_ACCESS_KEY, AWS_SECRET_ACCESS_KEY),
  });

  let s3 = new AWS.S3({
    apiVersion: "2022-04-04",
    params: { Bucket: AWS_BUCKET_NAME },
  });

  let data = file?.target?.files[0];
  let upload_params = {
    Bucket: AWS_BUCKET_NAME,
    Key: data ? data.name : "",
    Body: data ? data : "",
  };
  return s3.upload(upload_params).promise();
};

// export const handlePreviewFileInLocal = (event) => {
//   let img = URL?.createObjectURL(event.target.files[0]);
//   return img;
// };
export const handlePreviewFileInLocal = (event) => {
  if (event?.target?.files && event.target.files.length > 0) {
    let img = URL.createObjectURL(event.target.files[0]);
    return img;
  }
};

// FILE/IMAGE SIZE
export const convertFileSize = (x) => {
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let l = 0,
    n = parseInt(x, 10) || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
};

// GET NAME
export const getName = (user, currentUserId) => {
  let name = "";
  if (user?._id === currentUserId) {
    name = "You";
  } else {
    name = user?.name;
  }
  return name;
};

//DELETE AWS IMAGE
export const handleDeleteAwsImage = async (img) => {
  let s3 = new AWS.S3({
    apiVersion: "2022-04-04",
    params: { Bucket: AWS_BUCKET_NAME },
  });

  let delete_params = {
    Bucket: AWS_BUCKET_NAME,
    Key: img,
  };
  s3.deleteObject(delete_params, function (err, data) {
    if (err) console.log(err, err.stack);
    else console.log("deleted", img);
  });
};

export const handleUploadInDirectAwsAttachment = async (file) => {
  AWS.config.update({
    region: "ap-south-1",
    credentials: new AWS.Credentials(AWS_ACCESS_KEY, AWS_SECRET_ACCESS_KEY),
  });

  let s3 = new AWS.S3({
    apiVersion: "2022-04-04",
    params: { Bucket: AWS_BUCKET_NAME },
  });

  let upload_params = {
    Bucket: AWS_BUCKET_NAME,
    Key: file ? file.name : "",
    Body: file ? file : "",
  };
  return s3.upload(upload_params).promise();
};

//GET ALL SIDEBAR MAIN MODULES
export const getMyMainModules = () => {
  let MainModules = [];
  MainModules = allModules?.[0]?.menuItems
    ?.filter((m) => m?.isModulePermission === true)
    ?.map((mdl) => mdl?.name);
  return MainModules;
};

//GET ALL SIDEBAR SUBMODULES
export const getMySubModules = () => {
  let SubModules = [];
  allModules?.[0]?.menuItems
    ?.filter((m) => m?.isModulePermission === true)
    ?.map((mdl) => mdl?.subItems?.map((sm) => SubModules.push(sm.name)));

  return SubModules;
};

//GET ALL SIDEBAR MODULES WITH MENU AND SUBMENU
export const getMyAllModules = () => {
  let allModules = [];
  allModules = getMyMainModules()?.concat(getMySubModules());
  return allModules;
};

/********* GET WORKSPACE WISE PERMISSIONS MODULES ***********/
export const getWorkspaceWisePermissionsModules = (
  permissions,
  workspaceId
) => {
  let per = [];
  per = permissions?.filter((ws) => ws?.businessId?._id === workspaceId);
  if (per?.length > 0) {
    per = per?.[0]?.modules;
  }
  return per;
};

/********* IS EMPLOYEE OWNER ***********/
export const isEmployeeOwnerForPermissionAnotherFunc = (
  permissions,
  workspaceId
) => {
  let isOwner = false;
  let role = permissions?.filter((ws) => ws?.businessId?._id === workspaceId);
  if (role?.length > 0) {
    role?.[0]["roleName"] === "owner" ? (isOwner = true) : (isOwner = false);
  }
  return isOwner;
};

/********* USER SUPER ADMIN CHECK ***********/
export const isUserSuperAdmin = (user) => {
  return user ? user?.isSuperAdmin : false;
};

export const convertTotalHoursToHoursAndMinutes = (totalHours) => {
  const hours = Math.floor(totalHours);
  const minutes = (totalHours - hours) * 60;
  return hours === 0
    ? `-`
    : `${hours} hours and ${Math.floor(minutes)} minutes.`;
};

/********* SEARCH IMPLEMENT WITHOUT API ***********/
export const filterSearchData = (allData, filter) => {
  const filteredData = allData?.filter((data) =>
    data?.name?.toLowerCase()?.includes(filter.toLowerCase())
  );
  return filteredData;
};

/********* GET LOCATION URL ***********/
export const getLocationUrl = (latitude, longitude) => {
  if (latitude.length > 0 && longitude.length > 0) {
    const baseUrl = "https://www.google.com/maps/search/?api=1&query=";
    const locationUrl = baseUrl + latitude + "," + longitude;
    return locationUrl;
  }
  return "";
};

/********* REQUIRED FIELD ***********/
export const reuiredField = (
  <CustomTooltip text={"This field is required to be filled"} placement="right">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="6"
      fill="currentColor"
      class="bi bi-asterisk mb-2 text-danger"
      viewBox="0 0 16 16"
    >
      <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
    </svg>
  </CustomTooltip>
);

export const convertWithMomentForInput = (dateTimeString) => {
  const utcDateTime = moment.utc(dateTimeString);
  // Convert to India Standard Time (IST)
  const istDateTime = utcDateTime.clone().utcOffset("+05:30");

  return istDateTime.format("YYYY-MM-DDTHH:mm");
};

/********* CALCULATE PRICE AND RETURN TOTALAMOUNT ***********/
export const getTotalOfItems = (items, key) => {
  const total = items.reduce((accumulator, dt) => {
    const value = dt?.[key];
    return accumulator + parseInt(value);
  }, 0);
  return parseInt(total);
};

export const convertAmountLocal = (amount) => {
  return (
    "₹" +
    Number(amount).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );
};

/*********  CONVERT WITH MOMENT DATE AND TIME FOR TABKE ***********/
export const convertTimeAndDateMoment = (dateStr) => {
  const formattedDate = moment(dateStr).format("MM/DD/YYYY, hh:mm a");
  return formattedDate;
};

/*********  CONVERT CSV NAME ***********/
export const convertCsvName = (value) => {
  // Convert the string to lowercase
  // let csvName = value?.toLowerCase();
  // Remove all spaces and special characters
  // csvName = csvName?.replace(/[^a-z]/g, "");
  return value + "-custom";
};

/*********  Modal Observer ***********/
export const handleModalMutation = (modalId, callback) => {
  let observer;

  const disconnectObserver = () => {
    if (observer) {
      observer.disconnect();
    }
  };

  const observeModal = () => {
    observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "aria-hidden"
        ) {
          const modalHidden =
            mutation.target.getAttribute("aria-hidden") === "true";
          if (!modalHidden) {
            callback();
          }
        }
      });
    });

    const modal = document.getElementById(modalId);
    if (modal) {
      observer.observe(modal, {
        attributes: true,
        attributeFilter: ["aria-hidden"],
      });
    }
  };

  observeModal();

  return {
    observe: observeModal,
    disconnect: disconnectObserver,
  };
};

/********* Close All Modal ***********/

export const closeAllModals = () => {
  const modals = document.querySelectorAll(".modal");
  modals.forEach((modal) => {
    modal.classList.remove("show");
    modal.style.display = "none";
  });

  const backdrops = document.querySelectorAll(".modal-backdrop");
  backdrops.forEach((backdrop) => {
    backdrop.classList.remove("show");
    backdrop.style.display = "none";
  });
};

//********* Text Truncate *********//
export const truncatedText = (text, maxLength) => {
  return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
};
