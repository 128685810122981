import React, { useEffect, useState } from "react";
import AddRoleModal from "./AddRoleModal";
import { useSelector } from "react-redux";
import allModules from "../../../Navigation/AllModules";
import SubModuleTable from "./SubModuleTable";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";

function AddRoleModalView(props) {
  const { moduleData, setmoduleData, setroleModal, handleGetAllRole } = props;
  const [readAccessItems, setreadAccessItems] = useState([]);
  const [readWriteAcessItems, setreadWriteAcessItems] = useState([]);
  const [selectedModule, setSelectedModule] = useState("");
  const [moduleName, setmoduleName] = useState("Spaces");
  const [roleNameModal, setroleNameModal] = useState(false);
  const { user, allModulePermission } = useSelector((state) => state);
const [firstAndLastModuleName, setfirstAndLastModuleName] = useState({
  first:"",
  last:""
})

  const selectReadAccessItems = (items) => {
    const newReadAccessItems = [...readAccessItems];
    items?.subItems?.forEach((subitem) => {
      if (newReadAccessItems.includes(subitem.name)) {
        newReadAccessItems.splice(newReadAccessItems.indexOf(subitem?.name), 1);
        setreadAccessItems(newReadAccessItems);
      } else if (!newReadAccessItems.includes(subitem.name)) {
        newReadAccessItems.push(subitem.name);
        setreadAccessItems(newReadAccessItems);
      }
    });
    // setreadAccessItems(newReadAccessItems);
  };

  const selectReadWriteAccessItems = (items) => {
    const newReadAccessWriteItems = [...readWriteAcessItems];
    items?.subItems?.forEach((subitem) => {
      if (newReadAccessWriteItems.includes(subitem.name)) {
        newReadAccessWriteItems.splice(
          newReadAccessWriteItems.indexOf(subitem?.name),
          1
        );
        setreadWriteAcessItems(newReadAccessWriteItems);
      } else if (!newReadAccessWriteItems.includes(subitem.name)) {
        newReadAccessWriteItems.push(subitem.name);
        setreadWriteAcessItems(newReadAccessWriteItems);
      }
    });
    // setreadWriteAcessItems(newReadAccessWriteItems);
  };

  useEffect(() => {
    if (moduleData !== "") {
      let readAccessModules = moduleData?.modules
        ?.filter((items) => items?.permissionType === "read")
        ?.map((it) => {
          return it.moduleName;
        });
      let readWriteAccessModules = moduleData?.modules
        ?.filter((items) => items?.permissionType === "read-write")
        ?.map((it) => {
          return it.moduleName;
        });
      setreadAccessItems(readAccessModules);
      setreadWriteAcessItems(readWriteAccessModules);
    }
  }, [moduleData]);

  const resetModal = () => {
    setreadWriteAcessItems([]);
    setreadAccessItems([]);
    setmoduleData("");
    setroleModal(false);
  };

  const getForwardModuleName = (direction) => {
    // Find the index of the selected module name
    const filteredMenu = allModules?.[0]?.menuItems?.filter(
      (m) =>  m.isRole === true
    );

    const currentIndex = filteredMenu.findIndex(
      (module) => module.name === moduleName
    );

    // If the current index is valid and not the last one, return the next module name
    if (
      direction === "forward" &&
      currentIndex !== -1 &&
      currentIndex < filteredMenu.length - 1
    ) {
      setmoduleName(filteredMenu[currentIndex + 1].name);
    }
    if (direction === "backward" && currentIndex > 0) {
      setmoduleName(filteredMenu[currentIndex - 1].name);
    }
  };
  
  return (
    <>
      <div
        className="custom-modal"
        tabIndex="-1"
        role="dialog"
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body pb-0">
              <button type="button" className="btn-close" onClick={resetModal}>
                <span aria-hidden="true">×</span>
              </button>
              <span class="d-flex">
                <h5 class="text-capitalize mb-2">
                  {moduleData === "" ? "Add Role" : "Edit Role"}
                </h5>
                &nbsp;
                <CustomTooltip
                  text="Select Module and Create new Role by clicking the Add button on the screen"
                  placement="right"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-info-circle-fill mt-1"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                  </svg>
                </CustomTooltip>
              </span>

              <div className="border-bottom"></div>
              <div className="row mh-500p overflow-auto">
                <div className="col-lg-3 col-md-12">
                  <ul
                    className="border-0 p-3 row"
                    style={{ listStyleType: "none", padding: 0 }}
                  >
                       {allModules?.[0]?.menuItems
                      ?.filter((m) => m?.isRole === true && !m?.subItems)
                      .map((items, index) => {
                        const showSelectedItemsNamae =
                          !selectedModule || selectedModule === items?.name;
                        return (
                          showSelectedItemsNamae && (
                            <li
                              className="list-item rounded-5 mb-2 p-3"
                              style={{
                                cursor: "pointer",
                                backgroundColor:
                                  moduleName === items?.name ? "#e3edff" : "",
                                border: "none",
                              }}
                              key={index}
                              onClick={() => {
                                setmoduleName(items?.name);
                              }}
                            >
                              <h6 className="mb-0">
                                <span style={{ marginRight: 10 }}>
                                  {items?.svg}
                                </span>
                                {items?.name}
                                {items?.subItems?.length && (
                                  <span className="badge badge-sm badge-pill badge-soft-primary ms-2">
                                    {items?.subItems?.length}
                                  </span>
                                )}
                              </h6>
                            </li>
                          )
                        );
                      })}
                    {allModules?.[0]?.menuItems
                      ?.filter((m) => m?.subItems && m.isRole === true)
                      .map((items, index) => {
                        const showSelectedItemsNamae =
                          !selectedModule || selectedModule === items?.name;
                        return (
                          showSelectedItemsNamae && (
                            <li
                              className="list-item rounded-5 mb-2 p-3 col-lg-12 col-md-6"
                              style={{
                                cursor: "pointer",
                                backgroundColor:
                                  moduleName === items?.name ? "#e3edff" : "",
                                border: "none",
                              }}
                              key={index}
                              onClick={() => {
                                setmoduleName(items?.name);
                              }}
                            >
                              <h6 className="mb-0">
                                <span style={{ marginRight: 10 }}>
                                  {items?.svg}
                                </span>
                                {items?.name}
                                {items?.subItems?.length && (
                                  <span className="badge badge-sm badge-pill badge-soft-primary ms-2">
                                    {items?.subItems?.length}
                                  </span>
                                )}
                              </h6>
                            </li>
                          )
                        );
                      })}
                 
                  </ul>
                </div>

                <div className="col-lg-9 col-md-12 border-start">
                  <SubModuleTable
                    readAccessItems={readAccessItems}
                    setreadAccessItems={setreadAccessItems}
                    readWriteAcessItems={readWriteAcessItems}
                    setreadWriteAcessItems={setreadWriteAcessItems}
                    selectReadAccessItems={selectReadAccessItems}
                    selectReadWriteAccessItems={selectReadWriteAccessItems}
                    selectedModule={selectedModule}
                    moduleName={moduleName}
                    user={user}
                    allModulePermission={allModulePermission}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-between">
              <div className="d-flex align-self-start gap-2">
                {readAccessItems?.length > 0 && (
                  <span>Read Access: {readAccessItems?.length}</span>
                )}
                {readWriteAcessItems?.length > 0 && (
                  <span>
                    Read & Write Access: {readWriteAcessItems?.length}
                  </span>
                )}
              </div>

              <div className="d-flex align-self-end gap-2">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={resetModal}
                >
                  Discard
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={moduleName==="Spaces"}
                  onClick={() => {
                    getForwardModuleName("backward");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                    />
                  </svg>
                  &nbsp; Prev
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={moduleName==="CRM"}
                  onClick={() => {
                    getForwardModuleName("forward");
                  }}
                >
                  Next&nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => {
                    setroleNameModal(true);
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {roleNameModal && (
        <AddRoleModal
          readAccessItems={readAccessItems}
          readWriteAcessItems={readWriteAcessItems}
          setreadAccessItems={setreadAccessItems}
          setreadWriteAcessItems={setreadWriteAcessItems}
          user={user}
          moduleData={moduleData}
          setroleNameModal={setroleNameModal}
          setroleModal={setroleModal}
          setmoduleData={setmoduleData}
          handleGetAllRole={handleGetAllRole}
        />
      )}
    </>
  );
}

export default AddRoleModalView;
