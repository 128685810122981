import React from "react";
import ConfirmModal from "../../../../components/ConfirmationModal/ConfirmModal";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import { truncatedText } from "../../../../global-functions/globalFunctions";

const MailTemplates = ({
  mailTemplateLoader,
  allMailTemplates,
  setEditMailTemplate,
  mailTemplateConfirmModal,
  setMailTemplateConfirmModal,
  setMailTemplateId,
  handleMailTemplateConfirmModalClose,
  mailTemplateErrorMessage,
  handleDeleteMailTemplate,
  setAddMailTemplateModal,
  setTemplateDetailsModal,
  mailTemplatePage,
  mailTemplatePageLimit,
}) => {
  return (
    <>
      <div className="contact-list-view mb-7">
        {!mailTemplateLoader && allMailTemplates?.length > 0 && (
          <table id="datable_1" className="table">
            <thead>
              <tr className="text-nowrap">
                <th className="text-center">Sl. No.</th>
                <th>Display Name</th>
                <th>Category</th>
                <th>Template Title</th>
                <th>Email Subject</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {allMailTemplates?.map((item, index) => {
                return (
                  <tr>
                    <td className="text-center">
                      {(mailTemplatePage - 1) * mailTemplatePageLimit +
                        (index + 1)}
                      &nbsp;.
                    </td>
                    <td>{truncatedText(item?.displayName || "N/A", 20)}</td>
                    <td>{truncatedText(item?.category || "N/A", 20)}</td>
                    <td>{truncatedText(item?.title || "N/A", 20)}</td>
                    <td>{truncatedText(item?.subject || "N/A", 40)}</td>

                    <td className="text-center">
                      <div className="dropdown">
                        <button
                          className="btn btn-icon dropdown-toggle btn-light no-dropdown-arrow rounded-circle border"
                          type="button"
                          id="action_btn"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-three-dots"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                          </svg>
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="action_btn"
                        >
                          <li>
                            <div className="dropdown-item">
                              <span
                                className="d-flex align-items-center gap-1 text-secondary"
                                onClick={() => {
                                  setEditMailTemplate(item);
                                  setTemplateDetailsModal(true);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-info-circle-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                                </svg>
                                Details
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="dropdown-item">
                              <span
                                className="d-flex align-items-center gap-1 text-primary"
                                onClick={() => {
                                  setEditMailTemplate(item);
                                  setAddMailTemplateModal(true);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-pencil"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                </svg>
                                Edit
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="dropdown-item">
                              <span
                                className="d-flex align-items-center gap-1 text-danger"
                                onClick={() => {
                                  setMailTemplateConfirmModal(true);
                                  setMailTemplateId(item?._id);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-trash"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                </svg>
                                Delete
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        {mailTemplateLoader && <DataLoader title="data is loading..." />}
      </div>

      <ConfirmModal
        isOpen={mailTemplateConfirmModal}
        onClose={handleMailTemplateConfirmModalClose}
        text="Are You Sure You Want To Delete This Mail Template"
        loader={mailTemplateLoader}
        errorMessage={mailTemplateErrorMessage}
        handleConfirm={handleDeleteMailTemplate}
      />
    </>
  );
};

export default MailTemplates;
