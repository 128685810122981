import React from "react";

const CrmEmailsBody = ({
  allEmails,
  page,
  pageLimit,
  setSelectedMailTemplate,
  setEmailDetailsModal,
}) => {
  return (
    <>
      <div className="contact-list-view">
        <table id="datable_1" className="table nowrap w-100 mb-6">
          <thead>
            <tr>
              <th>No.</th>
              <th>Display Name</th>
              <th>Email Subject</th>
              <th>Action By</th>
              <th>Action On</th>
              <th>Email From</th>
              <th>Email To</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {allEmails?.map((email, index) => {
              return (
                <tr>
                  <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                  <td>{email?.displayName || "N/A"}</td>
                  <td>{email?.subject || "N/A"}</td>
                  <td>{email?.actionBy?.name || "N/A"}</td>
                  <td>
                    {email?.actionOn?.title || email?.actionOn?.name || "N/A"}
                  </td>
                  <td>{email?.actionBy?.name || "N/A"}</td>
                  <td>{email?.actionOn?.email}</td>
                  <td>
                    <span
                      className="btn btn-icon btn-light rounded-circle text-primary"
                      onClick={() => {
                        setSelectedMailTemplate(email);
                        setEmailDetailsModal(true);
                      }}
                    >
                      <span className="feather-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-info-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                        </svg>
                      </span>
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CrmEmailsBody;
