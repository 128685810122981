// ******* AUTHENTICATION ROUTES *****
let authenticationPrefix = "/auth";

export const navigateToLogin = () => {
  return `${authenticationPrefix}/login`;
};

export const navigateToSignup = () => {
  return `${authenticationPrefix}/signup`;
};

// ******* SCRUMBOARD ROUTES *****
let scrumboardPrefix = "/scrumboard";

export const navigateToAllBoard = (data) => {
  return `${scrumboardPrefix}/allboards`;
};

export const navigateToTaskboard = (data) => {
  return `${scrumboardPrefix}/taskboard`;
};

// ******* TODO ROUTES *****
let todoPrefix = "/todo";

export const navigateToTasklist = (data) => {
  return `${todoPrefix}/tasklist`;
};

export const navigateToGantt = (data) => {
  return `${todoPrefix}/gantt`;
};

// ******* CALENDAR ROUTES *****
let calendarPrefix = "/calendar";

export const navigateToInitialCalendarPage = (data) => {
  return `${calendarPrefix}`;
};

// ******* TODO ROUTES *****
let ApprovalPrefix = "/approval";

export const navigateToApprovalOwner = (data) => {
  return `${ApprovalPrefix}/owner`;
};

export const navigateToApprovalEmployee = (data) => {
  return `${ApprovalPrefix}/employee`;
};

export const navigateToApprovalBusiness = (data) => {
  return `${ApprovalPrefix}/allbusiness`;
};

// ******* WORKSPACE ROUTES *****
let workspacePrefix = "/workspace";

export const navigateToWorkspacePage = (data) => {
  return `${workspacePrefix}`;
};

// ******* SPACES ROUTES *****
let SpacePrefix = "/spaces";

export const navigateToSpaces = (data) => {
  return `${SpacePrefix}/allspaces`;
};
export const navigateToAllTaskList = (data) => {
  return `${SpacePrefix}/alltasklist/${data.id}`;
};
export const navigateToFolderList = (data) => {
  return `${SpacePrefix}/folderlist/${data.id}/${data.spaceId}/${data?.spacename}/${data.foldername}`;
};
export const navigateToIndividualSpaces = (data) => {
  return `${SpacePrefix}/individualspace/${data?.spacename}/${data.id}`;
};
export const navigateToTaskDetails = (data) => {
  return `${SpacePrefix}/taskdetails/${data.id}`;
};

// ******* PERMISSION MODULE ROUTES *****
let RolePrefix = "/role";

export const navigateToRoleList = (data) => {
  return `${RolePrefix}/rolelist`;
};

// ******* SETTINGS MODULE ROUTES *****
let SettingsPrefix = "/settings";

export const navigateToManagePeople = (data) => {
  return `${SettingsPrefix}/managepeople`;
};

export const navigateToOtherSettings = (data) => {
  return `${SettingsPrefix}/othersetting`;
};

// *******  MYTASKS ROUTES ***** //
let tasksPrefix = "/task";

export const navigateToMyTasksPage = (data) => {
  return `${tasksPrefix}/mytasks`;
};
// ******* TASKANALYSIS ROUTES ***** //

export const navigateToReport = (data) => {
  return `${tasksPrefix}/performance`;
};

export const navigateToTaskAnalysis = (data) => {
  return `${tasksPrefix}/taskanalysis`;
};
export const navigateToTaskAnalysisDetails = (data) => {
  return `${tasksPrefix}/taskanalysis/${data?.id}`;
};

// ******* COMPLAINT ROUTES ***** //
let GrievancePrefix = "/grievance";

export const navigateToMyGrievance = (data) => {
  return `${GrievancePrefix}/mygrievance`;
};

export const navigateToAlGrievance = (data) => {
  return `${GrievancePrefix}/allgrievance`;
};

export const navigateToGrievanceRate = (data) => {
  return `${GrievancePrefix}/homepage`;
};

// ******* LOG MODULE ROUTES *****//
let Logprefix = "/log";

export const navigateToLog = (data) => {
  return `${Logprefix}/${data?.module}/${data?.id}`;
};
// ******* PRIVACY POLICIY ROUTES *****//

export const redirectToPrivacyPolicy = () => {
  window.location.href =
    "https://sites.google.com/view/sociohood-technologies-tnc/home";
};

// ******* SALARY AND ATTENDANCE ROUTES ***** //

let Staffprefix = "/staff";

export const navigateToStaff = () => {
  return `${Staffprefix}/onboarding`;
};

export const navigateToStaffSalary = (data) => {
  return `${Staffprefix}/salary`;
};

export const navigateToStaffSalaryDetails = (data) => {
  return `${Staffprefix}/salary/salarydetails/${data.id}/${data.monthname}`;
};

export const navigateToStaffAttendance = (data) => {
  return `${Staffprefix}/attendance`;
};

export const navigateToStaffAttendanceDetails = (data) => {
  return `${Staffprefix}/attendance/attendancedetails/${data.id}`;
};

export const navigateToShiftTiming = () => {
  return `${Staffprefix}/allshifts`;
};
export const navigateToBusinessShift = () => {
  return `${Staffprefix}/businessshift`;
};

export const navigateToShiftDetails = (data) => {
  return `${Staffprefix}/shiftdetails/${data?.id}`;
};

export const navigateToHoliday = (data) => {
  return `${Staffprefix}/holiday`;
};

// ******* LEAVE ROUTES ***** //
let LeavePrefix = "/leave";

export const navigateToLeave = (data) => {
  return `${LeavePrefix}/leave`;
};

export const navigateToLeaveType = (data) => {
  return `${LeavePrefix}/leavetype`;
};

export const navigateToLeaveHistory = (data) => {
  return `${LeavePrefix}/leavehistory`;
};

export const navigateToLeaveRequest = (data) => {
  return `${LeavePrefix}/leaverequest`;
};

export const navigateToLeaveRequestDetails = (data) => {
  return `${LeavePrefix}/leavehistory/leavedetails/${data.id}/${data.monthname}`;
};

// ******* COMMUNICATION ROUTES ***** //
let CommunicationPrefix = "/communication";

export const navigateToNotice = (data) => {
  return `${CommunicationPrefix}/notices`;
};

export const navigateToNoticeDetails = (data) => {
  return `${CommunicationPrefix}/noticedetails/${data.id}`;
};

export const navigateToPoll = (data) => {
  return `${CommunicationPrefix}/polls`;
};

export const navigateToMeeting = (data) => {
  return `${CommunicationPrefix}/meetings`;
};

export const navigateToAllTask = () => {
  return `/alltask`;
};

// ******* HOLIDAY ROUTES ***** //
// let HolidayPrefix = "/holiday"

// export const navigateToHoliday = (data) => {
//   return `${HolidayPrefix}`;
// };

// ******* INVENTORY ROUTES ***** //
let InventoryPrefix = "/inventory";

export const navigateToRawMaterial = (data) => {
  return `${InventoryPrefix}/rawmaterial`;
};
export const navigateToItemCategory = (data) => {
  return `${InventoryPrefix}/itemcategories`;
};
export const navigateToCategoryWiseItems = (data) => {
  return `${InventoryPrefix}/itemcategories/${data?.category}/${data?.id}`;
};

export const navigateToManageStock = (data) => {
  return `${InventoryPrefix}/managestock`;
};

export const navigateToManageStockDetails = (data) => {
  return `${InventoryPrefix}/rawmaterial/stockdetails/${data?.id}`;
};

export const navigateToInventoryCustomer = (data) => {
  return `${InventoryPrefix}/customer`;
};

export const navigateToCustomerOrderDetails = (data) => {
  return `${InventoryPrefix}/customer/customerorderdetails/${data.id}`;
};

export const navigateToOrder = (data) => {
  return `${InventoryPrefix}/orders`;
};

export const navigateToAllTables = (data) => {
  return `${InventoryPrefix}/alltables`;
};
export const navigateToOrderApprovals = (data) => {
  return `${InventoryPrefix}/orderapprovals`;
};

export const navigateToManageInvoiceDetails = (data) => {
  return `${InventoryPrefix}/customer/invoicedetails/${data?.id}`;
};
export const navigateToOrderHistory = (data) => {
  return `${InventoryPrefix}/orderhistory`;
};

export const navigateToAllInventoryInvoices = (data) => {
  return `${InventoryPrefix}/allinvoices`;
};

export const navigateToCashInHand = (data) => {
  return `${InventoryPrefix}/cashinhand`;
};
export const navigateToInventoryAnalytics = (data) => {
  return `${InventoryPrefix}/inventoryanalytics`;
};

export const navigateToOrderBill = (data) => {
  return `${InventoryPrefix}/bill`;
};
export const navigateToInventorySettings = (data) => {
  return `${InventoryPrefix}/inventorysettings`;
};

// ******* ACCOUNTS ROUTES ***** //
let AccountPrefix = "/account";

export const navigateToItems = (data) => {
  return `${AccountPrefix}/allitems`;
};

export const navigateToAllCustomers = (data) => {
  return `${AccountPrefix}/allcustomers`;
};

export const navigateToAllInvoices = (data) => {
  return `${AccountPrefix}/allinvoices`;
};

// export const navigateToAllVendors = (data) => {
//   return `${AccountPrefix}/allvendors`;
// };

export const navigateToInvoiceDetails = (data) => {
  return `${AccountPrefix}/invoicedetails/${data.id}`;
};
export const navigateToGenerateInvoice = (data) => {
  return `${AccountPrefix}/generateinvoice`;
};
export const navigateToAllQuotes = (data) => {
  return `${AccountPrefix}/allquotes`;
};
export const navigateToQuotesDetails = (data) => {
  return `${AccountPrefix}/quotesdetails/${data?.id}`;
};
export const navigateToAddQuote = (data) => {
  return `${AccountPrefix}/addquote`;
};

export const navigateToAllCreditNote = (data) => {
  return `${AccountPrefix}/allcreditnotes`;
};

export const navigateToAddCreditNote = (data) => {
  return `${AccountPrefix}/addcreditnote`;
};

export const navigateToCreditNoteDetails = (data) => {
  return `${AccountPrefix}/creditnotesdetails/${data?.id}`;
};

export const navigateToChartOfAccounts = (data) => {
  return `${AccountPrefix}/chartofaccounts`;
};

export const navigateToAllBills = (data) => {
  return `${AccountPrefix}/allbills`;
};

export const navigateToCreateBills = (data) => {
  return `${AccountPrefix}/createbills`;
};

export const navigateToBillDetails = (data) => {
  return `${AccountPrefix}/billdetails/${data.id}`;
};

// ******* EXPENSEs ROUTES ***** //
export const navigateToAllExpenses = (data) => {
  return `${AccountPrefix}/allexpenses`;
};

// ******* LOCATION ROUTES ***** //

export const navigateToLocation = (data) => {
  return `${Staffprefix}/alllocation`;
};

let myTodoPrefix = "/mytodo";
// ******* MY TODO ROUTES ***** //
export const navigateToMyTodo = (data) => {
  return `${myTodoPrefix}`;
};

// ******* CRM ROUTES ***** //

let crmPrefix = "/crm";

export const navigateToAllLeads = (data) => {
  return `${crmPrefix}/allleads`;
};

export const navigateToLeadsOverviewDetails = (data) => {
  return `${crmPrefix}/allleads/leadoverview/${data?.id}`;
};

export const navigateToLeadsTimelineDetails = (data) => {
  return `${crmPrefix}/allleads/leadtimeline/${data?.id}`;
};

export const navigateToAllContacts = (data) => {
  return `${crmPrefix}/allcontacts`;
};

export const navigateToContactOverview = (data) => {
  return `${crmPrefix}/allcontacts/contactoverview/${data?.id}`;
};
export const navigateToContactTimeline = (data) => {
  return `${crmPrefix}/allcontacts/contacttimeline/${data?.id}/${data?.uniqueId}`;
};
export const navigateToParticipantsGroups = (data) => {
  return `${crmPrefix}/allparticipantsgroups`;
};

export const navigateToGroupsDetails = (data) => {
  return `${crmPrefix}/allparticipantsgroups/groupdetails/${data?.id}`;
};

export const navigateToAllDeals = (data) => {
  return `${crmPrefix}/alldeals`;
};

export const navigateToDealOverview = (data) => {
  return `${crmPrefix}/alldeals/dealoverview/${data?.id}`;
};

export const navigateToDealTimeline = (data) => {
  return `${crmPrefix}/alldeals/dealtimeline/${data?.id}`;
};

export const navigateToCrmMeeting = (data) => {
  return `${crmPrefix}/allmeetings`;
};

export const navigateToCrmCalls = (data) => {
  return `${crmPrefix}/allcalls`;
};

export const navigateToCrmTask = (data) => {
  return `${crmPrefix}/allTasks`;
};
export const navigateToCrmEmails = (data) => {
  return `${crmPrefix}/allEmails`;
};
export const navigateToCrmAnalytics = (data) => {
  return `${crmPrefix}/analytics`;
};
export const navigateToCrEmployeeAnalytics = (data) => {
  return `${crmPrefix}/employeeanalytics`;
};

export const navigateToCrmGroups = (data) => {
  return `${crmPrefix}/groups`;
};

export const navigateToCrmGroupStatus = (data) => {
  return `${crmPrefix}/groups/groupstatus/${data?.id}`;
};

export const navigateToCrmActivityGroups = (data) => {
  return `${crmPrefix}/activitygroups`;
};

export const navigateToActivityGroupDetails = (data) => {
  return `${crmPrefix}/activitygroups/groupdetails/${data?.id}`;
};

export const navigateToCrmSettings = (data) => {
  return `${crmPrefix}/settings`;
};

let vendorPrefix = "/vendor";

export const navigateToAllVendors = (data) => {
  return `${vendorPrefix}/allvendors`;
};
export const navigateToVendorsReport = (data) => {
  return `${vendorPrefix}/report`;
};

export const navigateToVendorsAnalytics = (data) => {
  return `${vendorPrefix}/allvendors/analytics`;
};

export const navigateToVendorDetails = (data) => {
  return `${vendorPrefix}/allvendors/vendordetails/${data?.id}`;
};
