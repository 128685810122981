import React from "react";
import { dateAndTimeFormatter } from "../../../global-functions/globalFunctions";
import { useNavigate } from "react-router-dom";
import {
  navigateToDealOverview,
  navigateToLeadsOverviewDetails,
} from "../../../Navigation/Navigation";

const MailTemplateDetailsModal = ({
  setEmailDetailsModal,
  selectedMailTemplate,
}) => {
  const navigate = useNavigate();

  return (
    <div className="details-modal w-100 mw-500p">
      <div className="nicescroll-bar simple-scrollbar">
        <div className="d-flex gap-3 justify-content-between">
          <div className="d-flex align-items-center gap-3">
            <span className="file-name">Email Details</span>
            <span
              className="btn btn-sm btn-icon btn-light text-primary rounded-circle"
              onClick={() => {
                if (selectedMailTemplate?.relatedTo === "Deal") {
                  navigate(
                    navigateToDealOverview({ id: selectedMailTemplate?.leadId })
                  );
                } else {
                  navigate(
                    navigateToLeadsOverviewDetails({
                      id: selectedMailTemplate?.leadId,
                    })
                  );
                }
              }}
            >
              <span className="feather-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-info-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                </svg>
              </span>
            </span>
          </div>
          <button
            onClick={() => setEmailDetailsModal(false)}
            type="button"
            className="info-close btn-close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="tab-content">
          <div className="tab-pane fade show active" id="tab_info">
            <div className="collapse-simple">
              <div className="card py-0">
                <div className="card-header">
                  <a
                    role="button"
                    data-bs-toggle="collapse"
                    href="#action_by"
                    aria-expanded="true"
                  >
                    Action By
                  </a>
                </div>
                <div id="action_by" className="collapse show">
                  <div className="card-body">
                    <ul className="fm-info">
                      <li>
                        <span>Name : </span>
                        <span>{selectedMailTemplate?.actionBy?.name}</span>
                      </li>
                      <li>
                        <span>Email : </span>
                        <span>{selectedMailTemplate?.actionBy?.email}</span>
                      </li>
                      <li>
                        <span>Contact No. : </span>
                        <span>{selectedMailTemplate?.actionBy?.phone}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="collapse-simple">
              <div className="card py-0">
                <div className="card-header">
                  <a
                    role="button"
                    data-bs-toggle="collapse"
                    href="#action_on"
                    aria-expanded="false"
                  >
                    Action On
                  </a>
                </div>
                <div id="action_on" className="collapse">
                  <div className="card-body">
                    <ul className="fm-info">
                      <li>
                        <span>Name : </span>
                        <span>
                          {selectedMailTemplate?.actionOn?.name ||
                            selectedMailTemplate?.actionOn?.title}
                        </span>
                      </li>
                      <li>
                        <span>Email : </span>
                        <span>{selectedMailTemplate?.actionOn?.email}</span>
                      </li>
                      <li>
                        <span>Contact No. : </span>
                        <span>{selectedMailTemplate?.actionOn?.phone}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="collapse-simple">
              <div className="card py-0">
                <div className="card-header">
                  <a
                    role="button"
                    data-bs-toggle="collapse"
                    href="#email_details"
                    aria-expanded="false"
                  >
                    Email Details
                  </a>
                </div>
                <div id="email_details" className="collapse">
                  <div
                    className="card-body overflow-auto nicescroll-bar simple-scrollbar"
                    style={{ height: "calc(100vh - 330px)" }}
                  >
                    <ul className="fm-info">
                      <li>
                        <span>Display Name : </span>
                        <span>{selectedMailTemplate?.displayName}</span>
                      </li>
                      <li>
                        <span>Created Date : </span>
                        <span>
                          {dateAndTimeFormatter(
                            selectedMailTemplate?.createdAt
                          )}
                        </span>
                      </li>
                      <li>
                        <span>Updated Date : </span>
                        <span>
                          {dateAndTimeFormatter(
                            selectedMailTemplate?.updatedAt
                          )}
                        </span>
                      </li>
                      <li>
                        <span>Related to : </span>
                        <span>{selectedMailTemplate?.relatedTo}</span>
                      </li>
                      <li>
                        <span>Subject : </span>
                        <span>{selectedMailTemplate?.subject}</span>
                      </li>
                      <li>
                        <span>Message : </span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: selectedMailTemplate?.message,
                          }}
                        ></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailTemplateDetailsModal;
